<mat-icon class="dialog-close" (click)="dialogRef.close()" i18n>close</mat-icon>
<div class="container-fluid">
  <div class="row">
    <div class="table-heading click-thru col-12 col-xl-4 " i18n>
      SCALED ORDER: {{marketService.activeMarket.coinCode}}/{{marketService.activeMarket.exchangeCode}}
    </div>
    <div class="col-12 col-xl-8 text-left text-lg-right table-heading">
      <div class="row flex-end">
        <div class="clickable note-box" (click)="fillAmount()" i18n-matTooltip placement="bottom"
            matTooltip="AVAILABLE BALANCE : {{orderType === 1 ? ((balance.balance_available || 0) | decimalNumber: amountDecimalSpaces) : ((balance.balance_available || 0) | decimalNumber:decimalSpaces)}} {{orderType === 1 ? marketService.activeMarket.coinBalance?.code : marketService.activeMarket.exchangeBalance?.code}}">
          <span class="no-bold" i18n>AVAILABLE : </span>
          <span>{{orderType === 1 ? ((balance.balance_available || 0) | decimalNumber: amountDecimalSpaces) : ((balance.balance_available || 0) | decimalNumber: decimalSpaces)}}</span>
          &nbsp;
          <span>{{orderType === 1 ? marketService.activeMarket.coinBalance?.code : marketService.activeMarket.exchangeBalance?.code}}</span>
        </div>
        <app-balance-viewer
          [available]="(balance?.balance_available || 0) | decimalNumber: orderType === 0 ? decimalSpaces : amountDecimalSpaces"
          [held]="(balance?.balance_held || 0) | decimalNumber: orderType === 0 ? decimalSpaces : amountDecimalSpaces"
          [earn]="(balance?.balance_earn || 0) | decimalNumber: orderType === 0 ? decimalSpaces : amountDecimalSpaces"
          [code]="(orderType === 0) ? marketService.activeMarket.exchangeBalance?.code : marketService.activeMarket.coinBalance?.code">
        </app-balance-viewer>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12 col-lg-6">
      <div class="row">
      <div class="col-12 mt-2">
        <mat-radio-group (change)="orderTypeChange()" [(ngModel)]="orderType" aria-label="Order">
          <mat-radio-button [value]="0" i18n>BUY</mat-radio-button>
          <mat-radio-button [value]="1" i18n>SELL</mat-radio-button>
        </mat-radio-group>
    </div>
    <div [formGroup]="orderForm" class="col-12 order-form small-text">

      <mat-grid-list cols="8" rowHeight="15px">

        <mat-grid-tile class="field-label" [colspan]="2" [rowspan]="3" i18n>
          Amount Total
        </mat-grid-tile>
        <mat-grid-tile [colspan]="4" [rowspan]="3">
          <mat-form-field class="w-100">
            <input matInput formControlName="amount" step="0.00000001" type="number" [(ngModel)]="amount" (change)="amountChange()" />
            <mat-error *ngIf="orderForm.controls.amount.hasError('min')">
              <ng-container i18n>Minimum {{ amountMinimum | decimalNumber : amountDecimalSpaces }}</ng-container>
            </mat-error>
          </mat-form-field>
        </mat-grid-tile>
        <mat-grid-tile [colspan]="2" [rowspan]="3" class="field-label">
          {{marketService.activeMarket.coinCode}}
        </mat-grid-tile>

        <mat-grid-tile class="field-label" [colspan]="2" [rowspan]="3" i18n>
          No of Orders
        </mat-grid-tile>
        <mat-grid-tile [colspan]="4" [rowspan]="3">
          <mat-form-field class="w-100">
            <input matInput formControlName="orders" step="1" type="number" [(ngModel)]="orderCount" />
            <mat-error *ngIf="orderForm.controls.orders.hasError('min')">
              <ng-container i18n>Minimum {{ minOrderCount }} Orders</ng-container>
            </mat-error>
            <mat-error *ngIf="orderForm.controls.orders.hasError('wholeNumber')">
              <ng-container i18n>Please note that the number of orders should be a whole number</ng-container>
            </mat-error>
            <mat-error *ngIf="orderForm.controls.orders.hasError('max')">
              <ng-container i18n>Maximum {{ maxOrderCount }} Orders</ng-container>
            </mat-error>
          </mat-form-field>
        </mat-grid-tile>
        <mat-grid-tile [colspan]="2" [rowspan]="3" class="field-label">
        </mat-grid-tile>

        <mat-grid-tile class="field-label" [colspan]="2" [rowspan]="3" i18n>
          Lower Price
        </mat-grid-tile>
        <mat-grid-tile [colspan]="4" [rowspan]="3">
          <mat-form-field class="w-100">
            <input (change)="lowerPriceChange()" matInput formControlName="priceLow" min="0.0001" step="0.00000001" type="number" [(ngModel)]="priceLower" />
            <mat-error *ngIf="orderForm.controls.priceLow.hasError('max')">
              <ng-container i18n>Should be less than Higher Price</ng-container>
            </mat-error>
            <mat-error *ngIf="orderForm.controls.priceLow.hasError('min')">
              <ng-container i18n>Minimum price of {{ priceMinimum | decimalNumber : decimalSpaces }}</ng-container>
            </mat-error>
            <mat-error *ngIf="orderForm.controls.priceLow.hasError('orderLowerPrice')">
              <ng-container i18n>An order's price is lower than lower price.</ng-container>
            </mat-error>
          </mat-form-field>
        </mat-grid-tile>
        <mat-grid-tile [colspan]="2" [rowspan]="3" class="field-label">
          {{marketService.activeMarket.exchangeBalance?.code}}
        </mat-grid-tile>

        <mat-grid-tile class="field-label" [colspan]="2" [rowspan]="3" i18n>
          Higher Price
        </mat-grid-tile>
        <mat-grid-tile [colspan]="4" [rowspan]="3">
          <mat-form-field class="w-100">
            <input (change)="higherPriceChange()" matInput formControlName="priceHigh" min="0.0001" step="0.00000001" type="number" [(ngModel)]="priceUpper" />
            <mat-error *ngIf="orderForm.controls.priceHigh.hasError('min2')">
              <ng-container i18n>Should be higher than Lower Price</ng-container>
            </mat-error>
            <mat-error *ngIf="orderForm.controls.priceHigh.hasError('min')">
              <ng-container i18n>Minimum price of {{ priceMinimum | decimalNumber : decimalSpaces }}</ng-container>
            </mat-error>
            <mat-error *ngIf="orderForm.controls.priceHigh.hasError('orderUpperPrice')">
              <ng-container i18n>An order's price is greater than lower price.</ng-container>
            </mat-error>
          </mat-form-field>
        </mat-grid-tile>
        <mat-grid-tile [colspan]="2" [rowspan]="3" class="field-label">
          {{marketService.activeMarket.exchangeBalance?.code}}
        </mat-grid-tile>
      </mat-grid-list>
    </div>

    <div class="table-heading max-header-size click-thru col-12 col-sm-3" i18n>
      Distribution:
    </div>

    <div class="col-12 col-sm-9">
      <div class="mt-3">
        <mat-radio-group [(ngModel)]="distribution" (change)="distributionChange()" aria-label="Distribution">
          <mat-radio-button [value]="0" i18n>Flat</mat-radio-button>
          <mat-radio-button [value]="1" i18n>Increasing</mat-radio-button>
          <mat-radio-button [value]="2" i18n>Decreasing</mat-radio-button>
          <mat-radio-button [value]="3" i18n>Custom</mat-radio-button>
        </mat-radio-group>
      </div>
    </div>

    <div class="col-12">
      <div class="scaled-control background-grey">
        <input type="range" [(ngModel)]="distributionValues[0]" (input)="rangeChange()" class="range-control" />
        <input type="range" [(ngModel)]="distributionValues[1]" (input)="rangeChange()" class="range-control" />
        <input type="range" [(ngModel)]="distributionValues[2]" (input)="rangeChange()" class="range-control" />
        <input type="range" [(ngModel)]="distributionValues[3]" (input)="rangeChange()" class="range-control" />
        <input type="range" [(ngModel)]="distributionValues[4]" (input)="rangeChange()" class="range-control" />
        <svg xmlns="http://www.w3.org/2000/svg" width="270" height="60" viewBox="0 0 270 45">
          <line x1="4" y1="0" x2="67.5" y2="22.5" stroke="orange"  stroke-width="2"></line>
          <line x1="67.5" y1="22.5" x2="135" y2="22.5" stroke="orange"  stroke-width="2"></line>
          <line x1="135" y1="22.5" x2="202.5" y2="22.5" stroke="orange"  stroke-width="2"></line>
          <line x1="202.5" y1="22.5" x2="268" y2="22.5" stroke="orange"  stroke-width="2"></line>
          <circle cx="6" cy="22.5" r="5" fill="orange"></circle>
          <circle cx="67.5" cy="22.5" r="5" fill="orange"></circle>
          <circle cx="135" cy="22.5" r="5" fill="orange"></circle>
          <circle cx="202.5" cy="22.5" r="5" fill="orange"></circle>
          <circle cx="265" cy="22.5" r="5" fill="orange"></circle>
        </svg>
      </div>

    </div>
  </div>
    </div>
    <div class="col-12 col-lg-6">
      <div class="row">
    <div class=" p-b-10px table-heading max-header-size click-thru col-12 col-sm-3" i18n>
      Preview Orders
    </div>

    <div class="col-12">
      <mat-table [dataSource]="orders" class="d-flex flex-column orders-preview">
        <ng-container matColumnDef="number">
          <mat-header-cell class="text-right mr-1" *matHeaderCellDef i18n>Order Number</mat-header-cell>
          <mat-cell *matCellDef="let element" class="my-order text-right mr-1">
            {{element.count}}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="amount">
          <mat-header-cell class="text-right mr-1" *matHeaderCellDef i18n>Amount</mat-header-cell>
          <mat-cell *matCellDef="let element" class="my-order text-right mr-1">
            {{element.amount|decimalNumber:amountDecimalSpaces}}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="price">
          <mat-header-cell class="text-right mr-1" *matHeaderCellDef i18n>Price</mat-header-cell>
          <mat-cell *matCellDef="let element" class="my-order text-right mr-1">
            {{element.price|decimalNumber:decimalSpaces:(getCoinCode(false))}}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="total">
          <mat-header-cell class="text-right mr-1" *matHeaderCellDef i18n>Total</mat-header-cell>
          <mat-cell *matCellDef="let element" class="my-order text-right mr-1">
            {{element.total|decimalNumber:decimalSpaces:(getCoinCode(false))}}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="fee">
          <mat-header-cell class="text-right mr-1" *matHeaderCellDef i18n>Est Fee</mat-header-cell>
          <mat-cell *matCellDef="let element" class="my-order text-right mr-1">
            {{element.fee|decimalNumber:feeDecimalSpaces}}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="netTotal">
          <mat-header-cell class="text-right mr-1" *matHeaderCellDef i18n>Est Net Total</mat-header-cell>
          <mat-cell *matCellDef="let element" class="my-order text-right mr-1">
            {{element.netTotal|decimalNumber:decimalSpaces:(getCoinCode(false))}}
          </mat-cell>
        </ng-container>

        <mat-header-row mat-header-row *matHeaderRowDef="ordersTableDefinition; sticky: true"></mat-header-row>
        <mat-row
          *matRowDef="let row; columns: ordersTableDefinition;"
          [class.text-red]="row.invalid"
        >
        </mat-row>
      </mat-table>
    </div>

    <div class="col-12 mt-1 orders-totals">
      <div class="float-right mr-3">
        <span class="inline">
          <div class="mr-2" i18n>Order Total:</div>
          <span [class.text-red]="orderForm.controls.amount.hasError('availableBalance')" class="mr-2">
            {{orderType === 1 ? totalAmount : totalNetTotal | decimalNumber:decimalSpaces:(getCoinCode(false))}} 
          </span>
          {{orderType === 1 ? marketService.activeMarket.coinCode : marketService.activeMarket.exchangeCode}}
        </span>
      </div>
    </div>

    <div class="col-12 mt-1">
      <mat-error *ngIf="orderForm.controls.amount.hasError('minTotal') || orderForm.controls.amount.hasError('availableBalance')">
        <ng-container i18n>Unfortunately, we cannot place your order due to the errors identified below. Please review the reasons below and adjust the details of your order:</ng-container>
      </mat-error>
      <ul>
      <mat-error *ngIf="orderForm.controls.amount.hasError('minTotal')">
        <li>
          <ng-container i18n>One or more of the orders will be placed with a net total of less than {{ totalMinimum | decimalNumber : decimalSpaces}}, these orders are indicated in the preview table above</ng-container>
        </li>
      </mat-error>
      <mat-error *ngIf="orderForm.controls.amount.hasError('availableBalance')">
        <li>
          <ng-container i18n>You do not have enough available balance to place this order, i.e. the total of all the orders to be placed is higher than your available balance</ng-container>
        </li>
      </mat-error>
      </ul>
    </div>

  </div>
    </div>
  </div>
  <div class="row">
    <div class="col-6 mt-1">
      <button mat-raised-button class="mat-raised-button w-100" color="primary" (click)="generateOrders()">PREVIEW ORDERS</button>
    </div>

    <div class="col-6 mt-1">
      <button mat-raised-button class="mat-raised-button w-100" color="primary" (click)="placeOrders()">SUBMIT ORDERS</button>
    </div>
  </div>
</div>

<div class="row">
  <div class="table-heading click-thru col-12 col-xl-4 ">
    <ng-container>{{type|i18n|uppercase}} {{executionType}}</ng-container> {{marketService.activeMarket.coinCode}}
  </div>
  <div class="col-12 col-xl-8 text-left table-heading">
    <div class="row flex-end">
      <div class="clickable note-box" (click)="fillAmount()" i18n-matTooltip placement="bottom"
        matTooltip="{{translatedWords['AVAILABLE BALANCE: ']}}{{(balance?.balance_available || 0) | decimalNumber:(type === 'buy' ? decimalSpaces : amountDecimalSpaces) }} {{balance?.code}}">
        <span class="no-bold" i18n>AVAILABLE : </span>
        <span>{{(balance?.balance_available || 0) | decimalNumber: type === 'buy' ? decimalSpaces :
          amountDecimalSpaces }}</span>
        &nbsp;
        <span>{{balance?.code}}</span>
      </div>
      <app-balance-viewer
        [available]="(balance?.balance_total || 0) | decimalNumber: type === 'buy' ? decimalSpaces : amountDecimalSpaces"
        [held]="(balance?.balance_held || 0) | decimalNumber: type === 'buy' ? decimalSpaces : amountDecimalSpaces"
        [earn]="(balance?.balance_earn || 0) | decimalNumber: type === 'buy' ? decimalSpaces : amountDecimalSpaces"
        [code]="(type === 'buy') ? exchangeCode : coinCode">
      </app-balance-viewer>
    </div>
  </div>
</div>
<div [formGroup]="orderForm" class="order-form small-text">
<!--<mat-tab-group>
  <mat-tab>-->
  <mat-grid-list cols="8" rowHeight="10px">
    <div [ngClass]="{'hidden': orderForm.controls.amount.disabled}">
      <mat-grid-tile class="field-label" [colspan]="2" [rowspan]="3" i18n>
        Amount
      </mat-grid-tile>
      <mat-grid-tile [colspan]="4" [rowspan]="3">
        <mat-form-field class="w-100">
          <input matInput formControlName="amount" min="0"
            [max]="type === 'sell' ? balance?.balance_available : undefined" step="0.00000001" type="number"
            (change)="amountChange()" (input)="markAsTouched()" (keydown)="markAsTouched()" (paste)="markAsTouched()" [(ngModel)]="amount" />
          <mat-error *ngIf="balance && orderForm.controls.amount.hasError('max') && executionType !== 'market'">
            <ng-container i18n>Available balance:</ng-container> {{balance?.balance_available | decimalNumber:(type === 'buy' ? decimalSpaces : amountDecimalSpaces)}} {{balance?.code}}
          </mat-error>
          <mat-error *ngIf="amountAvailable && orderForm.controls.amount.hasError('max') && executionType === 'market'">
            <ng-container i18n>Available in order book:</ng-container> {{amountAvailable | decimalNumber: amountDecimalSpaces}} {{marketService.activeMarket.coinCode}}
          </mat-error>
          <mat-error *ngIf="orderForm.controls.amount.hasError('min')">
            <ng-container i18n>Minimum {{minAmount}} {{marketService.activeMarket.coinCode}}</ng-container>
          </mat-error>
        </mat-form-field>
      </mat-grid-tile>
      <mat-grid-tile [colspan]="2" [rowspan]="3" class="field-label">
        {{marketService.activeMarket.coinCode}}
      </mat-grid-tile>
    </div>

    <div [ngClass]="{'hidden': orderForm.controls.price.disabled}">
      <mat-grid-tile class="field-label" [colspan]="2" [rowspan]="3" i18n>
        Price
      </mat-grid-tile>
      <mat-grid-tile [colspan]="4" [rowspan]="3">
        <mat-form-field class="w-100">
          <input matInput type="text" value="MARKET" *ngIf="!displayPrice" disabled="true" />
          <input matInput formControlName="price" min="0" step="0.00000001" type="number" (change)="priceChange()"
            [(ngModel)]="price" *ngIf="displayPrice" (input)="markAsTouched()" (keydown)="markAsTouched()" (paste)="markAsTouched()" />
        </mat-form-field>
      </mat-grid-tile>
      <mat-grid-tile [colspan]="2" [rowspan]="3" class="field-label">
        {{exchangeCode}}
      </mat-grid-tile>
    </div>

    <div [ngClass]="{'hidden': orderForm.controls.total.disabled}">
      <mat-grid-tile class="field-label" [colspan]="2" [rowspan]="3" i18n>
        Total
      </mat-grid-tile>
      <mat-grid-tile [colspan]="4" [rowspan]="3">
        <mat-form-field class="w-100">
          <input matInput formControlName="total" min="0" step="0.00000001" type="number" (change)="totalChange()"
            [(ngModel)]="total" (input)="markAsTouched()" (keydown)="markAsTouched()" (paste)="markAsTouched()" />
          <mat-error *ngIf="orderForm.controls.total.hasError('min')">
            <ng-container i18n>Minimum</ng-container>
            {{totalMinimum | decimalNumber : decimalSpaces}} {{type === 'buy' ? balance?.code : exchangeCode}}
          </mat-error>
        </mat-form-field>
      </mat-grid-tile>
      <mat-grid-tile [colspan]="2" [rowspan]="3" class="field-label">
        {{exchangeCode}}
      </mat-grid-tile>
    </div>

    <div [ngClass]="{'hidden': orderForm.controls.net_total.disabled}">
      <mat-grid-tile class="field-label" [colspan]="2" [rowspan]="3" i18n>
        Net Total
      </mat-grid-tile>
      <mat-grid-tile [colspan]="4" [rowspan]="3">
        <mat-form-field class="w-100">
          <input matInput formControlName="net_total" [min]="this.totalMinimum" step="0.00000001" type="number"
            [(ngModel)]="netTotal" (change)="netTotalChange()" (input)="markAsTouched()" (keydown)="markAsTouched()" (paste)="markAsTouched()" />
          <mat-error *ngIf="type !== 'sell' && orderForm.controls.net_total.hasError('max')">
            <ng-container i18n>Available balance:</ng-container> {{balance.balance_available | decimalNumber :
              decimalSpaces}} {{balance.code}}
          </mat-error>
          <mat-error *ngIf="type !== 'sell' && orderForm.controls.net_total.hasError('min')">
            <ng-container i18n>Minimum</ng-container> {{this.totalMinimum | decimalNumber: decimalSpaces}} {{balance.code}}
          </mat-error>
        </mat-form-field>
      </mat-grid-tile>
      <mat-grid-tile [colspan]="2" [rowspan]="3" class="field-label">
        {{exchangeCode}}
      </mat-grid-tile>
    </div>

    <mat-grid-tile class="field-label" [colspan]="2" [rowspan]="3">
      <ng-container i18n>Post Only&nbsp;
        <mat-icon matTooltipClass="fee-info-tooltip" class="fee-info" svgIcon="fee-info"
          i18n-matTooltip matTooltip="An order will only be placed if it does not match against an existing order in the order book, i.e. it will only be placed as a Maker Order" triggers="hover click" >&nbsp;</mat-icon>
      </ng-container>
    </mat-grid-tile>
    <mat-grid-tile [colspan]="4" [rowspan]="3" class="post-field">
      <mat-checkbox readonly formControlName="post_only" (change)="amountChange()" labelPosition="after" class="my-1 checkbox-post"></mat-checkbox>
    </mat-grid-tile>

    <mat-grid-tile class="mt-2" [colspan]="4" [rowspan]="6">
      <button mat-raised-button class="w-100 mat-raised-button" color="primary" (click)="clear()" i18n>CLEAR</button>
    </mat-grid-tile>

    <mat-grid-tile class="mt-2" [colspan]="4" [rowspan]="6">
      <button mat-raised-button class="w-100 mat-raised-button" color="primary" (click)="order()"
        i18n>{{type|uppercase}}</button>
    </mat-grid-tile>
  </mat-grid-list>
</div>

<!--</mat-tab>
  <mat-tab label="Limit"> Limit </mat-tab>
  <mat-tab label="Stop"> Stop </mat-tab>
</mat-tab-group>-->

<ng-container *ngIf="exchangeName === 'chainex'; else burnxForm" class="heightfull">
    <mat-tab-group (selectedIndexChange)="orderChange($event)" class="order-types-group heightfull" #orderTabs>
        <mat-tab label="LIMIT ORDER">
            <div class="container-fluid heightfull">
                <ng-container class="heightfull" *ngTemplateOutlet="cxLimitOrder"></ng-container>
            </div>
        </mat-tab>
        <mat-tab label="MARKET ORDER">
            <div class="container-fluid heightfull">
                <ng-container class="heightfull" *ngTemplateOutlet="cxMarketOrder"></ng-container>
            </div>
        </mat-tab>
        <mat-tab label="STOP ORDER">
            <div class="container-fluid heightfull">
              <ng-container class="heightfull" *ngTemplateOutlet="cxStopOrder"></ng-container>
            </div>
        </mat-tab>
        <mat-tab label="ACCUMULATION/DISTRIBUTION">
          <ng-container class="heightfull" *ngTemplateOutlet="cxDAOrder"></ng-container>
        </mat-tab>
        <mat-tab label="SCALED ORDER">
          <div class="container-fluid"></div>
        </mat-tab>
    </mat-tab-group>
</ng-container>

<ng-template #burnxForm>
    <mat-tab-group class="mt-1" (selectedIndexChange)="orderChange($event)" #orderTabs>
        <mat-tab label="LIMIT">
            <div class="container-fluid">
                <ng-container *ngTemplateOutlet="bxLimitOrder"></ng-container>
            </div>
        </mat-tab>
    </mat-tab-group>
</ng-template>

<ng-template #cxMarketOrder>
    <div class="row heightfull">
        <div *ngIf="!isMobile" class="d-none d-md-block col-sm-12 col-lg-6 fixGutter mt-1 pr-3 market-buy-box heightfull padding-bottom-7">
            <ng-container *ngTemplateOutlet="warning"></ng-container>
            <ng-container *ngTemplateOutlet="warningBuy"></ng-container>
            <div class="row mat-elevation-z2 pt-2 heightfull"
                [ngClass]="{'pb-27px': ['ZAR', 'USDT'].indexOf(marketService.activeMarket.exchangeCode) === -1}">
                <div class="col-12 heightfull">
                    <app-market-order-chainex class="heightfull" #buyFormMarket type="buy" [orderValues]="lastValues"></app-market-order-chainex>
                </div>
            </div>
        </div>

        <div *ngIf="!isMobile" class="d-none d-md-block col-sm-12 col-lg-6 fixGutter mt-1 market-sell-box heightfull padding-bottom-7">
            <ng-container *ngTemplateOutlet="warning"></ng-container>
            <ng-container *ngTemplateOutlet="warningSell"></ng-container>
            <div class="row mat-elevation-z2 pt-2 heightfull"
                [ngClass]="{'pb-27px':['ZAR', 'USDT'].indexOf(marketService.activeMarket.exchangeCode) === -1, 'py-1px':['ZAR', 'USDT'].indexOf(marketService.activeMarket.exchangeCode) === -1 }">
                <div class="col-12 heightfull">
                    <app-market-order-chainex class="heightfull" #sellFormMarket type="sell" [orderValues]="lastValues"></app-market-order-chainex>
                </div>
            </div>
        </div>

        <div *ngIf="isMobile" class="d-block d-sm-block col-12 col-sm-12 col-lg-6 fixGutter mt-1 mobile-transaction-box">
            <div class="row mat-elevation-z2">
                <div class="col-12 p-0">
                    <mat-tab-group>
                        <mat-tab label="BUY">
                            <ng-container *ngTemplateOutlet="warning"></ng-container>
                            <ng-container *ngTemplateOutlet="warningBuy"></ng-container>
                            <div class="mat-elevation-z2">
                                <div class="col-12">
                                  <app-market-order-chainex #mobileBuyFormMarket type="buy" [orderValues]="lastValues">
                                  </app-market-order-chainex>
                                </div>
                            </div>
                        </mat-tab>
                        <mat-tab label="SELL">
                            <ng-container *ngTemplateOutlet="warning"></ng-container>
                            <ng-container *ngTemplateOutlet="warningSell"></ng-container>
                            <div class="mat-elevation-z2">
                                <div class="col-12">
                                  <app-market-order-chainex #mobileSellFormMarket type="sell" [orderValues]="lastValues">
                                  </app-market-order-chainex>
                                </div>
                            </div>
                        </mat-tab>
                    </mat-tab-group>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #cxDAOrder>
  <div *ngIf="!isMobile" class="row heightfull">
    <div class="d-none d-md-block col-sm-12 col-lg-6 fixGutter mt-1 pr-3 market-buy-box heightfull padding-bottom-7">
      <ng-container *ngTemplateOutlet="warning"></ng-container>
      <ng-container *ngTemplateOutlet="warningBuy"></ng-container>
      <div class="row mat-elevation-z2 pt-2 heightfull"
           [ngClass]="{'pb-27px': ['ZAR', 'USDT'].indexOf(marketService.activeMarket.exchangeCode) === -1}">
        <div class="w-100">
          <app-dacomponent type="buy" (valueChange)="onValueChange($event)"></app-dacomponent>
        </div>
      </div>
    </div>

    <div *ngIf="!isMobile" class="d-none d-md-block col-sm-12 col-lg-6 fixGutter mt-1 market-sell-box heightfull padding-bottom-7">
      <ng-container *ngTemplateOutlet="warning"></ng-container>
      <ng-container *ngTemplateOutlet="warningSell"></ng-container>
      <div class="row mat-elevation-z2 pt-2 heightfull"
           [ngClass]="{'pb-27px':['ZAR', 'USDT'].indexOf(marketService.activeMarket.exchangeCode) === -1, 'py-1px':['ZAR', 'USDT'].indexOf(marketService.activeMarket.exchangeCode) === -1 }">
        <div class="w-100">
          <app-dacomponent type="sell" (valueChange)="onValueChange($event)"></app-dacomponent>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="isMobile" class="d-block d-sm-block col-12 col-sm-12 col-lg-6 fixGutter mt-1 mobile-transaction-box">
    <div class="row mat-elevation-z2">
      <div class="col-12 p-0">
        <mat-tab-group>
          <mat-tab label="BUY">
            <ng-container *ngTemplateOutlet="warning"></ng-container>
            <ng-container *ngTemplateOutlet="warningBuy"></ng-container>
            <div class="row mat-elevation-z2">
              <div class="w-100">
                <app-dacomponent type="buy" (valueChange)="onValueChange($event)"></app-dacomponent>
              </div>
            </div>
          </mat-tab>
          <mat-tab label="SELL">
            <ng-container *ngTemplateOutlet="warning"></ng-container>
            <ng-container *ngTemplateOutlet="warningSell"></ng-container>
            <div class="row mat-elevation-z2">
              <div class="w-100">
                <app-dacomponent type="sell" (valueChange)="onValueChange($event)"></app-dacomponent>
              </div>
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #cxLimitOrder>
    <div class="row heightfull">
        <div *ngIf="!isMobile" class="d-none d-md-block col-sm-12 col-lg-6 fixGutter mt-1 pr-3 market-buy-box heightfull padding-bottom-7">
            <ng-container *ngTemplateOutlet="warning"></ng-container>
            <ng-container *ngTemplateOutlet="warningBuy"></ng-container>
            <div class="row mat-elevation-z2 pt-2 heightfull"
                [ngClass]="{'pb-27px': ['ZAR', 'USDT'].indexOf(marketService.activeMarket.exchangeCode) === -1}">
                <div class="col-12">
                    <ng-content></ng-content>
                    <markets-orderform #buyForm type="buy" [orderValues]="lastValues"></markets-orderform>
                </div>
            </div>
        </div>

        <div *ngIf="!isMobile" class="d-none d-md-block col-sm-12 col-lg-6 fixGutter mt-1 market-sell-box heightfull padding-bottom-7">
            <ng-container *ngTemplateOutlet="warning"></ng-container>
            <ng-container *ngTemplateOutlet="warningSell"></ng-container>
            <div class="row mat-elevation-z2 pt-2 heightfull"
                [ngClass]="{'pb-27px':['ZAR', 'USDT'].indexOf(marketService.activeMarket.exchangeCode) === -1, 'py-1px':['ZAR', 'USDT'].indexOf(marketService.activeMarket.exchangeCode) === -1 }">
                <div class="col-12">
                    <markets-orderform #sellForm type="sell" [orderValues]="lastValues"></markets-orderform>
                </div>
            </div>
        </div>

        <div *ngIf="isMobile" class="d-block d-sm-block col-12 col-sm-12 col-lg-6 fixGutter mt-1 mobile-transaction-box">
            <div class="row mat-elevation-z2">
                <div class="col-12 p-0">
                    <mat-tab-group>
                        <mat-tab label="BUY">
                            <ng-container *ngTemplateOutlet="warning"></ng-container>
                            <ng-container *ngTemplateOutlet="warningBuy"></ng-container>
                            <div class="mat-elevation-z2">
                              <div class="col-12">
                                <markets-orderform #mobileBuyForm type="buy" [orderValues]="lastValues"></markets-orderform>
                              </div>
                            </div>
                        </mat-tab>
                        <mat-tab label="SELL">
                            <ng-container *ngTemplateOutlet="warning"></ng-container>
                            <ng-container *ngTemplateOutlet="warningSell"></ng-container>
                            <div class="mat-elevation-z2">
                              <div class="col-12">
                                <markets-orderform #mobileSellForm type="sell" [orderValues]="lastValues"></markets-orderform>
                              </div>
                            </div>
                        </mat-tab>
                    </mat-tab-group>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #bxLimitOrder>
    <div class="row">
        <div class="col-12">
            <markets-burnx-limit-order #bxLimitOrderComponent [orderValues]="lastValues"></markets-burnx-limit-order>
        </div>
    </div>
</ng-template>

<ng-template #warning>
    <div class="warning-box" *ngIf="!loggedIn">
        <div>
            <span i18n>
                Please sign up or in to trade.
            </span>

            <br><br>

            <button mat-raised-button routerLink="/register" class="mat-raised-button" color="accent" i18n>
                SIGN UP
            </button>
            &nbsp;&nbsp;
            <button mat-raised-button [routerLink]="[getLoginUrl(router, null)]" class="mat-raised-button"
                color="primary" i18n>
                SIGN IN
            </button>
        </div>
    </div>
</ng-template>

<ng-template #warningBuy>
    <div class="warning-box"
        *ngIf="loggedIn && (isIEOBuyRestricted(marketService.activeMarket) || isIEOVerificationLevelBuyRestricted(marketService.activeMarket) || isFiatRestricted(marketService.activeMarket))">
        <div>
            <span *ngIf="isIEOBuyRestricted(marketService.activeMarket)" i18n>
                You are restricted from placing buy orders on the market as it relates
                to an Initial Exchange Offering coin.
            </span>

            <span *ngIf="isIEOVerificationLevelBuyRestricted(marketService.activeMarket)" i18n>
                Please note that you cannot yet partake in this IEO unless you have
                completed Level 1 account verification. Click here to verify now.
            </span>

            <span
                *ngIf="!isIEOVerificationLevelBuyRestricted(marketService.activeMarket) && !isIEOBuyRestricted(marketService.activeMarket)"
                i18n>
                Please verify your account to trade.
            </span>

            <br><br>

            <button
                *ngIf="!isIEOBuyRestricted(marketService.activeMarket) || isIEOVerificationLevelBuyRestricted(marketService.activeMarket)"
                mat-raised-button routerLink="/profile" class="mat-raised-button" color="primary" i18n>
                VERIFY NOW
            </button>
        </div>
    </div>
</ng-template>

<ng-template #warningSell>
    <div class="warning-box"
        *ngIf="loggedIn && (isIEOSellRestricted(marketService.activeMarket) || isFiatRestricted(marketService.activeMarket))">
        <div>
            <span *ngIf="isIEOSellRestricted(marketService.activeMarket)" i18n>
                You are restricted from placing sell orders on the market as it relates to an Initial Exchange Offering
                coin.
            </span>

            <span
                *ngIf="!isIEOSellRestricted(marketService.activeMarket)"
                i18n>
                Please verify your account to trade.
            </span>

            <br><br>

            <button
                *ngIf="!isIEOSellRestricted(marketService.activeMarket)"
                mat-raised-button routerLink="/profile" class="mat-raised-button" color="primary" i18n>
                VERIFY NOW
            </button>
        </div>
    </div>
</ng-template>

<ng-template #cxStopOrder>
  <div class="row heightfull">
    <div *ngIf="!isMobile" class="d-none d-md-block col-sm-12 col-lg-6 fixGutter mt-1 pr-3 market-buy-box heightfull padding-bottom-7">
      <ng-container *ngTemplateOutlet="warning"></ng-container>
      <ng-container *ngTemplateOutlet="warningBuy"></ng-container>
      <div class="row mat-elevation-z2 pt-2 heightfull"
           [ngClass]="{'pb-27px': ['ZAR', 'USDT'].indexOf(marketService.activeMarket.exchangeCode) === -1}">
        <div class="col-12">
          <ng-content></ng-content>
          <app-stop-limit-order #buyStopForm type="buy" [orderValues]="lastValues"></app-stop-limit-order>
        </div>
      </div>
    </div>

    <div *ngIf="!isMobile" class="d-none d-md-block col-sm-12 col-lg-6 fixGutter mt-1 market-sell-box heightfull padding-bottom-7">
      <ng-container *ngTemplateOutlet="warning"></ng-container>
      <ng-container *ngTemplateOutlet="warningSell"></ng-container>
      <div class="row mat-elevation-z2 pt-2 heightfull"
           [ngClass]="{'pb-27px':['ZAR', 'USDT'].indexOf(marketService.activeMarket.exchangeCode) === -1, 'py-1px':['ZAR', 'USDT'].indexOf(marketService.activeMarket.exchangeCode) === -1 }">
        <div class="col-12">
          <app-stop-limit-order #sellStopForm type="sell" [orderValues]="lastValues"></app-stop-limit-order>
        </div>
      </div>
    </div>

    <div *ngIf="isMobile" class="d-block d-sm-block col-12 col-sm-12 col-lg-6 fixGutter mt-1 mobile-transaction-box">
      <div class="row mat-elevation-z2">
        <div class="col-12 p-0">
          <mat-tab-group>
            <mat-tab label="BUY">
              <div class="mat-elevation-z2">
                <div class="col-12">
                  <ng-container *ngTemplateOutlet="warning"></ng-container>
                  <ng-container *ngTemplateOutlet="warningBuy"></ng-container>
                  <app-stop-limit-order #mobileBuyStopForm type="buy" [orderValues]="lastValues"></app-stop-limit-order>
                </div>
              </div>
            </mat-tab>
            <mat-tab label="SELL">
              <div class="mat-elevation-z2">
                <div class="col-12">
                  <ng-container *ngTemplateOutlet="warning"></ng-container>
                  <ng-container *ngTemplateOutlet="warningSell"></ng-container>
                  <app-stop-limit-order #mobileSellStopForm type="sell" [orderValues]="lastValues"></app-stop-limit-order>
                </div>
              </div>
            </mat-tab>
          </mat-tab-group>
        </div>
      </div>
    </div>
  </div>
</ng-template>


<markets-top-stats *ngIf="!showMarket"></markets-top-stats>

  <div>
    <mat-tab-group (selectedTabChange)="tab_change($event)">
      <mat-tab i18n-label label="Price Chart">
        <div class="diffpadding chartheight" id="chartMe"></div>
      </mat-tab>
      <mat-tab  i18n-label label="Depth Chart">
        <div id="chartdiv_orderdepth" class="chartheight"></div>
      </mat-tab>
    </mat-tab-group>
  </div>

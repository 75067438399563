<div class="grid-container">
  <div class="mobilewidth center-top">
    <div class="col-12 chart-holder burnx-border-box mat-elevation-z2" >
      <markets-chart #chart (hideBuySell)="onHideBuySellChange($event)"></markets-chart>
    </div>
  </div>
  <div class="center-bottom mobilewidth">
      <app-order-types #orderTypes class="fullheight"></app-order-types>
  </div>
  <div class="left">
    <div class="mobilewidth fullheight grid-width-17">
      <markets-sidebar #sidebar [showHistory]="true" (marketIdChange)="onMarketIDChange($event)"
      (valueChange)="onValueChange($event)"></markets-sidebar>
    </div>
  </div>
  <div class="right">
    <div class="mobilewidth fullheight grid-width-17 pr-1">
      <markets-orderbook #orderBook (valueChange)="onValueChange($event)" (updateChart)="updateChart()">
      </markets-orderbook>
    </div>
  </div>
  <div class="footer">
    <div id="market-main-table"  class="fix-gutter mb-1 mobilewidth order-history-padding"
          *ngIf="loggedIn && !isFiatRestricted(marketService.activeMarket)">
      <div class="mat-elevation-z2">
        <div class="col-12">
          <mat-tab-group>
            <mat-tab i18n-label label="ACTIVE ORDERS">
              <markets-market-history class="no-overflow" [mode]="0" (valueChange)="onValueChange($event)"
                                      #activeOrders></markets-market-history>
            </mat-tab>
            <mat-tab i18n-label label="ORDER HISTORY">
              <markets-market-history class="no-overflow" [mode]="1" (valueChange)="onValueChange($event)"
                                      #orderHistory></markets-market-history>
            </mat-tab>
            <mat-tab i18n-label label="TRADE HISTORY">
              <markets-market-history class="no-overflow" [mode]="2" (valueChange)="onValueChange($event)" #trades>
              </markets-market-history>
            </mat-tab>
          </mat-tab-group>
        </div>
      </div>
    </div>
    <div class="mat-elevation-z2">
      <markets-coin-analytics></markets-coin-analytics>
    </div>
  </div>
</div>
<router-outlet></router-outlet>
<ng-template #warning>
  <div class="warning-box" *ngIf="!loggedIn">
    <div>
      <span i18n>
        Please sign up or in to trade.
      </span>

      <br><br>

      <button mat-raised-button routerLink="/register" class="mat-raised-button" color="accent" i18n>
        SIGN UP
      </button>
      &nbsp;&nbsp;
      <button mat-raised-button [routerLink]="[getLoginUrl(router, null)]" class="mat-raised-button" color="primary"
              i18n>
        SIGN IN
      </button>
    </div>
  </div>
</ng-template>

<ng-template #warningBuy>
  <div class="warning-box"
       *ngIf="loggedIn && (isIEOBuyRestricted(marketService.activeMarket) || isIEOVerificationLevelBuyRestricted(marketService.activeMarket) || isFiatRestricted(marketService.activeMarket))">
    <div>
      <span *ngIf="isIEOBuyRestricted(marketService.activeMarket)" i18n>
        You are restricted from placing buy orders on the market as it relates
        to an Initial Exchange Offering coin.
      </span>

      <span *ngIf="isIEOVerificationLevelBuyRestricted(marketService.activeMarket)" i18n>
        Please note that you cannot yet partake in this IEO unless you have
        completed Level 1 account verification. Click here to verify now.
      </span>

      <span
        *ngIf="!isIEOVerificationLevelBuyRestricted(marketService.activeMarket) && !isIEOBuyRestricted(marketService.activeMarket)"
        i18n>
        Please verify your account to trade.
      </span>

      <br><br>

      <button
        *ngIf="!isIEOBuyRestricted(marketService.activeMarket) || isIEOVerificationLevelBuyRestricted(marketService.activeMarket)"
        mat-raised-button routerLink="/profile" class="mat-raised-button" color="primary" i18n>
        VERIFY NOW
      </button>
    </div>
  </div>
</ng-template>

<ng-template #warningSell>
  <div class="warning-box"
       *ngIf="loggedIn && (isIEOSellRestricted(marketService.activeMarket) || isFiatRestricted(marketService.activeMarket))">
    <div>
      <span *ngIf="isIEOSellRestricted(marketService.activeMarket)" i18n>
        You are restricted from placing sell orders on the market as it relates to an Initial Exchange Offering coin.
      </span>

      <span *ngIf="!isIEOSellRestricted(marketService.activeMarket)"
            i18n>
        Please verify your account to trade.
      </span>

      <br><br>

      <button *ngIf="!isIEOSellRestricted(marketService.activeMarket)"
              mat-raised-button routerLink="/profile" class="mat-raised-button" color="primary" i18n>
        VERIFY NOW
      </button>
    </div>
  </div>
</ng-template>

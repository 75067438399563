import {
  Component, OnInit, OnDestroy, ChangeDetectorRef,
  EventEmitter, Output, ChangeDetectionStrategy
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { defer, SubscriptionLike, Subject } from 'rxjs';
import { debounceTime, switchMap, takeUntil } from 'rxjs/operators';

import { MarketsService } from '../markets.service';
import { StoreService } from 'src/app/core/services/store.service';
import { SessionStorageService } from 'src/app/core/services/session-storage.service';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { environment } from 'src/environments/environment';

const NUM_ORDERS = environment.config.EXCHANGE_NAME_L === 'chainex' ? 20 : 18;

@Component({
  selector: 'markets-orderbook',
  templateUrl: './order-book.component.html',
  styleUrls: ['./order-book.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrderBookComponent implements OnInit, OnDestroy {

  chainexGrouping: boolean = environment.config.CHAINEX_GROUPING;
  burnxGrouping: boolean = environment.config.BURNX_GROUPING;

  formValues: any = {
    buy: {
      feePrice: 0,
      amount: 0,
      updatePrice: 0,
      loadAmount: 0,
      orders: [],
      totalAmountAvailable: -1
    },
    sell: {
      feePrice: 0,
      amount: 0,
      updatePrice: 0,
      loadAmount: 0,
      orders: [],
      totalAmountAvailable: -1
    },
    price: 0,
    amount: 0,
    marketChanged: false,
    userSpecified: false
  };

  currentlyFetching: boolean = false;
  buyData: MatTableDataSource<any> = new MatTableDataSource([]);
  buyDataArray: Array<any>;
  buyOrdersStatus: string;
  decimals: number = environment.config.EXCHANGE_NAME_L === 'burnx' ? 4 : 8;
  pow: number = Math.pow(10, this.decimals);
  fetchTimeout: any;
  highestBuyOrder: any;
  lowestSellOrder: any;
  marketChanged: boolean;
  orderBookData: any;
  isGrouping: boolean;
  sellData: MatTableDataSource<any> = new MatTableDataSource([]);
  sellDataArray: Array<any>;
  sellOrdersStatus: string;
  spread: number = 0;
  tableDefinition: string[] = [
    'myOrder',
    'price',
    'amount',
    'total',
    'sum'
  ];
  @Output() updateChart: EventEmitter<any> = new EventEmitter();
  @Output() valueChange: EventEmitter<any> = new EventEmitter();

  translatedWords: Object = {
    'AMOUNT: ': this.i18n('AMOUNT: '),
  };

  orderBookHeader: boolean = environment.config.ORDER_BOOK_HEADER;
  fetchingMarket: number = 0;
  initializeMyOrders: boolean = true;

  private subs: SubscriptionLike[] = [];
  private orders: any[] = [];
  private destroy$: Subject<void> = new Subject();

  constructor(
    public marketService: MarketsService,
    private store: StoreService,
    private sessionStorage: SessionStorageService,
    public CDRef: ChangeDetectorRef,
    private i18n: I18n) {
    }

  ngOnInit() {
    this.buyOrdersStatus = 'ORDER_LOADINGORDER';
    this.sellOrdersStatus = 'ORDER_LOADINGORDER';
    this.marketService.activeMarketSubject.pipe(
      debounceTime(200),
      switchMap(() => defer(() => {
        this.setActiveMarket();
        this.initializeMyOrders = true;
        if (!this.CDRef['destroyed']) {
          this.CDRef.detectChanges();
        }
      })),
      takeUntil(this.destroy$)
    ).subscribe();

    this.subs.push(this.store.subscribe('openOrders').subscribe((data) => {
      if (!data.feed || (data.isUserSpecific) || (data.feed &&
        data.feed === `marketUpdates-${this.marketService.activeMarket.id}`)) {
          // we use my active orders to compare against the order book and mark own orders
          // which means we need to call active orders atleast once when we create the book
          // otherwise we will only be able to mark orders if a user specific message comes through
          if (data.isUserSpecific || this.initializeMyOrders) {
            this.initializeMyOrders = false;
            this.store.getMyActiveOrders(
              this.marketService.activeMarket.id
              ).subscribe((result: any) => {
                this.orders = result;
                this.markOrders();
            });
          }
        this.formValues.marketChanged = false;
        this.formValues.userSpecified = false;
        this.loadData();
      }
    }));
  }

  ngOnDestroy() {
    for (let i = 0; i < this.subs.length; i++) {
      this.subs[i].unsubscribe();
    }

    this.destroy$.next();
    this.destroy$.complete();
  }

  calculateBuyRunningTotal() {
    let total = 0;
    let idx = this.buyData.data.length - 1;

    while (total === 0 && idx >= 0) {
      total = this.buyData.data[idx].total;
      idx--;
    }

    let amount = 0;
    for (let i = 0; i < this.buyData.data.length; i++) {

      amount += this.buyData.data[i].amount;
      this.buyData.data[i].orderAmount = amount;
      if (this.buyData.data[i].total > 0) {
        this.buyData.data[i].percentage = this.tagRowPercentage(total, this.buyData.data[i].total);
        this.buyData.data[i].weight = this.tagRowWeight(this.buyData.data[i].orderTotal, total);
      } else {
        this.buyData.data[i].percentage = 0;
        this.buyData.data[i].weight = 0;
      }

    }
  }

  calculateSellRunningTotal() {
    let total = 0;
    let idx = 0;

    while (total === 0 && this.sellData.data.length > idx) {
      total = this.sellData.data[idx].total;
      idx++;
    }

    let amount = 0;
    for (let i = this.sellData.data.length - 1; 0 <= i; i--) {
      amount += this.sellData.data[i].amount;
      this.sellData.data[i].orderAmount = amount;
      if (this.sellData.data[i].total > 0) {
        this.sellData.data[i].percentage = this.tagRowPercentage(total, this.sellData.data[i].total);
        this.sellData.data[i].weight = this.tagRowWeight(this.sellData.data[i].orderTotal, total);
      } else {
        this.sellData.data[i].percentage = 0;
        this.sellData.data[i].weight = 0;
      }
    }
  }

  calculateSpread() {
    if (!!this.lowestSellOrder && !!this.highestBuyOrder) {
      this.spread = this.lowestSellOrder.price - this.highestBuyOrder.price;
    }
  }

  getStyleColor(type: string) {
    let theme = this.sessionStorage.get('THEME');
    theme = theme === 'dark-theme' ? theme : 'light-theme';
    return environment.config.ORDER_BOOK_COLOR[theme][type];
  }

  getRowColorStyle(row: any, colorPercentage: any, colorWeight: any) {
    if (row.update) {
      setTimeout(() => row.update = false, 200);
      return '';
    }

    return { background: `linear-gradient(to left,
      ${colorWeight} ${row.weight}%,
      ${colorPercentage} ${row.weight}%,
      ${colorPercentage} ${row.percentage}%, transparent 0%
    )`};
  }

  roundPrice(price: number) {
    return Math.round(price * this.pow) / this.pow;
  }

  findOrder(price: number, type: Number) {
    for (let o = 0; o < this.orders.length && price > 0; o++) {
      if (Number(this.orders[o].type) === type &&
      this.roundPrice(Number(this.orders[o].price)) === Number(price) &&
        this.orders[o].market === this.marketService.activeMarket.id) {
        return this.orders[o];
      }
    }
    return null;
  }

  markOrders() {
    for (let b = 0; b < this.buyData.data.length; b++) {
      const o = this.findOrder(this.buyData.data[b].price, 0);
      if (o) {
        this.buyData.data[b].hasOrder = true;
      } else {
        this.buyData.data[b].hasOrder = false;
      }
    }
    for (let s = 0; s < this.sellData.data.length; s++) {
      const o = this.findOrder(this.sellData.data[s].price, 1);
      if (o) {
        this.sellData.data[s].hasOrder = true;
      } else {
        this.sellData.data[s].hasOrder = false;
      }
    }
  }

  loadData() {
    if ((this.marketService.activeMarket.id === '-1') ||
      (+this.marketService.activeMarket.id === this.fetchingMarket)) {
      return;
    }
    // we need to check for this, encase a user feels like selecting different markets quickly
    // without it the order book can get stuck and display the incorrect order book
    if ((+this.marketService.activeMarket.id !== this.fetchingMarket) && this.currentlyFetching) {
      this.currentlyFetching = false;
    }

    if (!this.currentlyFetching) {
      this.currentlyFetching = true;
      this.fetchingMarket = +this.marketService.activeMarket.id;

      if (this.decimals > this.marketService.activeMarket.exchangeDecimals) {
        this.decimals = this.marketService.activeMarket.exchangeDecimals;
      }

      if (this.marketChanged) {
        this.buyData.data = [];
        this.sellData.data = [];
        this.spread = 0;
        this.decimals = this.marketService.activeMarket.exchangeDecimals;
      }

      this.marketService.getOrderDepth({
        'perPage': 50,
        'pageNo': 0,
        'orderBy': 'price',
        'market': this.marketService.activeMarket.id,
        'decimals': this.decimals
      }).subscribe((response) => {
        if (response.response === 'success') {
          this.orderBookData = response;
          const cleanOrders = (book, type) => {
            for (let i = 0; i < book.length; i++) {
              try {
                const order = book[i];
                order.price = Number(order.price);
                order.amount = Number(order.amount);
                order.orderTotal = Number(order.orderTotal);
                if (order.total === 0) {
                  book.splice(i--, 1);
                }
              } catch (ex) { }
            }
            book.sort((a, b) => a.price > b.price ? -1 : 1);
          };

          if (response.buy.length <= 0) {
            this.buyOrdersStatus = 'ORDER_NOORDERS';
          } else {
            cleanOrders(response.buy, 'buy');
          }
          if (response.sell.length <= 0) {
            this.sellOrdersStatus = 'ORDER_NOORDERS';
          } else {
            cleanOrders(response.sell, 'sell');
          }

          if (this.marketChanged || !this.buyDataArray || !this.sellDataArray) {
            this.buyDataArray = response.buy;
            this.sellDataArray = response.sell;
          } else {
            const updateOrders = (existingBook: Array<any>, newBook: Array<any>, type: string) => {
              for (let i = 0; i < existingBook.length; i++) {
                const existingOrder = existingBook[i];
                existingOrder.updated = false;
                // Do we have the order in the existing book?
                if (!newBook.find((newOrder, index) => {
                  if (+newOrder.price === +existingOrder.price) {
                    existingBook[i] = newOrder;
                    newBook.splice(index, 1);
                    if (newOrder.amount !== existingOrder.amount && !this.isGrouping) {
                      newOrder.update = true;
                      newOrder.more = newOrder.amount > existingOrder.amount;
                    }
                    return true;
                  }
                  return false;
                })) {
                  existingBook.splice(i--, 1);
                }
              }
              if (newBook && newBook.length) {
                newBook.forEach(order => {
                  if ( !this.isGrouping ) {
                    order.update = true;
                    order.more = true;
                  }
                  let i = 0;
                  while (existingBook[i] &&
                    ((+existingBook[i].price > +order.price && type === 'buy') ||
                    (+existingBook[i].price < +order.price && type === 'sell'))
                    && existingBook.length > i) {
                    i++;
                  }
                  existingBook.splice(i, 0, order);
                });
              }
              if (type === 'buy') {
                existingBook.sort((a, b) => a.price > b.price ? -1 : 1);
              } else if (type === 'sell') {
                existingBook.sort((a, b) => a.price > b.price ? -1 : 1);
              }
            };

            updateOrders(this.buyDataArray, response.buy, 'buy');
            updateOrders(this.sellDataArray, response.sell, 'sell');
          }

          this.highestBuyOrder = this.buyDataArray[0];
          this.lowestSellOrder = this.sellDataArray[this.sellDataArray.length - 1];

          if (this.marketChanged) {
            this.formValues.buy.price = 0;
            this.formValues.buy.amount = 0;
            this.formValues.sell.price = 0;
            this.formValues.sell.amount = 0;
            this.formValues.marketChanged = true;
            this.formValues.userSpecified = false;
            if (!!this.lowestSellOrder) {
              this.formValues.buy.price = this.lowestSellOrder.price;
              this.formValues.buy.amount = this.lowestSellOrder.amount;
            }
            if (!!this.highestBuyOrder) {
              this.formValues.sell.price = this.highestBuyOrder.price;
              this.formValues.sell.amount = this.highestBuyOrder.amount;
            }
            this.valueChange.emit(this.formValues);
          }
          this.marketChanged = false;

          this.updateFeePriceData();
          this.sellDataArray = this.reduceDecimals(this.sellDataArray);
          this.buyDataArray = this.reduceDecimals(this.buyDataArray);
          const emptyOrder = {
            amount: 0,
            orderTotal: 0,
            price: 0,
            total: 0
          };

          for (let i = this.sellDataArray.length; i < NUM_ORDERS; i++) {
            this.sellDataArray.unshift(emptyOrder);
          }
          for (let i = this.buyDataArray.length; i < NUM_ORDERS; i++) {
            this.buyDataArray.push(emptyOrder);
          }

          this.buyData.data = this.buyDataArray.slice(0, NUM_ORDERS);
          this.sellData.data = this.sellDataArray.slice(Math.max(this.sellDataArray.length - NUM_ORDERS, 0));

          this.updateChart.emit();


          this.sendOrderBookData();

          this.calculateBuyRunningTotal();
          this.calculateSellRunningTotal();
          this.calculateSpread();
        }
        this.markOrders();
        this.currentlyFetching = false;
        this.fetchingMarket = 0;
        this.isGrouping = false;
        this.CDRef.markForCheck();
      }, () => {
        this.currentlyFetching = false;
        this.fetchingMarket = 0;
        this.isGrouping = false;
      }, () => {
        this.currentlyFetching = false;
        this.fetchingMarket = 0;
        this.isGrouping = false;
      });
    }
  }
  reduceDecimals(orderArray: any) {
    const newOrderArray = [];
    let prevOrder: any;
    let zeroAmnt: number = 0;
    let zeroTotal: number = 0;
    for (const _order of orderArray) {
      const order = JSON.parse(JSON.stringify(_order));
      if ( order.price === 0) {
        if ( !prevOrder) {
          zeroAmnt += +order.amount;
          zeroTotal += +order.total;
        } else {
          const objIndex = newOrderArray.findIndex((obj => obj.price === prevOrder.price));
          newOrderArray[objIndex].amount += +order.amount;
          newOrderArray[objIndex].orderTotal += +order.orderTotal;
          newOrderArray[objIndex].total = order.total;
        }
      } else {
        newOrderArray.push(order);
        prevOrder = order;
      }
    }
    if ( prevOrder ) {
      const lastIndex = newOrderArray.findIndex((obj => obj.price === prevOrder.price));
      newOrderArray[lastIndex].amount += +zeroAmnt;
      newOrderArray[lastIndex].orderTotal += +zeroTotal;
    }
    return newOrderArray;
  }
  sendOrderBookData() {
    this.formValues.buy.orders = [];
    this.formValues.buy.totalAmountAvailable = -1;
    this.formValues.sell.orders = [];
    this.formValues.sell.totalAmountAvailable = -1;
    if (!!this.orderBookData) {
      this.formValues.buy.totalAmountAvailable = this.orderBookData.buy_total_amount;
      this.formValues.sell.totalAmountAvailable = this.orderBookData.sell_total_amount;
    }
    if (!!this.buyDataArray) {
      this.formValues.buy.orders = this.buyDataArray;
    }
    if (!!this.sellDataArray) {
      this.formValues.sell.orders = this.sellDataArray;
    }

    this.highestBuyOrder = this.buyDataArray[0];
    this.lowestSellOrder = this.sellDataArray[this.sellDataArray.length - 1];
    if (!!this.lowestSellOrder) {
      this.formValues.buy.price = this.lowestSellOrder.price;
      this.formValues.buy.amount = this.lowestSellOrder.amount;
    }
    if (!!this.highestBuyOrder) {
      this.formValues.sell.price = this.highestBuyOrder.price;
      this.formValues.sell.amount = this.highestBuyOrder.amount;
    }
    this.valueChange.emit(this.formValues);
  }

  updateFeePriceData() {
    this.formValues.buy.feePrice = -1;
    this.formValues.sell.feePrice = -1;
    if (!!this.lowestSellOrder) {
      this.formValues.buy.feePrice = this.lowestSellOrder.price;
    }
    if (!!this.highestBuyOrder) {
      this.formValues.sell.feePrice = this.highestBuyOrder.price;
    }
    this.valueChange.emit(this.formValues);
  }

  loadValue(row: any) {
    this.formValues.price = +row.price;
    this.formValues.amount = +row.orderAmount;
    this.formValues.userSpecified = true;
    this.formValues.marketChanged = false;
    this.valueChange.emit(this.formValues);
  }

  tagRowPercentage(runningTotal: number, total: number) {
    return (total / runningTotal) * 60;
  }

  tagRowWeight(orderTotal: number, runningTotal: number) {
    return (orderTotal / runningTotal) * 60;
  }

  addGroup() {
    if (!this.currentlyFetching && this.decimals < 8) {
      this.decimals++;
      this.pow = Math.pow(10, this.decimals);
      this.isGrouping = true;
      this.loadData();
    }
  }

  minusGroup() {
    if (!this.currentlyFetching && this.decimals > 0) {
      this.decimals--;
      this.pow = Math.pow(10, this.decimals);
      this.isGrouping = true;
      this.loadData();
    }
  }

  setActiveMarket() {
    this.marketChanged = true;
    // Show loading message every time the market changes
    this.buyOrdersStatus = 'ORDER_LOADINGORDER';
    this.sellOrdersStatus = 'ORDER_LOADINGORDER';
    this.loadData();
  }

  trackByOrderBook(index: number) {
    return index;
  }
}

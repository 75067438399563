import { Component, OnInit, Input, OnDestroy, ChangeDetectorRef} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as Big from 'big-js';

import { MarketsService } from '../markets.service';
import { MarketConfirmationComponent, DialogData } from '../market-confirmation/market-confirmation.component';
import { StoreService, SubscriptionLike } from '../../core/services/store.service';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { I18nTranslationService } from 'src/app/core/services/i18n-translation.service';

import { UtilHelper } from 'src/app/core/helpers/util-helper';

const typeMap = ['buy', 'sell'];

@Component({
  selector: 'markets-orderform',
  templateUrl: './order-form.component.html',
  styleUrls: ['./order-form.component.scss']
})
export class OrderFormComponent implements OnInit, OnDestroy {
  private settingsSub: SubscriptionLike;
  private balanceSub: SubscriptionLike;
  public valueChange: Function = UtilHelper.bigValueToFixed;
  @Input('orderValues')
  set orderValues(values: any) {
    if (values) {
      if (Object.keys(values).length > 0) {
        this.LoadValue(values);
      }
    }
  }

  amount: number | Big = new Big(0).toFixed(8);
  amountDecimalSpaces: number = 8;
  feeDecimalSpaces: number = 8;
  balance: any = {
    balance: { balance_available: 0.00000000, balance_total: 0, code: '' }
  };
  decimalSpaces: number = 8;
  fee: number | Big = new Big(0).toFixed(8);
  netTotal: number | Big = new Big(0).toFixed(8);
  orderForm: FormGroup;
  price: number | Big = new Big(0).toFixed(8);
  showConfirmation: boolean = true;
  activeMarketSub: SubscriptionLike;
  total: number | Big = new Big(0).toFixed(8);
  totalMinimum: number = 1;
  priceForFeeCalc: number | Big = new Big(0).toFixed(8);
  feeType: string = 'makerFee';
  feePercentage: number | Big = new Big(0).toFixed(8);
  @Input() type: string = 'buy'; // Buy = 0, Sell = 1
  executionType: string = '';
  exchangeCode: string = '';
  coinCode: string = '';
  displayPrice: boolean = true;
  amountAvailable: number | Big = new Big(0).toFixed(8);
  orderBook: any = [];
  priceMarket: number | Big = new Big(0).toFixed(8);
  firstLoad: boolean = true;
  feeToolTip: string = '';
  minAmount: number | Big = new Big(0).toFixed(8);
  minTotal: number = 0.0001;

  translatedWords: Object = {
    'AVAILABLE BALANCE: ': this.i18n('AVAILABLE BALANCE: '),
  };

  constructor(
    public dialog: MatDialog,
    public marketService: MarketsService,
    private formBuilder: FormBuilder,
    private store: StoreService,
    private i18n: I18n,
    private translateService: I18nTranslationService,
    public snackbar: MatSnackBar,
    private CDRef: ChangeDetectorRef
  ) {
    this.orderForm = this.formBuilder.group({
      amount: ['', Validators.required],
      price: ['', Validators.required],
      total: ['', Validators.min(this.minTotal)],
      fee: [''],
      net_total: ['', Validators.min(this.minTotal)],
      post_only: [false]
    });
  }

  ngOnInit() {
    this.activeMarketSub = this.marketService.activeMarketSubject.subscribe((response) => {
      this.setActiveMarket();
    });
    this.setActiveMarket();
    this.settingsSub = this.store.subscribe('settings').subscribe((response) => {
      if (!response.refresh) {
        this.showConfirmation = !response.data.confirmations ? true : response.data.confirmations === '1';
      }
    });

    this.setupForm();

    this.setupFormValidation();
  }

  ngOnDestroy() {
    this.settingsSub.unsubscribe();

    if (this.balanceSub) {
      this.balanceSub.unsubscribe();
    }
  }

  resetFormValidation() {
    Object.keys(this.orderForm.controls).forEach(key => {
      this.orderForm.get(key).setValidators(null);
      this.orderForm.get(key).updateValueAndValidity();
    });
  }

  setupFormValidation() {
    const netValidator = (price = false) => (control) => {
      const value = +control.value;
      if (value === null || !value || +value === 0) {
        return null;
      }
      let _max = 0;
      if (this.balance.balance_available) {
        _max = +this.balance.balance_available;
      }
      if (price) {
        _max *= this.price;
      }

      return !isNaN(value) && value > _max ? { 'max': { 'max': _max, 'actual': control.value } } : null;
    };

    this.resetFormValidation();
    const maximumValue = (this.type === 'buy' ? false :
    (this.executionType === 'market' ? this.amountAvailable : this.balance?.balance_available));
    this.minAmount = this.marketService.getMinAmount(
      this.minAmount, maximumValue, this.orderForm, +this.price, this.calcFeeToUse('takerFee'));

    if (this.type === 'buy') {
      switch (this.executionType) {
        case 'market': {
          this.orderForm.controls.amount.setValidators([Validators.max(this.amountAvailable)]);
          break;
        }
        default: {
          this.orderForm.controls.net_total.setValidators(null);
          this.orderForm.controls.net_total.updateValueAndValidity();
          this.orderForm.controls.net_total.setValidators(
            [netValidator(),
            Validators.min(this.totalMinimum)]);
          this.orderForm.controls.net_total.updateValueAndValidity();
          break;
        }
      }

    } else {
      switch (this.executionType) {
        case 'market': {
          this.orderForm.controls.amount.setValidators([Validators.max(this.amountAvailable)]);
          break;
        }
        default: {
          this.orderForm.controls.total.setValidators(null);
          this.orderForm.controls.total.updateValueAndValidity();

          this.orderForm.controls.amount.setValidators(null);
          this.orderForm.controls.amount.updateValueAndValidity();

          this.orderForm.controls.total.setValidators(
            [Validators.min(this.totalMinimum)]);
          this.orderForm.controls.total.updateValueAndValidity();
          this.orderForm.controls.amount.setValidators(
            [netValidator(false),
            Validators.min(['ZAR', 'USDT'].indexOf(this.marketService.activeMarket.coinCode) === -1 ? 0.00001 : 1)]);
          break;
        }
      }
    }
    this.orderForm.markAsPristine();
  }

  setupForm() {
    switch (this.executionType) {
      case 'market':
        // this.disableAllControls();
        this.orderForm.controls.amount.enable();
        this.orderForm.controls.price.enable();
        this.displayPrice = false;
        this.setupFormValidation();
        this.amount = 0;
        this.amountChange();
        break;
      default: break;
    }
  }

  disableAllControls() {
    Object.keys(this.orderForm.controls).forEach(key => {
      this.orderForm.get(key).disable();
    });
  }

  setExecutionType(type: string) {
    this.executionType = type;
    this.setupForm();
  }

  amountChange() {
    this.amount = this.valueChange(this.amount, this.amountDecimalSpaces);

    if (this.executionType === 'market' && !this.orderForm.controls.amount.errors) {
      this.calculateTotalMarket();
      this.calculateFee();
      this.calculateNetTotal();
    } else {
      this.calculateTotal();
      this.calculateFee();
      this.calculateNetTotal();
    }
    this.orderForm.controls.total.markAsTouched();
  }

  calculateAmount() {
    const currentPrice = new Big(1)[this.type === 'buy' ? 'plus' : 'minus'](this.feePercentage).times(+this.price);

    let currentAmount = new Big(0);
    // amount = net total / ((1 +/- fee percentage) * price)
    if (currentPrice.gt(0)) {
      currentAmount = new Big(+this.netTotal).div(currentPrice);
    }
    currentAmount = currentAmount.round(this.amountDecimalSpaces, 0); // round down to the last decimal place
    this.amount = this.valueChange(currentAmount, this.amountDecimalSpaces);
  }

  calcFeeToUse(type: string = 'makerFee') {
    let toUse = +this.marketService.activeMarket[type];
    if (this.marketService[type] < toUse) {
      toUse = +this.marketService[type];
    }
    return toUse;
  }

  generateFeeTooltip() {
    let mFee: string = '0';
    let tFee = '0';
    try {
      const mFeeToUse = this.calcFeeToUse('makerFee');
      const tFeeToUse = this.calcFeeToUse('takerFee');
      if (this.type === 'buy') {
        if (['ZAR'].indexOf(this.marketService.activeMarket.exchangeCode) !== -1) {
          mFee = this.price > 0 ? this.valueChange(new Big(+this.total)
            .times(mFeeToUse).div(+this.price), this.feeDecimalSpaces)
            : '0';
          tFee = this.price > 0 ? this.valueChange(new Big(+this.total)
            .times(tFeeToUse).div(+this.price), this.feeDecimalSpaces)
            : '0';
        } else {
          mFee = this.valueChange(new Big(+this.total)
            .times(mFeeToUse), this.feeDecimalSpaces);
          tFee = this.valueChange(new Big(+this.total)
            .times(tFeeToUse), this.feeDecimalSpaces);
        }
      } else {
        if (['ZAR'].indexOf(this.marketService.activeMarket.exchangeCode) !== -1) {
          mFee = this.price > 0 ? this.valueChange(new Big(+this.total)
            .times(mFeeToUse).div(this.price), this.feeDecimalSpaces)
            : '0';
          tFee = this.price > 0 ? this.valueChange(new Big(+this.total)
            .times(tFeeToUse).div(this.price), this.feeDecimalSpaces)
            : '0';
        } else {
          mFee = this.valueChange(new Big(+this.total)
            .times(mFeeToUse), this.feeDecimalSpaces);
          tFee = this.valueChange(new Big(+this.total)
            .times(tFeeToUse), this.feeDecimalSpaces);
        }
      }
    } catch (ex) { }
    this.feeToolTip = 'Maker: ' + mFee + '\nTaker: ' + tFee;
  }

  calculateFeeToUse() {
    const tempTakerFee = (+this.marketService['takerFee'] < +this.marketService.activeMarket['takerFee'] &&
      +this.marketService['takerFee'] !== -1) ? +this.marketService['takerFee'] : +this.marketService.activeMarket['takerFee'];
    const tempMakerFee = (+this.marketService['makerFee'] < +this.marketService.activeMarket['makerFee'] &&
      +this.marketService['makerFee'] !== -1) ? +this.marketService['makerFee'] : +this.marketService.activeMarket['makerFee'];
    this.feeType = (tempTakerFee > tempMakerFee) ? 'takerFee' : 'makerFee';
    this.feePercentage = this.orderForm.controls.post_only.value ?
      (tempMakerFee < 0 ? 0 : tempMakerFee) : (tempTakerFee > tempMakerFee ? tempTakerFee : tempMakerFee);
  }

  calculateFee() {
    try {
      this.generateFeeTooltip();
      this.calculateFeeToUse();
      if (this.type === 'buy') {
        if (['ZAR'].indexOf(this.marketService.activeMarket.exchangeCode) !== -1) {
          this.fee = this.price > 0 ? this.valueChange(new Big(+this.total)
            .times(this.feePercentage).div(+this.price), this.feeDecimalSpaces)
            : 0;
        } else {
          this.fee = this.valueChange(new Big(+this.total)
            .times(this.feePercentage), this.feeDecimalSpaces);
        }
      } else {
        if (['ZAR'].indexOf(this.marketService.activeMarket.exchangeCode) !== -1) {
          this.fee = this.price > 0 ? this.valueChange(new Big(+this.total)
            .times(this.feePercentage).div(this.price), this.feeDecimalSpaces)
            : 0;
        } else {
          this.fee = this.valueChange(new Big(+this.total)
            .times(this.feePercentage), this.feeDecimalSpaces);
        }
      }
    } catch (ex) { }
  }

  calculateNetTotal() {
    let currNetTotal = 0;
    const currTotal = new Big(+this.price).times(+this.amount);

    // net total = total * (1 +/- fee percentage)
    currNetTotal = new Big(1)[this.type === 'buy' ? 'plus' : 'minus'](this.feePercentage).times(currTotal);
    this.netTotal = this.valueChange(currNetTotal, this.decimalSpaces);

    this.orderForm.controls.net_total.markAsTouched();
    if (this.type === 'sell' && this.amount > this.balance.balance_available) {
      this.orderForm.controls.amount.setValidators([Validators.max(this.balance.balance_available)]);
      this.orderForm.controls.amount.markAsTouched();
    }
  }

  calculateTotalMarket() {
    this.amount = isNaN(this.amount) ? 0 : this.amount;
    let amountToOffer: number = this.amount;
    let totalCalc: number | Big = new Big(0).toFixed(8);
    let totalPrice: number | Big = new Big(0).toFixed(8);
    let orderCount: number = 0;
    if (!!this.orderBook) {
      for (let i = 0; i < this.orderBook.length; i++) {
        if (+this.orderBook[i].amount > 0) {
          amountToOffer = amountToOffer - this.orderBook[i].amount;
          let orderTake: number | Big = new Big(0).toFixed(8);
          if (amountToOffer > 0) {
            // we can take the whole order
            orderTake = new Big(+this.orderBook[i].amount).times(+this.orderBook[i].price);
          } else {
            orderTake = (new Big(+this.orderBook[i].amount).plus(+amountToOffer)).times(+this.orderBook[i].price);
          }
          totalCalc = new Big(totalCalc).plus(orderTake);
          totalPrice = new Big(totalPrice).plus(+this.orderBook[i].price);
          orderCount++;
          if (amountToOffer <= 0) {
            break;
          }
        }
      }
      this.priceMarket = new Big(totalPrice).div(orderCount).toFixed(this.decimalSpaces);
    }

    this.total = this.valueChange(totalCalc, this.decimalSpaces);
    if (this.type === 'sell') {
      this.validateTotal();
    }
  }

  calculateTotal() {
    this.amount = isNaN(this.amount) ? 0 : this.amount;
    this.price = isNaN(this.price) ? 0 : this.price;
    const tempTotal = new Big(+this.amount).times(+this.price);
    this.total = +(
      new Big(+(Math.floor(+(tempTotal.times(Math.pow(10, this.decimalSpaces)))))).div(Math.pow(10, this.decimalSpaces))
    );
    this.total = this.total.toFixed(this.decimalSpaces);
    if (this.type === 'sell' && this.total > this.minTotal) {
      this.orderForm.controls.total.markAsUntouched();
    } else {
      this.validateTotal();
    }
  }

  clear() {
    this.amount = (0).toFixed(this.decimalSpaces);
    this.total = (0).toFixed(this.decimalSpaces);
    this.price = (0).toFixed(this.decimalSpaces);

    this.calculateTotal();
    this.calculateFee();
    this.calculateNetTotal();

    this.orderForm.markAsUntouched();
  }

  fillAmount() {
    this.markAsTouched();
    if (this.type === 'buy') {
      this.netTotal = this.balance.balance_available;
      this.netTotalChange();
    } else {
      this.amount = this.balance.balance_available;
      this.amountChange();
    }
  }

  getPercentage(percentage: any) {
    return (percentage ? +percentage * 100 : 0).toFixed(2);
  }

  LoadValue(values: any) {
    // check if form is untouched before changing values
    const priceChangeAllowed = this.orderForm.untouched || (values.userSpecified || values.marketChanged);

    // this updates the price used to check if order is taker/maker
    if (!!values[this.type] && !!values[this.type].feePrice) {
      this.priceForFeeCalc = (+values[this.type].feePrice || 0).toFixed(this.amountDecimalSpaces);
      if (priceChangeAllowed) {
        this.priceChange();
      }
    }

    if (!!values[this.type] || (!!values.price && !!values.amount)) {
      if (!!values[this.type] && this.firstLoad) {
        this.firstLoad = false;
        this.price = (+values[this.type].price.toFixed(this.decimalSpaces) ||
          this.marketService.activeMarket.lastPrice);
        this.priceChange();
        if (values.marketChanged) {
          this.amount = 0;
        } else {
          this.amount = (+values[this.type].amount || 0).toFixed(this.amountDecimalSpaces);
        }
        this.amountChange();
        this.orderForm.controls.total.markAsUntouched();
        this.orderForm.controls.net_total.markAsUntouched();
      } else {
        if (priceChangeAllowed) {
          if (values.userSpecified || values.marketChanged) {
            this.price = +values.price || this.marketService.activeMarket.lastPrice;
          } else {
            // if order book update, get price by order type
            this.price = (+values[this.type].price.toFixed(this.decimalSpaces) ||
            this.marketService.activeMarket.lastPrice);
          }

          if (values.marketChanged) {
            this.amount = 0;
          } else {
            if (this.exchangeCode === 'ZAR') {
              this.netTotal = (+values.amount * +values.price) || 0;
              this.netTotalChange();
            } else {
              this.amount = +values.amount || 0;
              this.priceChange();
              this.amountChange();
            }
          }
          if ((this.type === 'buy' && this.netTotal > this.balance.balance_available) ||
            (this.type === 'sell' && this.amount > this.balance.balance_available)) {
            this.fillAmount();
          }

          if (!(values.userSpecified)) {
            this.orderForm.markAsUntouched();
          }
        }
      }
    }

    const otherType = this.type === 'buy' ? 'sell' : 'buy';
    if (!!values[otherType] && !!values[otherType].totalAmountAvailable) {
      this.amountAvailable = (+values[otherType].totalAmountAvailable).toFixed(this.amountDecimalSpaces);
    }
    if (!!values[otherType] && !!values[otherType].orders) {
      this.orderBook = values[otherType].orders;
    }

    if (values.marketChanged) {
      this.orderForm.markAsUntouched();
    }

  }

  markAsTouched() {
    this.orderForm.markAsTouched();
  }

  netTotalChange() {
    this.netTotal = this.valueChange(this.netTotal, this.decimalSpaces);
    this.calculateAmount();
    this.calculateTotal();
    this.calculateFee();
    this.calculateNetTotal();
    this.orderForm.controls.total.markAsTouched();
  }

  order() {
    this.orderForm.controls.amount.markAsTouched();
    this.orderForm.controls.amount.updateValueAndValidity();
    this.orderForm.controls.price.markAsTouched();
    this.orderForm.controls.price.updateValueAndValidity();
    this.orderForm.controls.total.markAsTouched();
    this.orderForm.controls.total.updateValueAndValidity();
    this.orderForm.controls.net_total.markAsTouched();
    this.orderForm.controls.net_total.updateValueAndValidity();

    this.orderForm.markAsTouched();

    if (this.orderForm.valid) {

      let actionType;
      switch (this.executionType) {
        case 'market': actionType = 2; break;
        default: actionType = 0; break;
      }

      const placeOrder = () => {
        const data: any = {
          amount: this.amount,
          price: this.price,
          market: this.marketService.activeMarket.id,
          type: typeMap.indexOf(this.type),
          action: actionType,
          post_only: this.orderForm.controls.post_only.value
        };
        this.marketService.createOrder(data).subscribe((response) => {
          if (this.showConfirmation || response.reason === 'CREATEORDER_PRICE_CHECK') {
            // checking for specific error messages
            if (response.reason === 'CREATEORDER_NOTENOUGHBALANCE' || response.response === 'success') {
              this.snackbar.open(this.translateService.translateResponse(response.reason),
                this.i18n('Close'), { duration: 2000 });
            } else {
              this.dialog.open(MarketConfirmationComponent, {
                width: '500px',
                data: {
                  title: response.response === 'success' ? 'Success' : 'Failed',
                  result: response.response === 'success' ? 'success' : 'failed',
                  reason: response.reason
                }
              });
            }
          } else if (response.response !== 'success') {
            this.snackbar.open(this.translateService.translateResponse(response.reason),
                this.i18n('Close'), { duration: 5000 });
          }

          if (response.response === 'success') {
            this.store.updateBalance({
              code: data.type === 0 ?
                this.marketService.activeMarket.exchangeCode : this.marketService.activeMarket.coinCode,
              balance_available: response.balance_available
            });
            this.amount = 0;
            this.price = 0;
            this.amountChange();
            this.priceChange();
            this.orderForm.controls.amount.markAsUntouched();
            this.orderForm.controls.total.markAsUntouched();
            this.orderForm.controls.net_total.markAsUntouched();
            this.orderForm.controls.post_only.setValue(false);
          }
        });
      };

      if (this.executionType === 'market') {
        const orderType = this.type.charAt(0).toUpperCase() + this.type.slice(1);
        const executionType = this.executionType.charAt(0).toUpperCase() + this.executionType.slice(1);
        const dialogData: DialogData = {
          title: this.i18n(`{{executionType}} {{orderType}} {{coincode}}`,
            { executionType, orderType, coincode: this.marketService.activeMarket.coinCode }),
          result: 'string',
          reason: this.i18n(`Are you sure you would like to place this {{type}} order?<br>
                  <b>Your order will be executed against orders currently in the market's orderbook,
                  </b>, estimated values as follows:
                  <br><b>Market: </b>{{pair}}
                  <br><b>Amount: </b>{{amount}} {{coincode}}
                  <br><b>ESTIMATED Price: </b>{{price}} {{exchangecode}}
                  <br><b>Net Total: </b>{{nettotal}}
                  {{exchangecode}} <br><br>
                  Please ensure that the above amounts are correct before confirming.
                  We will be unable to refund/cancel any live order that has been matched.<br><br>`, {
              pair: this.marketService.activeMarket.marketPair,
              amount: this.amount,
              coincode: this.marketService.activeMarket.coinCode,
              price: this.priceMarket,
              exchangecode: this.marketService.activeMarket.exchangeCode,
              nettotal: this.netTotal,
              type: this.type
            }),
          okButton: true,
          isTranslated: true,
          titleTranslated: true
        };
        const dialogRef = this.dialog.open(MarketConfirmationComponent, {
          width: '600px',
          data: dialogData
        });
        dialogRef.afterClosed().subscribe((result) => {
          if (!!result && result.result === 'accept') {
            placeOrder();
          }
        });
      } else if (this.showConfirmation) {
        const orderType = this.type.charAt(0).toUpperCase() + this.type.slice(1);
        const dialogData: DialogData = {
          title: this.i18n(`{{orderType}} {{coincode}}`,
            { orderType, coincode: this.marketService.activeMarket.coinCode }),
          result: 'string',
          reason: this.i18n(`Are you sure you would like to place this {{type}} order?<br>
                  <br><b>Market: </b>{{pair}}
                  <br><b>Amount: </b>{{amount}} {{coincode}}
                  <br><b>Price: </b>{{price}} {{exchangecode}}
                  <br><b>Net Total: </b>{{nettotal}}
                  {{exchangecode}} <br><br>
                  Please ensure that the above amounts are correct before confirming.
                  We will be unable to refund/cancel any live order that has been matched.<br><br>`, {
              pair: this.marketService.activeMarket.marketPair,
              amount: this.amount,
              coincode: this.marketService.activeMarket.coinCode,
              price: this.price,
              exchangecode: this.marketService.activeMarket.exchangeCode,
              nettotal: this.netTotal,
              type: this.type
            }),
          okButton: true,
          isTranslated: true,
          titleTranslated: true
        };
        const dialogRef = this.dialog.open(MarketConfirmationComponent, {
          width: '600px',
          data: dialogData
        });
        dialogRef.afterClosed().subscribe((result) => {
          if (!!result && result.result === 'accept') {
            placeOrder();
          }
        });
      } else {
        placeOrder();
      }
    }
  }

  priceChange() {
    this.price = this.valueChange(this.price, this.decimalSpaces);

    this.calculateFeeToUse();
    this.calculateTotal();
    this.calculateFee();
    this.calculateNetTotal();

    const maximumValue = (this.type === 'buy' ? false :
      (this.executionType === 'market' ? this.amountAvailable : this.balance?.balance_available));
    this.minAmount = this.marketService.getMinAmount(
      this.minAmount, maximumValue, this.orderForm, +this.price, this.calcFeeToUse('takerFee'));
    this.orderForm.controls.total.markAsTouched();
  }

  setActiveMarket() {
    this.decimalSpaces = Number(this.marketService.activeMarket.exchangeDecimals);
    if (this.marketService.activeMarket.exchangeCode === 'ZAR') {
      this.feeDecimalSpaces = this.marketService.activeMarket.coinDecimals ?
      Number(this.marketService.activeMarket.coinDecimals) : 8;
    } else {
      this.feeDecimalSpaces = Number(this.marketService.activeMarket.exchangeDecimals);
    }
    this.amountDecimalSpaces = Number(this.marketService.activeMarket.coinDecimals);

    if (Number.isNaN(this.decimalSpaces)) {
      this.decimalSpaces = 8;
      this.feeDecimalSpaces = 8;
    }

    if (Number.isNaN(this.amountDecimalSpaces)) {
      this.amountDecimalSpaces = 8;
    }

    this.totalMinimum = ['ZAR', 'USDT'].indexOf(this.marketService.activeMarket.exchangeCode) === -1 ? 0.0001 : 1;
    this.setupFormValidation();
    if (!this.CDRef['destroyed']) {
      this.CDRef.detectChanges();
    }

    if (this.balanceSub) {
      this.balanceSub.unsubscribe();
    }

    this.exchangeCode = !!this.marketService.activeMarket ? this.marketService.activeMarket.exchangeCode : '';
    this.coinCode = this.marketService.activeMarket.coinCode;

    if (this.marketService.activeMarket.id !== '-1') {
      this.balanceSub = this.store.subscribe('balances').subscribe(response => {
        if (!response.refresh) {
          const filteredResponse = response.data.filter(responseBalance => responseBalance.code === (
            this.type === 'buy' ?
            this.exchangeCode : this.coinCode))[0];
          if (filteredResponse !== undefined) {
            this.balance = filteredResponse;
            this.balance.balance_available = Number(this.balance.balance_available);
            if (!this.CDRef['destroyed']) {
              this.CDRef.detectChanges();
            }
          }
        }
      });
    }
    this.orderForm.markAsPristine();
    this.firstLoad = true;
  }

  totalChange() {
    this.total = this.valueChange(this.total, this.decimalSpaces);
    if (this.type === 'buy') {
      this.price = (+this.price).toFixed(this.decimalSpaces);
    } else {
      this.validateTotal();
    }

    this.amount = this.valueChange(new Big(+this.total).div(+this.price), this.amountDecimalSpaces);
    const calculatedTotal = this.valueChange(new Big(+this.amount).times(+this.price), this.decimalSpaces + 1);
    if (+this.total !== +calculatedTotal) {
      const multiplier = new Big(10).pow(this.amountDecimalSpaces);
      let calculatedAmount: number = +this.valueChange(new Big(+this.total)
        .div(+this.price), this.amountDecimalSpaces + 1);
      const roundedCalc = Math.round(multiplier.times(calculatedAmount).add(1));
      calculatedAmount = new Big(roundedCalc).div(multiplier);
      this.amount = calculatedAmount.toFixed(this.amountDecimalSpaces);
    }

    this.calculateFee();
    this.calculateNetTotal();
  }

  validateTotal() {
    this.orderForm.controls.total.markAsTouched();
  }

  displayFee() {
    if (this.type === 'buy') {
      return this.getPercentage(this.marketService.activeMarket.takerFee);
    } else {
      return this.getPercentage(this.marketService.activeMarket.makerFee);
    }
  }
}

import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import Big from 'big.js';

import { MarketsService } from '../../markets.service';
import { StoreService, SubscriptionLike } from '../../../core/services/store.service';
import { orderType } from '../order-types-shared';

import * as _ from 'lodash';
import { UtilHelper } from 'src/app/core/helpers/util-helper';
import { I18nTranslationService } from 'src/app/core/services/i18n-translation.service';
import { I18n } from '@ngx-translate/i18n-polyfill';

const distributions = {
  flat: 0,
  increasing: 1,
  decreasing: 2,
  custom: 3,
  0: 'flat',
  1: 'increasing',
  2: 'decreasing',
  3: 'custom',
};

interface IOrder {
  count: number;
  price: string;
  amount: number;
  total: number;
  netTotal: number;
  totalAmount: string;
  type: number;
  action: number;
  market: string;
  fee: string;
  invalid: boolean;
}

const orderTypes = orderType;

@Component({
  selector: 'app-scaled-order-chainex',
  templateUrl: './scaled-order-chainex.component.html',
  styleUrls: ['./scaled-order-chainex.component.scss']
})
export class ScaledOrderComponent implements OnInit, OnDestroy {
  public valueChange: Function = UtilHelper.bigValueToFixed;

  decimalSpaces: number = 8;
  amountDecimalSpaces: number = 8;
  feeDecimalSpaces: number = 8;
  orderType: Number = 0;
  amount: Number | Big | string = new Big(0).toFixed(8);
  orderCount: Number | Big | string = 3;
  priceLower: Number | Big | string = new Big(0).toFixed(8);
  priceUpper: Number | Big | string = new Big(0).toFixed(8);
  distribution: Number = distributions.flat;
  tickSize: Number | Big = 0.0000001;

  totalPrice: Number | Big = 0;
  totalFee: Number | Big = 0;
  totalNetTotal: Number | Big = 0;
  totalAmount: Number | Big = 0;

  minAmount: Number | Big;
  maxAmount: Number | Big;

  fee: number | Big | string = new Big(0).toFixed(8);
  feePercentage: number | Big | string = new Big(0).toFixed(8);

  feeType: string = 'takerFee';

  totalMinimum: number = 0.0001;
  amountMinimum: Number | Big | string = new Big(0).toFixed(8);
  priceMinimum: Number | Big |string = new Big(0).toFixed(8);

  priceForFeeCalcBuy: number | Big | string = new Big(0).toFixed(8);
  priceForFeeCalcSell: number | Big | string = new Big(0).toFixed(8);

  minimumAmountAllowed: Number | Big = 0.001;

  orderForm: FormGroup;

  orders: IOrder[];

  ordersTableDefinition: string[] = ['number', 'amount', 'price', 'total', 'fee', 'netTotal'];

  distributionValues: number[] = [50, 50, 50, 50, 50];

  activeMarketSub: SubscriptionLike;
  balanceSubs: SubscriptionLike;

  balance: any = {
    balance: { balance_available: 0.00000000, code: '' }
  };

  minOrderCount: number = 3;
  maxOrderCount: number = 50;
  digitalMarket: boolean = true;

  constructor(
    public dialogRef: MatDialogRef<ScaledOrderComponent>,
    public dialog: MatDialog,
    public marketService: MarketsService,
    private formBuilder: FormBuilder,
    private store: StoreService,
    private translateService: I18nTranslationService,
    public snackbar: MatSnackBar,
    private i18n: I18n,
    private CDRef: ChangeDetectorRef
  ) {
    this.orderForm = this.formBuilder.group({
      amount: ['', [Validators.required]],
      orders: ['', [Validators.required]],
      priceLow: ['', [Validators.required]],
      priceHigh: ['', [Validators.required]],
    }, {validator: this.priceValidate()});
  }

  ngOnInit() {
    this.activeMarketSub = this.marketService.activeMarketSubject.subscribe(this.setActiveMarket);

    this.setActiveMarket();
    this.setupFormValidation();
    this.redrawDistribution();
  }

  ngOnDestroy() {
    this.activeMarketSub.unsubscribe();
    if (this.balanceSubs) {
      this.balanceSubs.unsubscribe();
    }
  }

  setActiveMarket() {
    if (this.marketService) {
      if (this.marketService.activeMarket.exchangeDecimals) {
        this.decimalSpaces = Number(this.marketService.activeMarket.exchangeDecimals);
      }
      if (this.marketService.activeMarket.coinDecimals) {
        this.amountDecimalSpaces = Number(this.marketService.activeMarket.coinDecimals);
      }
      this.feeDecimalSpaces = (this.marketService.activeMarket.exchangeCode === 'ZAR' ?
        this.marketService.activeMarket.coinDecimals :
        this.marketService.activeMarket.exchangeDecimals) || 8;
      this.totalMinimum = ['ZAR', 'USDT'].indexOf(this.marketService.activeMarket.exchangeCode) === -1 ? 0.0001 : 1;
      this.amountMinimum =
        ['ZAR', 'USDT'].indexOf(this.marketService.activeMarket.exchangeCode) === -1 ? 0.00000001 : 0.01;
      this.priceMinimum =
        ['ZAR', 'USDT'].indexOf(this.marketService.activeMarket.exchangeCode) === -1 ? 0.00000001 : 0.01;
      this.digitalMarket =
        ['ZAR', 'USDT'].indexOf(this.marketService.activeMarket.exchangeCode) === -1 ? true : false;

      if (this.balanceSubs) {
        this.balanceSubs.unsubscribe();
      }

      if (this.marketService.activeMarket.id !== '-1') {
        this.balanceSubs = this.store.subscribe('balances').subscribe(response => {
          if (!response.refresh) {
            const filteredResponse = response.data.filter(responseBalance => responseBalance.code === (
              this.orderType === 0 ?
                this.marketService.activeMarket.exchangeCode :
                this.marketService.activeMarket.coinCode))[0];
            if (filteredResponse !== undefined) {
              this.balance = filteredResponse;
              this.balance.balance_available = Number(this.balance.balance_available);
              if (!this.CDRef['destroyed']) {
                this.CDRef.detectChanges();
              }
            }
          }
        });
      }

      this.amountChange();
      this.lowerPriceChange();
      this.higherPriceChange();
    }
  }

  netValidator() {
    return (control) => {
      const value = +control.value;
      let _max = 0;
      _max = this.orderType === orderTypes.buy ?
        +(this.marketService.activeMarket.coinBalance.balance_available) :
        +(this.marketService.activeMarket.exchangeBalance.balance_available);
      if (this.orderType === orderTypes.buy || value === null || !value || +value === 0) {
        return null;
      }
      return !isNaN(value) && value > _max ? { 'max': { 'max': _max, 'actual': control.value } } : null;
    };
  }

  fillAmount() {
    if (this.orderType === orderTypes.buy) {
    } else {
      this.amount = this.marketService.activeMarket.coinBalance.balance_available;
    }
  }

  rangeChange() {
    this.distribution = distributions.custom;
    this.redrawDistribution();
  }

  getCoinCode(opposite: boolean) {
    if (this.orderType === orderTypes.buy && !opposite) {
      return this.marketService.activeMarket.coinCode;
    } else {
      return this.marketService.activeMarket.exchangeCode;
    }
  }

  redrawDistribution() {
    const svgContainer = document.querySelector('.scaled-control svg');
    if (!!svgContainer) {
      const lines = svgContainer.querySelectorAll('line');
      const circle = svgContainer.querySelectorAll('circle');
      for (let i = 0; i < this.distributionValues.length - 1; i++) {
        // LINE Y index 0 to 45
        lines[i].setAttribute('y1', ((100 - this.distributionValues[i]) / 100 * 45).toString());
        lines[i].setAttribute('y2', ((100 - this.distributionValues[i + 1]) / 100 * 45).toString());
      }

      for (let i = 0; i < (circle.length) - 1; i++) {
        if (i === 0) {
          circle[0].setAttribute('cy', ((100 - this.distributionValues[0]) / 100 * 45).toString());
        }
        circle[i + 1].setAttribute('cy', ((100 - this.distributionValues[i + 1]) / 100 * 45).toString());
      }
    }
  }

  distributionChange() {
    switch (this.distribution) {
      case distributions.flat:
        this.distributionValues[0] = 50;
        this.distributionValues[1] = 50;
        this.distributionValues[2] = 50;
        this.distributionValues[3] = 50;
        this.distributionValues[4] = 50;
        break;
      case distributions.decreasing:
        this.distributionValues[0] = 100;
        this.distributionValues[1] = 75;
        this.distributionValues[2] = 50;
        this.distributionValues[3] = 25;
        this.distributionValues[4] = 0;
        break;
      case distributions.increasing:
        this.distributionValues[0] = 0;
        this.distributionValues[1] = 25;
        this.distributionValues[2] = 50;
        this.distributionValues[3] = 75;
        this.distributionValues[4] = 100;
        break;
      default:
        return;
    }
    this.redrawDistribution();
  }

  roundToTickSize(tickSize: number | Big, price: number | Big) {
    const tp = new Big(tickSize);

    const p = +price.toString();
    const t = +tickSize.toString();

    const rounded = p - (p % t) + (p % t < t / 2 ? 0 : t);
    const roundedBig = new Big(rounded);

    return roundedBig.toBigPlaces(tp.dp()).toNumber();
  }

  getAmountDistribution = (distribution: number, orderCount: number) => {
    const pricePointPercentages: number[] = [];
    // Min and max percentage of the amount allocated per price point
    const minPercentage = 0.05;
    const maxPercentage = 0.5;
    switch (distribution) {
      case distributions.decreasing:
      case distributions.increasing:

        for (let i = 0; i < orderCount; i++) {
          pricePointPercentages[i] =
            minPercentage +
            (i * (maxPercentage - minPercentage)) / (orderCount);
        }

        if (distribution === distributions.decreasing) {
          return pricePointPercentages.reverse();
        }

        return pricePointPercentages;

      case distributions.custom:

        let distIndex = 0;
        let progression = 0;
        let currentPercentage = 0;
        let nextPercentage = 0;
        const numberOfDots = (orderCount < 5 ? orderCount : 5);

        for (let i = 0; i < orderCount; i++) {
          progression = i / (orderCount) * numberOfDots;
          distIndex = _.floor(progression, 0);
          progression -= distIndex;

          currentPercentage = this.distributionValues[distIndex];
          nextPercentage = this.distributionValues[distIndex];

          currentPercentage /= 200;
          nextPercentage /= 200;

          if (currentPercentage > nextPercentage) {
            currentPercentage += nextPercentage;
            nextPercentage = currentPercentage - nextPercentage;
          }

          currentPercentage += (nextPercentage - currentPercentage) * progression;

          if (currentPercentage < minPercentage) {
            currentPercentage = minPercentage;
          }
          if (currentPercentage > maxPercentage) {
            currentPercentage = maxPercentage;
          }

          if (nextPercentage < minPercentage) {
            nextPercentage = minPercentage;
          }
          if (nextPercentage > maxPercentage) {
            nextPercentage = maxPercentage;
          }

          pricePointPercentages[i] =
            currentPercentage +
            (i * (nextPercentage - currentPercentage)) / (orderCount);
        }

        return pricePointPercentages;

      default:
      case distributions.flat:
        return _.range(orderCount).map(x => 100 / orderCount);

    }
  }

  distributeAmount = (total: number, weights: number[]) => {
    // let leftover = 0;
    const distributedTotal: number[] = [];
    let distributionSum = _.sum(weights);

    weights.forEach(weight => {
      const val = +this.valueChange((weight * total) / distributionSum, this.amountDecimalSpaces);

      let weightedValue = val;

      if (val < this.minimumAmountAllowed) {
        weightedValue = 0;
        distributionSum -= weight;
      }
      distributedTotal.push(weightedValue);
    });
    return distributedTotal;
  }

  amountChange() {
    this.amount = this.valueChange(this.amount, this.amountDecimalSpaces);
  }

  lowerPriceChange() {
    this.priceLower = this.valueChange(this.priceLower, this.decimalSpaces);
  }

  higherPriceChange() {
    this.priceUpper = this.valueChange(this.priceUpper, this.decimalSpaces);
  }

  LoadValue(values: any) {
    // this updates the price used to check if order is taker/maker
    if (!!values[orderTypes[0]] && !!values[orderTypes[0]].feePrice) {
      this.priceForFeeCalcBuy = (+values[orderTypes[0]].feePrice || 0).toFixed(this.amountDecimalSpaces);
    }
    if (!!values[orderTypes[1]] && !!values[orderTypes[1]].feePrice) {
      this.priceForFeeCalcSell = (+values[orderTypes[1]].feePrice || 0).toFixed(this.amountDecimalSpaces);
    }

  }

  calculateFeeToUse() {
    const tempTakerFee = (+this.marketService['takerFee'] < +this.marketService.activeMarket['takerFee'])
      ? +this.marketService['takerFee'] : +this.marketService.activeMarket['takerFee'];
    const tempMakerFee = (+this.marketService['makerFee'] < +this.marketService.activeMarket['makerFee'])
      ? +this.marketService['makerFee'] : +this.marketService.activeMarket['makerFee'];
    this.feeType = (tempTakerFee > tempMakerFee) ? 'takerFee' : 'makerFee';
    this.feePercentage = (tempTakerFee > tempMakerFee) ? tempTakerFee : tempMakerFee;
  }

  calculateFee(price: Number | Big, total: Number | Big) {
    try {
      this.calculateFeeToUse();

      if (this.orderType === 0) {
        if (['ZAR'].indexOf(this.marketService.activeMarket.exchangeCode) !== -1) {
          this.fee = price > 0 ? this.valueChange(new Big(+total)
            .times(this.feePercentage).div(+price), this.decimalSpaces)
            : 0;
        } else {
          this.fee = this.valueChange(new Big(+total)
            .times(this.feePercentage), this.decimalSpaces);
        }
      } else {
        if (['ZAR'].indexOf(this.marketService.activeMarket.exchangeCode) !== -1) {
          this.fee = price > 0 ? this.valueChange(new Big(+total)
            .times(this.feePercentage).div(price), this.decimalSpaces)
            : 0;
        } else {
          this.fee = this.valueChange(new Big(+total)
            .times(this.feePercentage), this.decimalSpaces);
        }
      }
    } catch (ex) { }
  }

  generateOrders() {
    this.orderForm.controls.amount.setErrors(null);
    this.orderForm.controls.amount.updateValueAndValidity();
    this.orderForm.controls.amount.markAsTouched();
    this.orderForm.controls.orders.markAsTouched();
    this.orderForm.controls.priceLow.markAsTouched();
    this.orderForm.controls.priceHigh.markAsTouched();

    if (this.orderForm.valid) {
      const weights = this.getAmountDistribution(+this.distribution, +this.orderCount);
      const orderSizes = this.distributeAmount(+this.amount, weights);
      const priceDiff: number = +this.priceUpper - +this.priceLower;
      const stepsPerPricePoint: number = priceDiff / (+this.orderCount - 1);

      // Generate the prices we're placing orders at
      const orderPrices: number[] = [];
      for (let i = 0; i < this.orderCount; i++) {
        // Lower price
        if (i === 0) {
          orderPrices.push(+this.priceLower);
        } else if (i === +this.orderCount - 1) {
          orderPrices.push(+this.priceUpper);
        } else {
          orderPrices.push(+this.priceLower + stepsPerPricePoint * i);
        }
      }

      let minPrice = Infinity;
      let maxPrice = -Infinity;
      let minTotal = Infinity;
      let totalPrice = 0;
      let totalFee = 0;
      let total = 0;
      let totalNetTotal = 0;
      const totalAmount = this.amount;

      const orders = orderPrices.reduce((acc, curr, index) => {
        minPrice = Math.min(minPrice, curr);
        maxPrice = Math.max(maxPrice, curr);

        total = +(new Big(curr)).times(orderSizes[index]);
        this.calculateFee(curr, total);
        totalPrice += total;
        totalFee += +this.fee;
        let netTotal = 0;
        if (['ZAR', 'USDT'].indexOf(this.marketService.activeMarket.exchangeCode) !== -1) {
          let currentFee: any = 0;
          currentFee = new Big(+curr).times(+orderSizes[index]).times(new Big(this.feePercentage));
          netTotal = +this.valueChange(new Big(+total)[this.orderType === 0 ? 'plus' : 'minus'](currentFee)
            , this.decimalSpaces);
        } else {
          netTotal = +this.valueChange(new Big(+total)[this.orderType === 0 ? 'plus' : 'minus'](+this.fee)
            , this.decimalSpaces);
        }
        totalNetTotal += netTotal;

        minTotal = Math.min(minTotal, total);

        return acc.concat({
          count: index + 1,
          price: curr.toFixed(8),
          amount: orderSizes[index], // _.floor(orderSizes[index], 0)
          total: total,
          totalAmount: totalAmount,
          netTotal: netTotal,
          type: this.orderType,
          action: 0,
          market: this.marketService.activeMarket.id,
          fee: this.fee,
          invalid: (this.getCoinCode(true) === 'ZAR' ? total < 1 : total < 0.0001)
        });
      }, []);
      this.totalPrice = totalPrice;
      this.totalFee = totalFee;
      this.totalNetTotal = totalNetTotal;
      this.totalAmount = totalAmount;

      // Verify that the generated orders match the specification so that we don't end up poor

      let failCreate: boolean = false;
      const amountErrors = {};

      if (minTotal < this.totalMinimum) {
        amountErrors['minTotal'] = true;
      }

      if (this.orderType === orderTypes.buy &&
        this.totalNetTotal > this.balance.balance_available) {
          amountErrors['availableBalance'] = true;
      } else if (this.orderType === orderTypes.sell &&
        Math.abs(_.sumBy(orders, order => order.amount)) > this.balance.balance_available) {
          amountErrors['availableBalance'] = true;
      }

      if (Object.keys(amountErrors).length > 0) {
        failCreate = true;
        this.orderForm.controls.amount.setErrors(amountErrors);
      }

      if (this.orderCount % 1 !== 0 && !failCreate) {
        this.orderForm.controls.orders.setErrors({ 'wholeNumber': true });
        failCreate = true;
      }

      if (minPrice < this.priceLower && !failCreate) {
        this.orderForm.controls.priceLow.setErrors({ 'orderLowerPrice': true });
        failCreate = true;
      }

      if (maxPrice > this.priceUpper && !failCreate) {
        this.orderForm.controls.priceHigh.setErrors({ 'orderUpperPrice': true });
        failCreate = true;
      }

      this.orders = orders;
    } else {
      return;
    }
  }

  placeOrders() {
    this.generateOrders();
    if (!this.orderForm.valid) {
      return;
    }
    const data: object = { 'orders': this.orders };

    this.marketService.createScalingOrders(data).subscribe((response) => {
      if (response.response === 'success') {
        this.amount = 0;
        this.store.updateBalance({
          code: this.orderType === 0 ?
            this.marketService.activeMarket.exchangeCode : this.marketService.activeMarket.coinCode,
          balance_available: response.balance_available
        });
      } else {
        this.snackbar.open(this.translateService.translateResponse(response.reason),
                this.i18n('Close'), { duration: 2000 });
      }
    });

    this.dialogRef.close();
  }

  orderTypeChange() {
    this.setupFormValidation();
    this.setActiveMarket();
    if (+this.amount !== 0) {
      this.generateOrders();
    }
  }

  resetFormValidation() {
    Object.keys(this.orderForm.controls).forEach(key => {
      this.orderForm.get(key).setValidators(null);
      this.orderForm.get(key).updateValueAndValidity();
    });
  }

  priceValidate() {
    return (group: FormGroup): {[key: string]: any} => {
      if (group.controls.priceLow.errors && !group.controls.priceLow.errors.max) {
        return;
      }
      if (group.controls.priceHigh.errors) {
        return;
      }
      const lower = group.controls.priceLow;
      const upper = group.controls.priceHigh;
      if (lower.value > upper.value) {
        this.orderForm.controls.priceLow.setErrors( { 'max': { 'max': +upper.value, 'actual': +lower.value } });
      } else {
        this.orderForm.controls.priceLow.setErrors(null);
      }
      return;
    };
  }

  setupFormValidation() {
    this.resetFormValidation();

    this.orderForm.controls.amount.setValidators(null);
    this.orderForm.controls.amount.updateValueAndValidity();
    this.orderForm.controls.amount.setValidators([Validators.required, Validators.min(this.amountMinimum)]);
    this.orderForm.controls.amount.updateValueAndValidity();

    this.orderForm.controls.orders.setValidators(null);
    this.orderForm.controls.orders.updateValueAndValidity();
    this.orderForm.controls.orders.setValidators([Validators.required,
      Validators.min(this.minOrderCount), Validators.max(this.maxOrderCount)]);
    this.orderForm.controls.orders.updateValueAndValidity();

    this.orderForm.controls.priceLow.setValidators(null);
    this.orderForm.controls.priceLow.updateValueAndValidity();
    this.orderForm.controls.priceLow.setValidators([Validators.required,
        Validators.min(this.priceMinimum)]);
    this.orderForm.controls.priceLow.updateValueAndValidity();

    this.orderForm.controls.priceHigh.setValidators(null);
    this.orderForm.controls.priceHigh.updateValueAndValidity();
    this.orderForm.controls.priceHigh.setValidators([Validators.required,
        Validators.min(this.priceMinimum)]);
    this.orderForm.controls.priceHigh.updateValueAndValidity();

    this.orderForm.markAsPristine();
  }
}

import { Component } from '@angular/core';

/*
 * This component is used purely to keep the page on the same route
 */
@Component({
  selector: 'app-marketparam',
  template: ''
})
export class MarketparamComponent {

}

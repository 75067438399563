import { Component, OnInit, Input, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as Big from 'big-js';

import { MarketsService } from '../../markets.service';
import { MarketConfirmationComponent, DialogData } from '../../market-confirmation/market-confirmation.component';
import { StoreService } from '../../../core/services/store.service';
import {
  IConfirmDialogData,
  ConfirmDialogComponent,
  DialogTypes,
} from 'src/app/shared/confirm-dialog/confirm-dialog.component';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { I18nTranslationService } from 'src/app/core/services/i18n-translation.service';
import { SubscriptionLike } from 'rxjs';
import { orderType, executionType } from '../order-types-shared';
import { UtilHelper } from 'src/app/core/helpers/util-helper';
import {environment} from '../../../../environments/environment';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-market-order-chainex',
  templateUrl: './market-order-chainex.html',
  styleUrls: ['./market-order-chainex.scss']
})
export class MarketOrderComponent implements OnInit, OnDestroy {
  public valueChange: Function = UtilHelper.bigValueToFixed;
  @Input('orderValues')
  set orderValues(values: any) {
    if (Object.keys(values).length > 0) {
      this.LoadValue(values);
      this.setupFormValidation();
    }
  }

  exchangeName: string = environment.config.EXCHANGE_NAME_L;
  amountAvailable: number | Big = new Big(0).toFixed(8);

  decimalSpaces: number = 8;
  amountDecimalSpaces: number = 8;
  feeDecimalSpaces: number = 8;

  amount: number | Big = new Big(0).toFixed(8);
  price: number | Big = new Big(0).toFixed(8);
  fee: number | Big = new Big(0).toFixed(8);
  total: number | Big = new Big(0).toFixed(8);
  netTotal: number | Big = new Big(0).toFixed(8);

  netTotalMinimum: number = 0.0001;
  minAmount: number | Big = new Big(0).toFixed(8);
  feeMinAmount: number | Big = new Big(0).toFixed(8);
  minAmountBuy: number | Big = new Big(0).toFixed(8);
  minAmountSell: number | Big = new Big(0).toFixed(8);
  priceForFeeCalc: number | Big = new Big(0).toFixed(8);
  feePercentage: number | Big = new Big(0).toFixed(8);
  firstPrice: number | Big = new Big(0).toFixed(8);

  feeType: string = 'takerFee';

  priceMarket: number | Big = new Big(0).toFixed(8);
  priceMarketBuy: number | Big = new Big(0).toFixed(8);
  priceMarketSell: number | Big = new Big(0).toFixed(8);
  orderBook: any = [];
  orderBookBuy: any = [];
  orderBookSell: any = [];

  orderForm: FormGroup;

  showConfirmation: boolean = true;

  subs: SubscriptionLike[] = [];
  balanceSubs: SubscriptionLike;

  balance: any = {
    balance: { balance_available: 0.00000000, code: '' }
  };

  @Input() type: string = 'buy'; // Buy = 0, Sell = 1

  executionType: string = '';
  exchangeCode: string = '';
  coinCode: string = '';
  displayPrice: boolean = true;

  firstLoad: boolean = true;
  priceDeviationError: boolean = false;
  priceDeviationMessage: string = `We are currently unable to place market orders for the specified amount due to price fluctuations.
                                   Please try a different amount or make use of limit orders until the market’s prices stabilize.`;
  private sellAmountAvailable: number | Big = new Big(0).toFixed(8);
  private buyAmountAvailable: number | Big = new Big(0).toFixed(8);

  constructor(
    public dialog: MatDialog,
    public marketService: MarketsService,
    private formBuilder: FormBuilder,
    private store: StoreService,
    private i18n: I18n,
    private translateService: I18nTranslationService,
    public snackbar: MatSnackBar,
    private CDRef: ChangeDetectorRef
  ) {
    this.orderForm = this.formBuilder.group({
      amount: ['', Validators.required],
      price: ['', Validators.required],
      total: ['', Validators.min(0.0001)],
      fee: [''],
      net_total: ['', Validators.min(0.0001)]
    });
  }

  ngOnInit() {
    this.subs.push(this.marketService.activeMarketSubject.subscribe((response) => {
      this.setActiveMarket();
    }));

    this.setActiveMarket();
    this.subs.push(this.store.subscribe('settings').subscribe((response) => {
      if (!response.refresh) {
        this.showConfirmation = !response.data.confirmations ? true : response.data.confirmations === '1';
      }
    }));

    this.orderForm.controls.amount.enable();
    this.orderForm.controls.price.enable();
    this.orderForm.controls.net_total.enable();
    this.displayPrice = false;
    this.setupFormValidation();
    this.amount = 0;
    this.amountChange();
  }

  ngOnDestroy() {
    this.subs.forEach(sub => sub.unsubscribe());
    if (this.balanceSubs) {
      this.balanceSubs.unsubscribe();
    }
  }

  resetFormValidation() {
    Object.keys(this.orderForm.controls).forEach(key => {
      this.orderForm.get(key).setValidators(null);
      this.orderForm.get(key).updateValueAndValidity();
    });
  }

  selectMinAmount() {
    this.processOrderBook();
    if (this.exchangeName === 'burnx') {
      if (this.type === 'sell') {
        this.amountAvailable = this.sellAmountAvailable;
        this.minAmount = this.minAmountBuy;
      } else {
        this.amountAvailable = this.buyAmountAvailable;
        this.minAmount = this.minAmountSell;
      }
    }
  }

  setupFormValidation() {
    this.selectMinAmount();

    const maxValidator = () => (control) => {
      if (+this.amount > +this.amountAvailable) {
        return { max: true, actual: control.value };
      }
      return null;
    };
    const maximumValue = (this.type === 'buy' ? false :
      (!!this.balance ? this.balance?.balance_available : this.amountAvailable));
    this.minAmount = this.marketService.getMinAmount(
      this.minAmount, maximumValue, null, +this.price, this.calcFeeToUse('takerFee'));

    const balanceValidator = () => (control) => {
      if (this.type === 'sell') {
        if (+this.amount > +this.balance.balance_available) {
          return { max2: true };
        }
      } else {
        if (+this.netTotal > +this.balance.balance_available) {
          return { max2: true };
        }
      }
      return null;
    };

    const amountCheck = () => (control) => {
      this.selectMinAmount();
      const minAmount = this.type === 'buy' ? this.minAmountBuy : this.minAmountSell;
      if (+minAmount === 0) {
        if (+minAmount >= +this.amount) {
          return { min0: true };
        }
      } else {
        if (+minAmount > +this.amount) {
          return { min1: true };
        }
      }
      if (this.priceDeviationError) {
        return { priceDeviation: true };
      }
      return null;
    };

    // this.resetFormValidation();
    this.orderForm.controls.amount.updateValueAndValidity();
    this.orderForm.controls.amount.setValidators(null);
    this.orderForm.controls.amount.setValidators(
      [maxValidator(), balanceValidator(), amountCheck(), Validators.min(this.minAmount)]
      );
    this.orderForm.controls.amount.updateValueAndValidity();

    this.orderForm.markAsPristine();
  }

  setActiveMarket() {
    if (this.marketService.activeMarket.exchangeDecimals) {
      this.decimalSpaces = Number(this.marketService.activeMarket.exchangeDecimals);
      this.feeDecimalSpaces = Number(this.marketService.activeMarket.exchangeDecimals);
    } else {
      this.decimalSpaces = ['ZAR', 'USDT'].indexOf(this.marketService.activeMarket.exchangeCode) === -1 ? 8 : 2;
      this.feeDecimalSpaces = ['USDT'].indexOf(this.marketService.activeMarket.exchangeCode) === -1 ? 8 : 2;
    }
    if (this.marketService.activeMarket.coinDecimals) {
    this.amountDecimalSpaces = Number(this.marketService.activeMarket.coinDecimals);
    } else {
      this.amountDecimalSpaces = ['ZAR', 'USDT'].indexOf(this.marketService.activeMarket.coinCode) === -1 ? 8 : 2;
    }
    this.netTotalMinimum = ['ZAR', 'USDT'].indexOf(this.marketService.activeMarket.exchangeCode) === -1 ? 0.0001 : 1;
    if (!this.CDRef['destroyed']) {
      this.CDRef.detectChanges();
    }

    if (this.balanceSubs) {
      this.balanceSubs.unsubscribe();
    }

    this.exchangeCode = !!this.marketService.activeMarket ? this.marketService.activeMarket.exchangeCode : '';
    this.coinCode = this.marketService.activeMarket.coinCode;

    if (this.marketService.activeMarket.id !== '-1') {
      this.balanceSubs = this.store.subscribe('balances').subscribe(response => {
        if (!response.refresh) {
          const filteredResponse = response.data.filter(responseBalance => responseBalance.code === (
            this.type === 'buy' ?
              this.marketService.activeMarket.exchangeCode :
              this.marketService.activeMarket.coinCode))[0];
          if (filteredResponse !== undefined) {
            this.balance = filteredResponse;
            this.balance.balance_available = Number(this.balance.balance_available);
            if (!this.CDRef['destroyed']) {
              this.CDRef.detectChanges();
            }
          }
        }
      });
    }
    this.orderForm.markAsPristine();
    this.firstLoad = true;
  }

  LoadValue(values: any) {

    // this updates the price used to check if order is taker/maker
    if (!!values[this.type] && !!values[this.type].feePrice) {
      this.priceForFeeCalc = (+values[this.type].feePrice || 0).toFixed(this.amountDecimalSpaces);
      this.priceChange();
    }

    // this is for market order types
    if (!!values.price && values.price !== this.price) {
      this.priceForFeeCalc = (+values.price || 0).toFixed(this.amountDecimalSpaces);
      this.price = this.priceForFeeCalc;
    }

    if ((!!values[this.type] || (!!values.price && !!values.amount))) {
      if (!!values[this.type] && this.firstLoad) {
        this.firstLoad = false;
        this.price = +values[this.type].price.toFixed(this.decimalSpaces) ||
          this.marketService.activeMarket.lastPrice;
        if (values.marketChanged) {
          this.amount = 0;
        } else {
          this.amount = (+values[this.type].amount || 0).toFixed(this.amountDecimalSpaces);
        }
        this.amountChange();
        this.orderForm.controls.amount.markAsUntouched();
      } else {
        if (values.userSpecified || values.marketChanged) {
          this.price = +values.price || this.marketService.activeMarket.lastPrice;
          if (values.marketChanged) {
            this.amount = 0;
          } else {
            this.amount = +values.amount || 0;
          }
          this.priceChange();
          this.amountChange();
          if ((this.type === 'buy' && this.netTotal > this.balance.balance_available) ||
            (this.type === 'sell' && this.amount > this.balance.balance_available)) {
            this.fillAmount();
          }
        }
      }
    }

    const otherType = this.type === 'buy' ? 'sell' : 'buy';

    if (!!values[otherType] && !!values[otherType].orders) {
      if (this.exchangeName === 'chainex') {
        this.orderBook = values[otherType].orders;
        if (otherType === 'sell') {
          this.orderBook.sort((a, b) => a.price < b.price ? -1 : 1);
        } else if (otherType === 'buy') {
          this.orderBook.sort((a, b) => a.price > b.price ? -1 : 1);
        }
      } else if (this.exchangeName === 'burnx') {
        this.orderBookBuy = values['buy'].orders;
        this.orderBookBuy.sort((a, b) => a.price > b.price ? -1 : 1);
        this.orderBookSell = values['sell'].orders;
        this.orderBookSell.sort((a, b) => a.price < b.price ? -1 : 1);
      }
      this.amountAvailable = (+values[otherType].totalAmountAvailable).toFixed(this.amountDecimalSpaces);
      this.buyAmountAvailable = (+values['sell'].totalAmountAvailable).toFixed(this.amountDecimalSpaces);
      this.sellAmountAvailable = (+values['buy'].totalAmountAvailable).toFixed(this.amountDecimalSpaces);
      this.processOrderBook();
    }

  }


  calculateAmount() {
    let currentAmount: any = this.amount;
    let currentPrice: any = 0;


    currentPrice = new Big(+this.price).times(new Big(1)[this.type === 'buy' ? 'plus' : 'minus']
    (this.marketService.activeMarket[this.feeType]));
    currentAmount = currentPrice > 0 ? new Big(Number(this.netTotal)).div(currentPrice) : new Big(0);
    currentAmount = currentAmount.round(this.amountDecimalSpaces, 0); // round down to the last decimal place

    this.amount = this.valueChange(currentAmount, this.amountDecimalSpaces);
    // this.cdRef.detectChanges();
  }

  calcFeeToUse(type: string = 'makerFee') {
    let toUse = +this.marketService.activeMarket[type];
    if (this.marketService[type] < toUse) {
      toUse = +this.marketService[type];
    }
    return toUse;
  }

  amountChange() {
    if (this.exchangeName === 'burnx') {
      this.resetFormValidation();
    }

    const maximumValue = (this.type === 'buy' ? false :
      (!!this.balance ? this.balance?.balance_available : this.amountAvailable));
    this.amount = this.valueChange(this.amount, this.amountDecimalSpaces);
    this.minAmount = this.marketService.getMinAmount(
      this.minAmount, maximumValue, null, +this.price, this.calcFeeToUse('takerFee'));
    this.processOrderBook();
    this.orderForm.controls.amount.markAsTouched();
  }

  processOrderBook(checkDeviationOnly: boolean = false) {
    if (this.exchangeName === 'chainex') {
      if (!!this.orderBook && this.orderBook.length > 0) {
        this.calculateMinAmountNeeded(this.orderBook, this.type);
        this.processOrderBookForAmount(this.orderBook, checkDeviationOnly);
      }
    } else if (this.exchangeName === 'burnx') {
      if (!!this.orderBookBuy && this.type === 'sell') {
        this.processOrderBookForAmount(this.orderBookBuy, checkDeviationOnly);
        this.calculateMinAmountNeeded(this.orderBookBuy, 'buy');
      }
      if (!!this.orderBookSell && this.type === 'buy') {
        this.processOrderBookForAmount(this.orderBookSell, checkDeviationOnly);
        this.calculateMinAmountNeeded(this.orderBookSell, 'sell');
      }
    }
  }

  priceChange() {
    this.price = this.valueChange(this.price, this.decimalSpaces);
    const maximumValue = (this.type === 'buy' ? false :
      (!!this.balance ? this.balance?.balance_available : this.amountAvailable));
    this.minAmount = this.marketService.getMinAmount(
      this.minAmount, maximumValue, null, +this.price, this.calcFeeToUse('takerFee'));
    this.processOrderBook();
  }

  placeOrder() {
    const data: any = {
      amount: this.amount,
      price: this.firstPrice,
      market: this.marketService.activeMarket.id,
      type: orderType[this.type.toLowerCase()],
      action: executionType.market
    };
    this.marketService.createOrder(data).subscribe((response) => {

      if (this.showConfirmation) {
        // checking for specific error messages
        if (response.reason === 'CREATEORDER_NOTENOUGHBALANCE' || response.response === 'success') {
          this.snackbar.open(this.translateService.translateResponse(response.reason),
            this.i18n('Close'), { duration: 2000 });
        } else {
          this.dialog.open(MarketConfirmationComponent, {
            width: '500px',
            data: {
              title: response.response === 'success' ? 'Success' : 'Failed',
              result: response.response === 'success' ? 'success' : 'failed',
              reason: response.reason
            }
          });
        }
      } else if (response.response !== 'success') {
        this.snackbar.open(this.translateService.translateResponse(response.reason),
            this.i18n('Close'), { duration: 5000 });
      }

      if (response.response === 'success') {
        this.store.updateBalance({
          code: data.type === 0 ?
            this.marketService.activeMarket.exchangeCode : this.marketService.activeMarket.coinCode,
          balance_available: response.balance_available
        });
        this.amount = 0;
        this.amountChange();
        this.orderForm.controls.amount.markAsUntouched();
      }
    });
  }

  orderBuy() {
    if (this.exchangeName === 'burnx') {
      this.type = 'buy';
      this.setupFormValidation();
    }
    this.orderForm.controls.amount.markAsTouched();
    this.orderForm.controls.amount.updateValueAndValidity();
    this.orderForm.controls.price.markAsTouched();
    this.orderForm.controls.price.updateValueAndValidity();
    this.orderForm.controls.total.markAsTouched();
    this.orderForm.controls.total.updateValueAndValidity();

    this.orderForm.markAsTouched();

    if (this.orderForm.valid) {
      if (this.showConfirmation) {
        const order = this.type.charAt(0).toUpperCase() + this.type.slice(1);
        const execution = 'Market';
        const dialogData: DialogData = {
          title: this.i18n(`{{execution}} {{order}} {{coincode}}`,
            { execution, order, coincode: this.marketService.activeMarket.coinCode }),
          result: 'string',
          reason: this.getConfirmDialog(order),
          okButton: true,
          isTranslated: true,
          titleTranslated: true
        };
        const dialogRef = this.dialog.open(MarketConfirmationComponent, {
          width: '600px',
          data: dialogData
        });
        dialogRef.afterClosed().subscribe((result) => {
          if (!!result && result.result === 'accept') {
            // check price deviation again after confirmed
            this.processOrderBook(true);
            if (this.priceDeviationError) {
              this.dialog.open(MarketConfirmationComponent, {
                width: '500px',
                data: {
                  title: 'Failed',
                  result: 'failed',
                  reason: this.priceDeviationMessage
                }
              });
            } else {
              this.placeOrder();
            }
          }
        });
      } else {
        this.placeOrder();
      }
    }
  }

  getConfirmDialog(order: string): string {
    let formattedReason = this.i18n(`Are you sure you would like to place this {{type}} order?<br /><br />
                                        <b>Your order will be executed against orders currently in the market's orderbook
                                        </b>, estimated values are as follows:
                                        <br /><br /><b>Market: </b>{{pair}}
                                        <br /><b>Amount: </b>{{amount}} {{coincode}}
                                        <br /><b>Estimated Price: </b>{{price}} {{exchangecode}}
                                        <br /><b>Net Total: </b>MARKET
                                        {{exchangecode}} <br><br>
                                        Please ensure that the above amounts are correct before confirming.
                                        We will be unable to refund/cancel any live order that has been matched.<br /><br />
                                        <b>Are you sure you would like to place this order at the following estimated price?</b><br /><br />
                                        {{format}}<b>Estimated Price: </b>{{price}} {{exchangecode}}{{endFormat}}`, {
        pair: this.marketService.activeMarket.marketPair,
        amount: this.amount,
        coincode: this.marketService.activeMarket.coinCode,
        price: this.priceMarket,
        exchangecode: this.marketService.activeMarket.exchangeCode,
        type: order
        });
        formattedReason = formattedReason.replace('{{format}}', '<div class="border-2 py-2 mb-3 text-center">');
        formattedReason = formattedReason.replace('{{endFormat}}', '</div>');
        return formattedReason;

  }

  orderSell() {
    if (this.exchangeName === 'burnx') {
      this.type = 'sell';
      // this.resetFormValidation();

      this.setupFormValidation();
    }
    this.orderForm.controls.amount.markAsTouched();
    this.orderForm.controls.price.markAsTouched();
    this.orderForm.controls.total.markAsTouched();

    this.orderForm.markAsTouched();

    if (this.orderForm.valid) {

      if (this.showConfirmation) {
        const order = this.type.charAt(0).toUpperCase() + this.type.slice(1);
        const execution = 'Market';
        const dialogData: DialogData = {
          title: this.i18n(`{{execution}} {{order}} {{coincode}}`,
            { execution, order, coincode: this.marketService.activeMarket.coinCode }),
          result: 'string',
          reason: this.getConfirmDialog(order),
          okButton: true,
          isTranslated: true,
          titleTranslated: true
        };
        const dialogRef = this.dialog.open(MarketConfirmationComponent, {
          width: '600px',
          data: dialogData
        });
        dialogRef.afterClosed().subscribe((result) => {
          if (!!result && result.result === 'accept') {
            // check price deviation again after confirmed
            this.processOrderBook(true);
            if (this.priceDeviationError) {
              this.dialog.open(MarketConfirmationComponent, {
                width: '500px',
                data: {
                  title: 'Failed',
                  result: 'failed',
                  reason: this.priceDeviationMessage
                }
              });
            } else {
              this.placeOrder();
            }
          }
        });
      } else {
        this.placeOrder();
      }
    }
  }

  fillAmount() {
    if (this.type === 'buy') {
      this.netTotal = this.balance.balance_available;
      this.netTotalChange();
    } else {
      this.amount = this.balance.balance_available;
      this.amountChange();
    }
  }

  netTotalChange() {
    this.netTotal = this.valueChange(this.netTotal, this.decimalSpaces);
    if (!!this.orderBook && this.orderBook.length > 0) {
      this.processOrderBookForNetTotal(this.orderBook);
    }
    this.calculateAmount();
    this.orderForm.controls.amount.markAsTouched();
    this.orderForm.controls.net_total.markAsTouched();
  }

  clear() {
    this.amount = (0).toFixed(this.decimalSpaces);
    this.amountChange();
  }

  processOrderBookForNetTotal(orderBook: any = []) {
    try {
      let feeToUse = +this.marketService.activeMarket[this.feeType];
      if (this.marketService[this.feeType] < feeToUse) {
        feeToUse = +this.marketService[this.feeType];
      }
      let netTotalToOffer: number = this.netTotal;
      let currentNetTotal: number | Big = new Big(0).toFixed(8);
      let amountCalc: number | Big = new Big(0).toFixed(8);
      let totalCalc: number | Big = new Big(0).toFixed(8);
      let totalPrice: number | Big = new Big(0).toFixed(8);
      this.amount = 0;
      const weightCalcData: {
        'amount': number,
        'price': number
      }[] = [];

      for (let i = 0; i < orderBook.length; i++) {
        if (+orderBook[i].amount > 0) {
          currentNetTotal = this.calculateOrderNetTotal(orderBook[i].price, orderBook[i].amount,
            feeToUse, orderBook[i].orderTotal, 'partial');
          let orderTake: number | Big = new Big(0).toFixed(8);
          if (netTotalToOffer - currentNetTotal > 0) {
            orderTake = +orderBook[i].amount;
            totalCalc = new Big(totalCalc).plus(orderBook[i].orderTotal);
            netTotalToOffer = netTotalToOffer - currentNetTotal;
          } else {
            let currentAmount: number | Big = new Big(0).toFixed(8);
            if (['ZAR', 'USDT'].indexOf(this.marketService.activeMarket.exchangeCode) === -1) {
              const currentFee = new Big(1)[this.type === 'buy' ? 'plus' : 'minus'](feeToUse);
              const currentTotal = new Big(+netTotalToOffer).div(currentFee);
              totalCalc = new Big(totalCalc).plus(currentTotal);
              currentAmount = currentTotal.div(+orderBook[i].price);
            } else {
              const currentFee = new Big(1)[this.type === 'buy' ? 'plus' : 'minus'](feeToUse);
              currentAmount = new Big(netTotalToOffer).div(currentFee.times(orderBook[i].price));
              totalCalc = new Big(currentAmount).times(orderBook[i].price);
            }
            orderTake = currentAmount;
            netTotalToOffer = 0;
          }
          weightCalcData.push({
            'amount': new Big(orderTake),
            'price': new Big(orderBook[i].price)
          });

          amountCalc = new Big(amountCalc).plus(orderTake);
          if (netTotalToOffer <= 0) {
            break;
          }
        }
      }
      weightCalcData.forEach(element => {
        const weight = amountCalc > 0 ? new Big(element.amount).div(amountCalc) : new Big(0);
        const thisPrice = new Big(weight).times(element.price);
        totalPrice = new Big(totalPrice).add(thisPrice);
      });
      this.amount = this.valueChange(amountCalc, this.amountDecimalSpaces);
      this.priceMarket = totalPrice;
      this.priceMarket = this.valueChange(this.priceMarket, this.decimalSpaces);
      this.price = this.valueChange(this.priceMarket, this.decimalSpaces);
      this.total = this.valueChange(totalCalc, this.decimalSpaces);
    } catch (ex) {
      console.error(ex);
    }
  }

  // new code

  calculateOrderNetTotal(price: number, amount: number, fee: number, total: number, type: string = 'partial') {
    if (type === 'full') {
      if (['ZAR', 'USDT'].indexOf(this.marketService.activeMarket.exchangeCode) === -1) {
        const currentFee = new Big(1)[this.type === 'buy' ? 'plus' : 'minus'](fee);
        return new Big(amount).times(price).times(currentFee);
      } else {
        const currentFee  = new Big(+amount).times(new Big(fee));
        const feeCalc = new Big(amount)[this.type === 'buy' ? 'plus' : 'minus'](currentFee).times(price);
        return feeCalc;
      }
    } else {
      if (['ZAR', 'USDT'].indexOf(this.marketService.activeMarket.exchangeCode) === -1) {
        const currentFee  = new Big(+price).times(+amount).times(new Big(fee));
        return new Big(+total)[this.type === 'buy' ? 'plus' : 'minus'](currentFee);
      } else {
        const currentFee  = new Big(+amount).times(new Big(fee));
        const feeCalc = new Big(amount)[this.type === 'buy' ? 'plus' : 'minus'](currentFee).times(price);
        return feeCalc;
      }
    }
  }

  processOrderBookForAmount(orderBook: any = [], checkDeviationOnly: boolean = false) {
    try {
      if (+this.amount === 0) {
        return;
      }
      this.priceDeviationError = false;
      let feeToUse = +this.marketService.activeMarket[this.feeType];
      if (this.marketService[this.feeType] < feeToUse) {
        feeToUse = +this.marketService[this.feeType];
      }
      let amountToOffer: number = this.amount;
      let totalAmount: number = 0;
      let totalCalc: number | Big = new Big(0).toFixed(8);
      let totalPrice: number | Big = new Big(0).toFixed(8);
      let totalNetTotal: number | Big = new Big(0).toFixed(8);
      let sumTotal: number = 0;
      let sumAmount: number = 0;
      let orderNetTotal: number = 0;
      if (!checkDeviationOnly) {
        this.priceMarket = new Big(0).toFixed(8);
      }

      for (let i = 0; i < orderBook.length; i++) {
        if (+orderBook[i].amount > 0) {
          totalAmount += +orderBook[i].amount;
          amountToOffer = amountToOffer - orderBook[i].amount;
          let orderTake: number | Big = new Big(0).toFixed(8);
          let weight: number | Big = new Big(0).toFixed(8);
          if (amountToOffer > 0) {
            orderTake = new Big(+orderBook[i].amount).times(+orderBook[i].price);
            orderNetTotal = this.calculateOrderNetTotal(orderBook[i].price, orderBook[i].amount,
              feeToUse, orderBook[i].orderTotal, 'partial');
            totalCalc = new Big(totalCalc).plus(orderTake);
            weight = new Big(+orderBook[i].amount).div(this.amount);
            sumAmount += orderBook[i].amount;
          } else {
            orderTake = (new Big(+orderBook[i].amount)
              .plus(+amountToOffer))
              .times(+orderBook[i].price);
            orderNetTotal = this.calculateOrderNetTotal(orderBook[i].price,
              new Big(orderBook[i].amount).plus(+amountToOffer),
              feeToUse, orderTake, 'full');
            if (orderNetTotal < +this.netTotalMinimum) {
              // amount rounding error occurred, round up again
              const newDigits = this.marketService.activeMarket.coinDecimals - 1;
              const newAmount = new Big(orderBook[i].amount).plus(+amountToOffer).round(newDigits, 3); // round up
              orderNetTotal = this.calculateOrderNetTotal(orderBook[i].price,
                new Big(newAmount),
                feeToUse, orderTake, 'full');
            }
            totalCalc = new Big(totalCalc).plus(orderTake);
            weight = new Big(+orderBook[i].amount)
              .plus(+amountToOffer).div(this.amount);
            sumAmount += +orderBook[i].amount + +amountToOffer;
          }
          sumTotal += +orderTake;
          const thisPrice: number | Big = new Big(+orderBook[i].price).times(weight);
          totalPrice = new Big(totalPrice).plus(thisPrice);
          totalNetTotal = new Big(totalNetTotal).plus(orderNetTotal);
          if ((sumTotal / sumAmount) / orderBook[i].price > 1.05 ||
            (sumTotal / sumAmount) / orderBook[i].price < 0.95) {
            this.priceDeviationError = true;
          }
          if (amountToOffer <= 0) {
            break;
          }
        }
      }

      // get first order's price
      // on the sell order book, this will be the first available price in the array
      this.firstPrice = 0;
      if (this.type === 'buy') {
        let i = 0;
        while (i < orderBook.length && (this.firstPrice === 0)) {
          if (orderBook[i].price > 0) {
            this.firstPrice = orderBook[i].price;
          }
          i++;
        }
      } else {
        this.firstPrice = orderBook[0].price;
      }
      if (sumTotal && sumAmount && (this.firstPrice > 0)) {
        if ((sumTotal / sumAmount) / this.firstPrice > 1.05 ||
          (sumTotal / sumAmount) / this.firstPrice < 0.95) {
          this.priceDeviationError = true;
        }
      }

      if (!checkDeviationOnly) {
        this.priceMarket = this.valueChange(totalPrice, this.decimalSpaces);
        this.price = this.valueChange(this.priceMarket, this.decimalSpaces);
        this.total = this.valueChange(totalCalc, this.decimalSpaces);
        this.netTotal = this.valueChange(totalNetTotal, this.decimalSpaces);
      }
    } catch (ex) {}
  }

  calculateMinAmountNeeded(orderBook: any= [], type: string = '') {
    try {
      let feeToUse = +this.marketService.activeMarket[this.feeType];
      if (this.marketService[this.feeType] < feeToUse) {
        feeToUse = +this.marketService[this.feeType];
      }
      let netTotalToOffer: number = this.netTotalMinimum;
      let currentNetTotal: number | Big = new Big(0).toFixed(8);
      let amountCalc: number | Big = new Big(0).toFixed(8);

      for (let i = 0; i < orderBook.length; i++) {
        if (+orderBook[i].amount > 0) {
          currentNetTotal = this.calculateOrderNetTotal(orderBook[i].price, orderBook[i].amount,
            feeToUse, orderBook[i].orderTotal, 'partial');
          let orderTake: number | Big = new Big(0).toFixed(8);
          if (netTotalToOffer - currentNetTotal > 0) {
            orderTake = +orderBook[i].amount;
            netTotalToOffer = netTotalToOffer - currentNetTotal;
          } else {
            let currentAmount: number | Big = new Big(0).toFixed(8);
            if (['ZAR', 'USDT'].indexOf(this.marketService.activeMarket.exchangeCode) === -1) {
              const currentFee = new Big(1)[this.type === 'buy' ? 'plus' : 'minus'](feeToUse);
              const currentTotal = new Big(+netTotalToOffer).div(currentFee);
              currentAmount = currentTotal.div(+orderBook[i].price);
            } else {
              const currentFee = new Big(1)[this.type === 'buy' ? 'plus' : 'minus'](feeToUse);
              currentAmount = new Big(netTotalToOffer).div(currentFee.times(orderBook[i].price));
            }
            orderTake = currentAmount;
            netTotalToOffer = 0;
          }
          amountCalc = new Big(amountCalc).plus(orderTake, 8);
          if (netTotalToOffer <= 0) {
            break;
          }
        }
      }
      if (type === 'buy') {
        this.minAmountBuy = this.valueChange(amountCalc, this.amountDecimalSpaces);
      } else if (type === 'sell') {
        this.minAmountSell = this.valueChange(amountCalc, this.amountDecimalSpaces);
      }
    } catch (ex) {
    }
  }

}



import { Component, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { SubscriptionLike } from 'rxjs';

import { widget } from '../../../assets/charting_library/charting_library.min';

import { I18nTranslationService } from 'src/app/core/services/i18n-translation.service';
import { MarketsService } from '../markets.service';
import { SessionStorageService } from 'src/app/core/services/session-storage.service';

import { environment } from 'src/environments/environment';

declare const Datafeeds: any;
declare const TradingView: any;
declare global {
  interface Window { DEFAULT_SYMBOL: string; }
}

const BASE_URL = environment.config.BACKEND_URL;

if (!!window['google']) {
  window['google'].charts.load('current', { 'packages': ['corechart'] });
}

@Component({
  selector: 'markets-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss']
})
export class ChartComponent implements OnInit, OnDestroy {
  tab: any = 0;
  darkTheme: boolean = false;
  buyData: any = [];
  chartHandler: any;
  chartID: HTMLDivElement;
  depthChart: any = document.getElementById('chartdiv_orderdepth');
  fullscreenButton: HTMLDivElement;
  mode: any = 0;
  options: any = {
    hAxis: {
      title: this.translateService.translateResponse('Price'),
      titleTextStyle: {
        color: '#333'
      }
    },
    vAxis: {
      title: this.translateService.translateResponse('Volume'),
      titleTextStyle: {
        color: '#333'
      }
    },
    legend: { position: 'none' },
    chartArea: { left: '3%', top: '10%', width: '95%', height: '80%' },
    colors: ['#27ae60', '#c0392b'],
    backgroundColor: '#fff'
  };
  orderChart: any = null;
  orderChartShowing: any = false;
  orderChartTable: any;
  orderChartUpdate: any;
  resizeHandler: any;
  sellData: any = [];
  statsCols: any = 6;
  tabHeight: number = 0;
  tabWidth: number = 0;
  tvWidget: any;
  @Output() hideBuySell: EventEmitter<any> = new EventEmitter();
  activeMarketSub: SubscriptionLike;

  showMarket: boolean = environment.config.MARKET_PAIR;
  exchangeName: any = environment.config.EXCHANGE_NAME_L;

  timeZone: string;

  private subs: SubscriptionLike;

  constructor(
    private sessionStorage: SessionStorageService,
    private translateService: I18nTranslationService,
    public marketsService: MarketsService) {


    window.addEventListener('unload', (event) => {
      event.preventDefault();
      this.activeMarketSub.unsubscribe();
    });
  }

  ngOnInit() {
    window.DEFAULT_SYMBOL = this.marketsService.activeMarket.marketPair;
    const self: any = this;
    this.resizeHandler = function () {
      self.statsCols = window.innerWidth >= 1300 ? 6 : 3;
    };
    window.addEventListener('resize', () => {
      this.resizeHandler();
      this.loadGoogleChart(this);
      const chartID: HTMLDivElement = document.getElementById('chartMe') as HTMLDivElement;
      this.resizeChart(chartID.classList.contains('full-screen'));
    });
    this.resizeHandler();
    this.setChartInit(this);

    this.subs = this.sessionStorage.observe('THEME').subscribe(
      (data) => {
        this.darkTheme = data === 'dark-theme';
        this.updateGraph();
      }
    );

    const sessionTime = this.sessionStorage.get('LOGGED_TIMEZONE');
    this.timeZone = sessionTime ? sessionTime : Intl.DateTimeFormat().resolvedOptions().timeZone;

    this.activeMarketSub = this.marketsService.activeMarketSubject.subscribe((response) => {
      this.loadTradingView();
    });
  }

  ngOnDestroy() {
    window.removeEventListener('resize', () => {
      this.resizeHandler();
      this.loadGoogleChart(this);
      const chartID: HTMLDivElement = document.getElementById('chartMe') as HTMLDivElement;
      this.resizeChart(chartID.classList.contains('full-screen'));
    });
    if (!!this.tvWidget) {
      try {
        this.tvWidget.remove();
        this.tvWidget = null;
      } catch (ex) {
      }
    }
    window.removeEventListener('unload', (event) => {
      event.preventDefault();
      this.activeMarketSub.unsubscribe();
    });
    if (this.fullscreenButton) {
      this.fullscreenButton.removeEventListener('click', () => {
        this.chartID.classList.toggle('full-screen');
        this.hideBuySell.emit(this.chartID.classList.contains('full-screen'));
        this.resizeChart(this.chartID.classList.contains('full-screen'));
      });
    }
    if (!!this.chartHandler) {
      clearInterval(this.chartHandler);
    }

    if (this.subs) {
      this.subs.unsubscribe();
    }
  }

  chartInit() {
    // setup some chart colors for BX or CX
    let loadingScreenBackground = '';
    if (!this.darkTheme) {
      loadingScreenBackground = window['lightColor'];
    } else if (this.darkTheme) {
      loadingScreenBackground = window['darkColor'];
    }
    let upColor = '';
    let upColorAlpha = '';
    if ( this.exchangeName === 'burnx' ) {
      upColor = '#42B17F';
      upColorAlpha = 'rgba(66, 177, 127, 0.5)';
    } else {
      upColor = '#69cc66';
      upColorAlpha = '#69cc66';
    }
    const chartOptions = {
      debug: false,
      fullscreen: false,
      symbol: this.marketsService.activeMarket.marketPair,
      interval: '1M',
      timeframe: '2D',
      container_id: 'chartMe',
      chartArea: { left: '3%', top: '20%', width: '95%', height: '80%' },
      withdateranges: false,
      showIndicatorsDialog: !1,
      pricescale: 100000000,

      disabled_features: [
        // 'header_widget',
        'header_symbol_search',
        'symbol_info',
        'header_compare',
        'header_chart_type',
        'display_market_status',
        'symbol_search_hot_key',
        'compare_symbol',
        'border_around_the_chart',
        'remove_library_container_border',
        'symbol_info',
        'header_interval_dialog_button',
        'show_interval_dialog_on_key_press',
        // 'volume_force_overlay'
      ],

      enabled_features: [
        'dont_show_boolean_study_arguments',
        'hide_last_na_study_output',
        'adaptive_logo',
        'hide_left_toolbar_by_default'],

      // BEWARE: no trailing slash is expected in feed URL
      datafeed: Datafeeds ? new Datafeeds.UDFCompatibleDatafeed(
        // 'https://demo_feed.tradingview.com'
        // change BASE_URL to 'https://app.chainex.io' to test on dev
        BASE_URL + '/chart',
        60000 // Update Frequency
      ) : {},
      studies_overrides: {
        // 'Moving Average.precision': 8,
        // 'volume.volume.color.0': upColor,
        'volume.volume.color.1': upColor
      },
      overrides: {
        'symbolWatermarkProperties.transparency': 1,
        'mainSeriesProperties.candleStyle.upColor': upColor,
        'mainSeriesProperties.candleStyle.borderUpColor': upColor,
        'mainSeriesProperties.candleStyle.wickUpColor': upColorAlpha,
        'mainSeriesProperties.candleStyle.barColorsOnPrevClose': false,
        'paneProperties.topMargin': 20,
      },
      time_frames: [],
      library_path: 'assets/charting_library/',
      locale: 'en',
      height: this.getChartHeight(),
      padding: 0,
      // disabled_features: ['use_localstorage_for_settings'],
      // client_id: 'tradingview.com',
      // user_id: 'public_user_id',
      theme: (this.darkTheme ? 'dark' : 'light'),
      loading_screen: {
        backgroundColor: loadingScreenBackground,
      },

      timezone: this.timeZone,
    };

    if (TradingView && !this.tvWidget) {
      this.tvWidget = window['tvWidget'] = new widget(chartOptions);
      this.chartID = document.getElementById('chartMe') as HTMLDivElement;
      this.tvWidget.onChartReady(() => {
        this.updateChartInfo();
        // this.tvWidget.chart().createStudy('Volume', 1, 0, 1, 1, { precision: 8, overlay: 1 }, 1);
        this.fullscreenButton = document.createElement('div');
        this.fullscreenButton.style.width = '20px';
        this.fullscreenButton.style.height = '20px';
        this.fullscreenButton.style.backgroundColor = 'transparent';
        this.fullscreenButton.style.display = 'inline-block';
        this.fullscreenButton.style.position = 'absolute';
        this.fullscreenButton.style.top = '0px';
        this.fullscreenButton.style.right = '50px';
        this.fullscreenButton.style.left = '50px';
        this.fullscreenButton.addEventListener('click', () => {
          this.chartID.classList.toggle('full-screen');
          this.hideBuySell.emit(this.chartID.classList.contains('full-screen'));
          this.resizeChart(this.chartID.classList.contains('full-screen'));
        });

        this.chartID.appendChild(this.fullscreenButton);
        this.tvWidget.changeTheme(this.darkTheme ? 'dark' : 'light');
      });
      if (this.chartID) {
        this.tabHeight = this.chartID.clientHeight;
        this.tabWidth = this.chartID.clientWidth;
      }
    }
  }

  getChartHeight() {
    const screenWidth = document.body.clientWidth;
    if (screenWidth >= 1765) {
      return '352';
    } else if (screenWidth >= 1600) {
      return '350';
    } else if (screenWidth >= 1200) {
      return '350';
    } else {
      return '350';
    }
  }

  drawChart(buy: any, sell: any) {
    this.buyData = buy.filter(a => Number(a.price) > 0);
    this.sellData = sell.filter(a => Number(a.price) > 0);

    this.orderChartShowing = true;
    const self: any = this;
    try {
      self.loadGoogleChart(self);
    } catch (ex) { }
  }

  graphLoad(self: any, orderChartData: any, longest: number) {
    self.depthChart = document.getElementById('chartdiv_orderdepth');
    const tab = self.depthChart.parentNode;
    if (self.orderChartShowing && orderChartData && self.depthChart) {
      self.depthChart.style.display = 'none';
      self.depthChart.setAttribute('style', 'height: ' + self.tabHeight + 'px; width: ' +
      (tab.clientWidth - 20) + 'px; overflow: hidden;');

      self.depthChart.style.display = 'block';

      self.orderChart = new window['google'].visualization.AreaChart(self.depthChart);

      const percentage = longest > 10 ? '10%' : '5%';
      self.options.chartArea = { left: percentage, top: '10%', width: '95%', height: '70%' };

      self.orderChart.draw(orderChartData, self.options);
    }
  }

  loadGoogleChart(self: any) {
    if (!document.getElementById('chartdiv_orderdepth')) {
      return;
    }
    const orderChartData: any = new window['google'].visualization.DataTable();
    orderChartData.addColumn('number', 'Price');
    orderChartData.addColumn('number', 'Bid');
    // A column for custom tooltip content
    orderChartData.addColumn({ type: 'string', role: 'tooltip' });
    orderChartData.addColumn('number', 'Ask');
    // A column for custom tooltip content
    orderChartData.addColumn({ type: 'string', role: 'tooltip' });

    let longest: number = 0;
    let num: number;
    let total: number = 0;
    for (let i = 0; i < self.buyData.length; i++) {
      try {
        num = Number(self.buyData[i]['price']);
        // Please review this line:
        total = +self.buyData[i]['total'].toFixed(['ZAR', 'USDT'].indexOf
        (this.marketsService.activeMarket.coinCode) === -1 ? 8 : 2);
        longest = total.toString().length > longest ? total.toString().length : longest;
      } catch (ex) {
        continue;
      }
      orderChartData.addRow([
        num,
        total,
        total + ' ' + this.marketsService.activeMarket.exchangeCode + ' to get to ' + num,
        null,
        null
      ]);
    }

    for (let i = 0; i < self.sellData.length; i++) {
      try {
        num = Number(self.sellData[i]['price']);
        // Please review this line:
        total = +self.sellData[i]['total'].toFixed(['ZAR', 'USDT'].indexOf
        (this.marketsService.activeMarket.coinCode) === -1 ? 8 : 2);
        longest = total.toString().length > longest ? total.toString().length : longest;
      } catch (ex) {
        continue;
      }
      orderChartData.addRow([
        num,
        null,
        null,
        total,
        total + ' ' + this.marketsService.activeMarket.exchangeCode + ' to get to ' + num
      ]);
    }

    self.graphLoad(self, orderChartData, longest);
  }

  loadTradingView() {
    if (!document.getElementById('chartMe')) {
      this.chartHandler = setInterval(() => {
        this.loadTradingView();
      }, 100);
      return;
    }

    if (!!this.chartHandler) {
      clearInterval(this.chartHandler);
    }

    if (!!this.tvWidget && this.marketsService.activeMarket.marketPair) {
      if (!this.tvWidget._ready) {
        if (!!this.chartHandler) {
          clearInterval(this.chartHandler);
        }
        this.chartHandler = setInterval(() => {
          if (this.tvWidget._ready) {
            this.updateChartInfo();
            clearInterval(this.chartHandler);
          }
        }, 1000);
      } else {
        if (!!this.chartHandler) {
          clearInterval(this.chartHandler);
        }
        try {
          this.updateChartInfo();
        } catch (ex) {
          this.chartHandler = setInterval(() => {
            this.loadTradingView();
          }, 1000);
        }
      }
    }
  }

  updateChartInfo() {
    const isUSDTorZAR = (this.marketsService.activeMarket.exchangeCode === 'ZAR' ||
        this.marketsService.activeMarket.exchangeCode === 'USDT');
    this.tvWidget.setSymbol(this.marketsService.activeMarket.marketPair, '60');
    this.tvWidget.chart().removeAllStudies();
    this.tvWidget.chart().createStudy('Volume', 1, !1, null, null, { precision: isUSDTorZAR ? 2 : 8 }, 1);

    (((<HTMLIFrameElement> document.getElementById(this.tvWidget._id)).contentWindow.document)
    .querySelector('.pane-legend-line.apply-overflow-tooltip.main'))
    .innerHTML = 'Loading...';
  }

  resizeChart(fullscreen: boolean) {
    const tv = document.getElementsByTagName('iframe');

    if (!!tv) {
      if (fullscreen) {
        tv.item(0).height = '100%';
        let el: HTMLElement | Element = document.getElementById('main-header');
        if (!!el) {
          el.classList.add('d-none');
        }
        el = document.getElementById('main-footer');
        if (!!el) {
          el.classList.add('d-none');
        }
        el = document.getElementById('market-main-table');
        if (!!el) {
          el.classList.add('d-none');
        }
        el = document.getElementsByClassName('market-buy-box')[0];
        if (!!el) {
          el.classList.add('d-none');
        }
        el = document.getElementsByClassName('market-sell-box')[0];
        if (!!el) {
          el.classList.add('d-none');
        }
        el = document.getElementsByClassName('mobile-transaction-box')[0];
        if (!!el) {
          el.classList.add('d-none');
        }
      } else {

        tv.item(0).height = this.getChartHeight() + 'px';

        let el: HTMLElement | Element = document.getElementById('main-header');
        if (!!el) {
          el.classList.remove('d-none');
        }
        el = document.getElementById('main-footer');
        if (!!el) {
          el.classList.remove('d-none');
        }
        el = document.getElementById('market-main-table');
        if (!!el) {
          el.classList.remove('d-none');
        }
        el = document.getElementsByClassName('market-buy-box')[0];
        if (!!el) {
          el.classList.remove('d-none');
        }
        el = document.getElementsByClassName('market-sell-box')[0];
        if (!!el) {
          el.classList.remove('d-none');
        }
        el = document.getElementsByClassName('mobile-transaction-box')[0];
        if (!!el) {
          el.classList.remove('d-none');
        }
      }
    }
  }

  setChartInit(self: any) {
    const chartID: any = document.getElementById('chartMe');
    if (!chartID || this.marketsService.activeMarket.id === '-1') {
      setTimeout(function () { self.setChartInit(self); }, 1000);
    } else {
      setTimeout(function () { self.chartInit(); }, 500);
    }
  }

  tab_change(e: any) {
    this.tab = e.index;
    this.updateGraph();
  }

  updateGraph() {
    try {
      if (this.tab === 0) {
        if (typeof this.tvWidget !== 'undefined') {
          try {
            this.tvWidget.changeTheme(this.darkTheme ? 'dark' : 'light');
            this.loadTradingView();
          } catch {
            setTimeout(() => { this.updateGraph(); }, 100);
          }
        }
      } else if (this.tab === 1) {
        let upColor = '';
        if ( this.exchangeName === 'burnx' ) {
          upColor = '#42B17F';
        } else {
          upColor = '#69cc66';
        }
        this.options.colors =  [upColor, '#c0392b'];
        this.options.backgroundColor = this.darkTheme ? window['darkColor'] : window['lightColor'];
        this.options.hAxis.titleTextStyle.color = this.darkTheme ? '#fff' : '#333';
        this.options.vAxis.titleTextStyle.color = this.darkTheme ? '#fff' : '#333';
        this.loadGoogleChart(this);
      }
    } catch { }
  }
}

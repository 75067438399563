import {
  Component, OnInit, Input, ViewChild, OnDestroy,
  Output, EventEmitter, ChangeDetectionStrategy, ChangeDetectorRef
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SubscriptionLike } from 'rxjs';

import { MarketConfirmationComponent, DialogData } from '../market-confirmation/market-confirmation.component';
import { MarketsService } from '../markets.service';
import { StoreService } from '../../core/services/store.service';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { I18nTranslationService } from 'src/app/core/services/i18n-translation.service';
import { environment } from 'src/environments/environment';
import { DecimalNumberPipe } from 'src/app/core/pipe/decimalNumber.pipe';
import { SessionStorageService } from 'src/app/core/services/session-storage.service';

@Component({
  selector: 'markets-market-history',
  templateUrl: './market-history.component.html',
  styleUrls: ['./market-history.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MarketHistoryComponent implements OnInit, OnDestroy {
  enablePaginator: boolean = true;
  historyData: MatTableDataSource<any> = new MatTableDataSource([]);
  historyDefinition: string[] = [
    'action',
    'amount',
    'price',
    'total',
    'fee',
    'net_total',
    'conditional_trading',
    'status',
    'time',
    'actions'
  ];

  exchangeName: string = environment.config.EXCHANGE_NAME_L;
  actionToolTips: string[] = ['4' , '5'];

  // tslint:disable-next-line:no-input-rename
  @Input('mode') historyMode: number = 0;
    /*
    modes
    Active_Orders = 0
    Order_History = 1
    Trade_History = 2
    Active_Accumulation_Distribution_Orders = 3
  */

  @Input() cancelButton: number = 0;
  @Input() historyStyle: string = 'chainex';
  @Input() hidePaginator: boolean = false;

  loadTimeout: any;
  listNumber: number = environment.config.EXCHANGE_NAME_L === 'burnx' ? 6 : 50;
  pageSizes: number[] = [5, 10, 20, 50];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  showNotification: boolean = true;
  @ViewChild(MatSort) sort: MatSort;

  storeSubscriptions: any = [];
  totalRecords: number;
  @Output() valueChange: EventEmitter<any> = new EventEmitter();

  pageHandler: any = null;

  data: any = {
    perPage: this.listNumber,
    pageNo: '0',
  };

  marketSetting: any = {
    activeOrdersSettings: {
       perPage: this.listNumber
    },
    orderHistorySettings: {
       perPage: this.listNumber
    },
    tradeHistorySettings: {
       perPage: this.listNumber
    }
 };

  private subs: SubscriptionLike[] = [];

  constructor(public marketsService: MarketsService,
    private dialog: MatDialog,
    private store: StoreService,
    private i18n: I18n,
    public snackbar: MatSnackBar,
    private translateService: I18nTranslationService,
    private cdr: ChangeDetectorRef,
    private sessionStorage: SessionStorageService,
    private decimalPipe: DecimalNumberPipe) {
    }

  ngOnInit() {
    this.update_market_settings(this.listNumber, false, this.historyMode);
    this.subs.push(this.marketsService.activeMarketSubject.subscribe(() => {
      this.loadTableData(this.historyMode);
      this.cdr.markForCheck();
    }
    ));

    this.enablePaginator = false;
    this.loadDefinitions(this.historyMode);

    this.subs.push(this.store.subscribe('settings').subscribe((response) => {
      if (!response.refresh) {
        this.showNotification = !response.data.confirmations ? true : response.data.confirmations === '1';
      }
    }));
    // this.loadTableData(this.history_mode);
    this.historyData.sort = this.sort;
  }

  ngOnDestroy() {
    this.subs.forEach(sub => sub.unsubscribe());
  }

  cancelOrder(element: any) {
    if (this.showNotification) {
      const dialogData: DialogData = {
        title: 'Cancel Order',
        result: 'string',
        reason: this.i18n(`Are you sure you would like to cancel the following Order?<br>
          <br><b>Pair</b>: {{pair}}
          <br><b>Type</b>: {{type}}
          <br><b>Amount</b>: {{amount}}
          <br><br>`, {
            pair: element.pair,
            type: (element.type === '0' ? 'BUY' : 'SELL'),
            amount: this.marketsService.fixMarketValueDecimals(+element.amount, +element.coin_decimals)
          }),
        okButton: true,
        isTranslated: true
      };
      const dialogRef = this.dialog.open(MarketConfirmationComponent, {
        width: '400px',
        data: dialogData
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result.result === 'accept') {
          this.cancelOrderNow(element);
          this.cdr.markForCheck();
        }
      });
    } else {
      this.cancelOrderNow(element);
    }
  }

  loadTableData(mode: number) {
    if (!!this.loadTimeout) {
      clearTimeout(this.loadTimeout);
    }
    this.loadTimeout = setTimeout(() => {
      let sortBy = 'time';
      if (this.sort == null || this.sort === undefined) {
        this.sort = new MatSort();
        this.sort.direction = 'desc';
        this.sort.active = 'time';
      }

      let direction = this.sort.direction.toUpperCase();
      if (!this.sort.active || this.sort.direction === '') {
        sortBy = 'time';
        direction = 'DESC';
      } else {
        sortBy = this.sort.active;
      }

      const data: any = {
        'perPage': this.listNumber,
        'pageNo': 0,
        'orderBy': sortBy,
        'order': direction,
        'market': this.marketsService.activeMarket.id,
      };
      const self = this;
      const processData = function(result: any) {
        if (result.response === 'success') {
          self.historyData.data = result.data;
          this.cdr.markForCheck();
        }
      };
      switch (mode) {
        case 0: // Active_Orders
        default:
          if (this.marketsService.activeMarket.id !== '-1' || this.historyStyle === 'burnx') {
            this.subs.push(this.store.getMyActiveOrders(
              this.historyStyle === 'burnx' ? '' : this.marketsService.activeMarket.id
              ).subscribe((result: any) => {
                self.historyData.data = result;
                this.totalRecords = result.length;
                this.cdr.markForCheck();
            }));
            this.historyData.paginator = this.paginator;
          }
        break;
        case 1: // Order_History
          this.marketsService.getOrderHistory(data).subscribe(processData.bind(this));
          this.historyData.paginator = this.paginator;
        break;
        case 2: // Trade_History
          this.marketsService.getMyTradeHistory(data).subscribe(processData.bind(this));
          this.historyData.paginator = this.paginator;
        break;
      }
    }, 1000);
    this.historyData.sort = this.sort;
  }

  buildToolTip(element: any) {
    if (this.mustDisplayTooltip(element)) {
      let toolTipString = '';
      switch (element.action) {
        case '4':
        case '5': {
          const stopLogicOperator = +this.marketsService.activeMarket.lastPrice < +element.stop_price ? '>=' : '<=';
          toolTipString = 'Order Type: ' + this.actionName(element.action) + '\n'
            + 'Stop Price: ' + stopLogicOperator + ' ' +
            this.decimalPipe.transform(element.stop_price, element.exchange_decimals, '') + '\n';
        }
          break;
      }
      return toolTipString;
    } else {
      return '';
    }
  }

  mustDisplayTooltip(element: any) {
    return this.actionToolTips.indexOf(element.action) !== -1;
  }

  loadDefinitions(mode: number) {
    switch (mode) {
      case 0: // Active_Orders
      default:
        if (this.historyStyle === 'burnx') {
          this.historyDefinition = [
            'coin',
            'buy',
            'conditional_trading',
            'price',
            'quantity',
            'cancel'
          ];
        } else {
          this.historyDefinition = [
            'type',
            'amount',
            'price',
            'total',
            'fee',
            'net_total',
            'conditional_trading',
            'status',
            'time',
            'actions'
          ];
        }

        this.subs.push(this.store.subscribe('openOrders').subscribe((response) => {
          if (!response.feed || (!!response.isUserSpecific) || (response.feed &&
          response.feed === `marketUpdates-${this.marketsService.activeMarket.id}`)) {
            this.loadTableData(this.historyMode);
          }
          if (!!response.id) {
            this.storeSubscriptions.push({
              subject: 'openOrders',
              id: response.id
            });
          }
        }));
        this.enablePaginator = true;
      return;
      case 1: // Order_History
        this.historyDefinition = [
          'type',
          'action',
          'amount',
          'price',
          'total',
          'fee',
          'net_total',
          'time'
        ];
        this.subs.push(this.store.subscribe('orderHistory').subscribe((response) => {
          if (!response.feed || (!!response.isUserSpecific) || (response.feed &&
          response.feed === `marketUpdates-${this.marketsService.activeMarket.id}`)) {
            this.loadTableData(this.historyMode);
          }
          if (!!response.id) {
            this.storeSubscriptions.push({
              subject: 'orderHistory',
              id: response.id
            });
          }
        }));
        this.enablePaginator = true;
      return;
      case 2: // Trade_History
        this.historyDefinition = [
          'type',
          'amount',
          'price',
          'total',
          'fee',
          'net_total',
          'time',
        ];
        this.subs.push(this.store.subscribe('tradeHistory').subscribe((response) => {
          if (!response.feed || (!!response.isUserSpecific) || (response.feed &&
          response.feed === `marketUpdates-${this.marketsService.activeMarket.id}`)) {
            this.loadTableData(this.historyMode);
          }
          if (!!response.id) {
            this.storeSubscriptions.push({
              subject: 'tradeHistory',
              id: response.id
            });
          }
        }));
        this.enablePaginator = true;
      return;
    }
  }

  cancelOrderNow(element: any) {
    this.marketsService.cancelOrder({orderId: element.order_id}).subscribe((response) => {
      if (response.response === 'success') {
      } else {
        this.snackbar.open(this.translateService.translateResponse(response.reason),
            this.i18n('Close'), {duration: 2000});
      }
    });
  }

  cancelAllOrders () {
    if (this.showNotification) {
      const dialogData: DialogData = {
        title: 'Cancel Orders',
        result: 'string',
        reason: this.i18n(`Are you sure you would like to cancel all your open orders?`, {}),
        okButton: true,
        isTranslated: true
      };
      const dialogRef = this.dialog.open(MarketConfirmationComponent, {
        width: '400px',
        data: dialogData
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result.result === 'accept') {
          for (let i = 0; i < this.historyData.data.length; i++) {
            this.cancelOrderNow(this.historyData.data[i]);
          }
        }
      });
    } else {
      for (let i = 0; i < this.historyData.data.length; i++) {
        this.cancelOrderNow(this.historyData.data[i]);
      }
    }
  }

  paging_change($event: any) {
    this.data.pageNo = $event.pageIndex;
    this.data.perPage = $event.pageSize;
    this.update_market_settings($event.pageSize, true, this.historyMode);
    if (!!this.pageHandler) {
      clearTimeout(this.pageHandler);
    }

    this.pageHandler = setTimeout(() => {
      this.loadTableData(this.historyMode);
    }, 1000);
  }

  update_market_settings(length: any, update: boolean = false, mode: number) {
    let marketing_settings = this.sessionStorage.get('MARKET_SETTINGS');
    marketing_settings = marketing_settings || {};
    switch (mode) {
      case 0: // Active_Orders
        if (update) {
          marketing_settings.activeOrderSettings = {'perPage': length};
          this.sessionStorage.store('MARKET_SETTINGS', marketing_settings);
        } else {
          if (marketing_settings && marketing_settings.activeOrderSettings) {
            this.listNumber = marketing_settings.activeOrderSettings.perPage || this.listNumber;
            this.data.perPage = this.listNumber;
          }
        }
        break;
      case 1: // Order_History
        if (update) {
          marketing_settings.orderHistorySettings = {'perPage': length};
          this.sessionStorage.store('MARKET_SETTINGS', marketing_settings);
        } else {
          if (marketing_settings && marketing_settings.orderHistorySettings) {
            this.listNumber = marketing_settings.orderHistorySettings.perPage || this.listNumber;
            this.data.perPage = this.listNumber;
          }
        }
        break;
      case 2: // Trade_History
        if (update) {
          marketing_settings.tradeHistorySettings = {'perPage': length};
          this.sessionStorage.store('MARKET_SETTINGS', marketing_settings);
        } else {
          if (marketing_settings && marketing_settings.tradeHistorySettings) {
            this.listNumber = marketing_settings.tradeHistorySettings.perPage || this.listNumber;
            this.data.perPage = this.listNumber;
          }
        }
        break;
    }
    this.marketSetting = marketing_settings || this.marketSetting;
  }

  hasNextPage() {
    if (!this.paginator) {
      return false;
    }
    return (this.paginator.pageIndex + 1) * this.listNumber < this.paginator.length;
  }

  hasPrevPage() {
    if (!this.paginator) {
      return false;
    }
    return this.paginator.pageIndex > 0;
  }

  getPageNumber() {
    if (!this.paginator || this.paginator.length === 0) {
      return '1 of 1';
    }
    return (this.paginator.pageIndex + 1).toString() + ' of ' + Math.ceil(this.paginator.length / this.listNumber);
  }

  nextPage() {
    if (!this.paginator) {
      return false;
    }
    this.paginator.nextPage();
  }

  prevPage() {
    if (!this.paginator) {
      return false;
    }
    this.paginator.previousPage();
  }

  getCoinCode(id: string) {
    const pair = this.marketsService.getMarketPair(id);
    return pair ? pair.coinCode : '';
  }

  getCoinPair(id: string) {
    const pair = this.marketsService.getMarketPair(id);
    const marketPairString: string = pair.coinCode + '/' + pair.exchangeCode;
    return marketPairString;
  }

  LoadValue(row: any) {
    this.valueChange.emit({price: row.price, amount: row.amount, updatePrice: true});
  }

  SortEvent(mode: number) {
    if (mode === 0) { // Active_Orders
      return;
    }
    this.loadTableData(mode);
  }

  actionName(action: string) {
    switch (action) {
      case '0': return 'LIMIT';
      case '1': return 'DELETE';
      case '2': return 'MARKET';
      case '4': return 'STOP';
      case '5': return 'STOP LIMIT';
      default: return 'ORDER';
    }
  }

  mustDisplay(action: string, value: number) {
    return this.actionName(action) === 'MARKET' ? 'MARKET' : +value;
  }

}

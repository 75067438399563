import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';

import { AuthGuard, NoAuthGuard } from './../core/services/auth-guard.service';
import { routing } from './markets.routing';
import { MarketsComponent } from './markets/markets.component';
import { MarketHistoryComponent } from './market-history/market-history.component';
import { OrderBookComponent } from './order-book/order-book.component';
import { ChartComponent } from './chart/chart.component';
import { SharedModule } from '../shared/shared.module';
import { OrderFormComponent } from './order-form/order-form.component';
import { DecimalNumberPipe } from '../core/pipe/decimalNumber.pipe';
import { OrderTypesComponent } from './order-types/order-types-component';
import { MarketOrderComponent } from './order-types/market-order/market-order-chainex';
import { BurnxHeaderComponent } from './burnx-header/burnx-header.component';
import { TopStatsComponent } from './chart/top-stats/top-stats.component';
import { MatCardModule } from '@angular/material/card';
import { DaformComponent } from './order-types/da-order/da-form/daform.component';
import { ScaledOrderComponent } from './order-types/scaled-order/scaled-order-chainex.component';
import { LoadingDaComponent } from './order-types/da-order/loading-da/loading-da.component';
import { StopLimitOrderComponent } from './order-types/stop-limit-order/stop-limit-order.component';
import { MarketparamComponent } from './markets/marketparam/marketparam.component';
import { CoinAnalyticsComponent } from './coin-analytics/coin-analytics.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    routing,
    MatTabsModule,
    MatGridListModule,
    MatInputModule,
    MatRadioModule,
    MatTableModule,
    MatSortModule,
    MatIconModule,
    MatDialogModule,
    SharedModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatCardModule,
    MatRadioModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSelectModule,
  ],
  declarations: [
    MarketsComponent,
    MarketparamComponent,
    MarketHistoryComponent,
    OrderBookComponent,
    ChartComponent,
    OrderFormComponent,
    OrderTypesComponent,
    MarketOrderComponent,
    BurnxHeaderComponent,
    OrderFormComponent,
    DaformComponent,
    ScaledOrderComponent,
    LoadingDaComponent,
    StopLimitOrderComponent,
  ],
  entryComponents: [
    ScaledOrderComponent
  ],
  providers: [
    AuthGuard,
    NoAuthGuard,
    DatePipe,
    I18n,
    DecimalNumberPipe,
    MatDatepickerModule
  ],
  exports: [
    ReactiveFormsModule
  ]
})
export class MarketsModule { }

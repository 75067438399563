import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MetaGuard } from '@ngx-meta/core';

import { MarketsComponent } from './markets/markets.component';
import { MarketparamComponent } from './markets/marketparam/marketparam.component';

import { environment } from '../../environments/environment';
import { MarketsModule } from './markets.module';

const routes: Routes = [
  {
    path: 'markets',
    component: MarketsComponent,
    data: {
      title: 'Markets',
      meta: {
        title: environment.config.EXCHANGE_NAME + ' - ' + 'Markets',
        description: environment.config.EXCHANGE_NAME + ' markets provide real-time market trade statistics.' +
        ' Buy and sell digital assets, view trade history and more.',
        'og:image': 'https://chainex.io/assets/chainex/images/meta/Markets.png',
        'twitter:image': 'https://chainex.io/assets/chainex/images/meta/Markets.png',
        'twitter:card': 'summary_large_image'
      }
    },
    children: [
      { path: ':coin1/:coin2', component: MarketparamComponent },
    ],
    canActivate: [MetaGuard]
  }
];

export const routing: ModuleWithProviders<MarketsModule> = RouterModule.forChild(routes);

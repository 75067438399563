import { Component, OnInit, Input, OnDestroy, ChangeDetectorRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as Big from 'big-js';
import { LoadingDaComponent } from '../loading-da/loading-da.component';
import { MarketsService } from '../../../markets.service';
import { HistoryService} from '../../../../history/history.service';
import { StoreService } from '../../../../core/services/store.service';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { I18nTranslationService } from 'src/app/core/services/i18n-translation.service';
import { SubscriptionLike, BehaviorSubject } from 'rxjs';
import { DaOrdersTableComponent} from '../../../../history/da-orders-table/da-orders-table.component';
import * as moment from 'moment';
import { MarketConfirmationComponent , DialogData } from '../../../market-confirmation/market-confirmation.component';
import { environment } from 'src/environments/environment';
import { UtilHelper } from 'src/app/core/helpers/util-helper';

@Component({
  selector: 'app-dacomponent',
  templateUrl: './daform.component.html',
  styleUrls: ['./daform.component.scss'],
  providers: [ DaOrdersTableComponent]
})
export class DaformComponent implements OnInit, OnDestroy {

  @ViewChild('loadscreen') loadscreen: LoadingDaComponent;
  public valueChange: Function = UtilHelper.bigValueToFixed;

  exchangeName: string = environment.config.EXCHANGE_NAME_L;

  amountAvailable: number | Big = new Big(0).toFixed(8);

  decimalSpaces: number = 8;
  amountDecimalSpaces: number = 8;
  feeDecimalSpaces: number = 8;
  minAmount: number = 0.00000001;

  data: any = {
    market_id : Number(this.marketService.activeMarket.id),
    allorders : false
  };

  priceChange: number | Big = new Big(0).toFixed(8);
  aboveBelow: number = 0;
  useOrderBook: number;
  buyFromSell: number;
  orderAmount: number | Big = new Big(0).toFixed(8);
  maxBuyPrice: number | Big = new Big(0).toFixed(8);
  seconds: number | Big = new Big(0).toFixed(2);
  checked1: boolean = true;
  checked2: boolean;
  accDistType: any;
  formInputTop: any;
  orderType: number;
  orderType2: any = new BehaviorSubject<Number>(-1) ;
  displayAccOverlay: true;
  displayDistOverlay: true;
  ordertypestring: String;

  fee: number | Big = new Big(0).toFixed(8);
  total: number | Big = new Big(0).toFixed(8);
  amount: any;
  price: any;

  amountAccumulated: any;
  lastPrice: any;
  maxTotal: number | Big = new Big(0).toFixed(8);

  totalMinimum: number = 0.0001;
  priceForFeeCalc: number | Big = new Big(0).toFixed(8);
  feePercentage: number | Big = new Big(0).toFixed(8);

  feeType: string = 'makerFee';

  priceMarket: number | Big = new Big(0).toFixed(8);
  orderBook: any = [];
  placementDate: any = '';

  orderForm: FormGroup;

  showConfirmation: boolean = true;

  private subs: SubscriptionLike[] = [];
  private balanceSubs: SubscriptionLike;

  activeMarketSub: SubscriptionLike;

  executionType: string = 'market';

  @Input() balance: any = {
    balance: { balance_available: 0.0000045, code: '' }
  };

  @Input() type: string = ''; // Buy/Accumulation = 0, Sell/Distribution = 1

  exchangeCode: string = '';
  coinCode: string = '';
  displayPrice: boolean = true;

  maxTotalMin: number = 0.001;


  firstLoad: boolean = true;

  validationMessage: number;
  oppType: string;

  constructor(
    public dialog: MatDialog,
    public cancel: DaOrdersTableComponent,
    private historyService: HistoryService,
    public marketService: MarketsService,
    private formBuilder: FormBuilder,
    private store: StoreService,
    private i18n: I18n,
    private translateService: I18nTranslationService,
    public snackbar: MatSnackBar,
    private CDRef: ChangeDetectorRef) {

    this.orderForm = this.formBuilder.group({
      priceChange: ['', Validators.min(0.0001)],
      aboveBelow: ['', Validators.required],
      useOrderBook: [{disabled : false}],
      buyFromSell: [{disabled : false}],
      orderAmount: ['', Validators.required],
      maxBuyPrice: ['', Validators.required],
      total: ['', Validators.min(0.0001)],
      seconds: ['', Validators.required],
      fee: [''],
      maxTotal: ['', Validators.min(this.maxTotalMin)]
    });

    this.marketService.getDaStatus().subscribe(value => {
      this.orderType2.next(value);
    });
  }

  ngOnInit() {
    this.activeMarketSub = this.marketService.activeMarketSubject.subscribe((response) => {
      this.setActiveMarket();
    });
    if (this.type === 'buy') {
      this.formInputTop = this.i18n('Highest Bid');
    } else {
      this.formInputTop = this.i18n('Lowest Ask');
    }

    this.setActiveMarket();
    this.subs.push(this.store.subscribe('settings').subscribe((response) => {
      if (!response.refresh) {
        this.showConfirmation = !response.data.confirmations ? true : response.data.confirmations === '1';
      }
    }));

    this.subs.push(this.store.subscribe('daOrderUpdate').subscribe((response) => {
      if (!!response.refresh) {
        this.amountAccumulated = this.valueChange(response.amount_traded, this.amountDecimalSpaces);
        this.lastPrice = this.valueChange(response.last_price, this.decimalSpaces);
      }
    }));

    this.subs.push(this.store.subscribe('daOrders').subscribe((response: any) => {
      if (!!response.refresh) {
        if (response.title === 'DA Order Cancelled') {
          this.marketService.setDaStatus(-1);
          this.clear();
        } else if (response.title === 'DA Order Added') {
          this.setActiveMarket();
        }
      }
    }));

    this.setupFormValidation();
    this.getordertype();
    this.setupForm();
  }

  ngOnDestroy() {
    this.subs.forEach(sub => sub.unsubscribe());
    if (this.balanceSubs) {
      this.balanceSubs.unsubscribe();
    }
    this.activeMarketSub.unsubscribe();
  }

  totalChangeUpdate() {
    this.total = this.valueChange(this.total, this.amountDecimalSpaces);
  }

  calculateMaxTotalMinimum() {
    this.maxTotal = new Big(+this.maxBuyPrice).times(this.orderAmount);
    this.maxTotal = this.valueChange(this.maxTotal, this.decimalSpaces);
    this.orderForm.controls.maxTotal.markAsTouched();

  }

  updateValidation() {
    this.calculateMaxTotalMinimum();
    this.orderAmount = this.valueChange(this.orderAmount, this.amountDecimalSpaces);
    this.maxBuyPrice = this.valueChange(this.maxBuyPrice, this.decimalSpaces);
    const minValidAmount
      = ['ZAR', 'USDT'].indexOf(this.marketService.activeMarket.exchangeCode) === -1 ? 0.0001 : 1;
    this.maxTotalMin = ['ZAR', 'USDT'].indexOf(this.marketService.activeMarket.exchangeCode) === -1 ? 0.001 : 1;
    this.orderForm.controls.total.setValidators(null);
    this.orderForm.controls.total.updateValueAndValidity();
    this.orderForm.controls.total.setValidators(
      [Validators.required,
      Validators.min(this.orderAmount)]
    );
    this.minAmount = this.orderAmount;
    this.orderForm.controls.total.updateValueAndValidity();

    this.orderForm.controls.maxTotal.setValidators(null);
    this.orderForm.controls.maxTotal.updateValueAndValidity();
    this.orderForm.controls.maxTotal.setValidators([Validators.min(this.maxTotalMin)]);

    if (Number(this.maxBuyPrice) !== 0) {
      this.orderForm.controls.orderAmount.setValidators(null);
      this.orderForm.controls.orderAmount.updateValueAndValidity();
      this.orderForm.controls.orderAmount.setValidators([Validators.required]);
      this.validationMessage = minValidAmount / Number(this.maxBuyPrice);
      this.orderForm.controls.orderAmount.updateValueAndValidity();

      return;
    } else if (Number(this.orderAmount) !== 0) {
      this.orderForm.controls.maxBuyPrice.setValidators(null);
      this.orderForm.controls.maxBuyPrice.updateValueAndValidity();
      this.orderForm.controls.maxBuyPrice.setValidators([Validators.required]);
      this.orderForm.controls.maxBuyPrice.updateValueAndValidity();
      this.validationMessage = minValidAmount / Number(this.orderAmount);

      return;
    }

    this.orderForm.controls.maxBuyPrice.setValidators(null);
    this.orderForm.controls.maxBuyPrice.updateValueAndValidity();
    this.orderForm.controls.maxBuyPrice.setValidators([Validators.required]);
    this.validationMessage = this.totalMinimum;
    this.orderForm.controls.maxBuyPrice.updateValueAndValidity();
  }

  resetFormValidation() {
    Object.keys(this.orderForm.controls).forEach(key => {
      this.orderForm.get(key).setValidators(null);
      this.orderForm.get(key).updateValueAndValidity();
    });
  }

  returnDirectionString(direction: any, match: any) {
    if (direction === '0' && match === '1') {
      return this.i18n('Below lowest ask');
    } else if (direction === '1' && match === '1') {
      return this.i18n('Above lowest ask');
    } else if (direction === '0' && match === '0') {
      return this.i18n('Below highest bid');
    } else if (direction === '1' && match === '0') {
      return this.i18n('Above highest bid');
    }
  }

  cancelOrder() {
    if ( this.type === 'buy') {
      const dialogData: DialogData = {
        title: this.i18n('Cancel Order'),
        result: 'string',
        reason: this.i18n(`Are you sure you would like to cancel the following Order?<br>
          <br><b>Price Change : </b> {{priceChange}} {{exchangeCode}}
          <br><b>Above/Below : </b>{{priceChangeDirection}}
          <br><b>Use sell orderbook prices  </b>: {{useOrderbookPrice}}
          <br><b>Buy from sell orders : </b> {{matchOppositeOrders}}
          <br><b>Order placement amount : </b> {{orderAmount}} {{coinCode}}
          <br><b>Maximum buy price : </b> {{limitTotalCoinAmount}} {{exchangeCode}}
          <br><b>Total amount to buy : </b> {{total}} {{coinCode}}
          <br><b>Seconds between order placement : </b> {{seconds}}
          <br><br> Please note that cancelling this order will cancel
                   the entire process to which it relates and the
                   process will therefore not be fulfilled based
                   on the settings specified
          <br><br>`, {
          aboveBelow: this.aboveBelow, priceChange: this.priceChange,
          priceChangeDirection: (Number(this.aboveBelow) === 0 ? 'Below' : 'Above'),
          useOrderbookPrice: (Number(this.useOrderBook) === 0 ? 'No' : 'Yes'),
          matchOppositeOrders: (Number(this.buyFromSell) === 0 ? 'No' : 'Yes'),
          orderAmount: this.orderAmount,
          limitTotalCoinAmount: this.maxBuyPrice,
          total: this.total,
          seconds: this.seconds,
          coinCode: this.marketService.activeMarket.coinCode,
          exchangeCode: this.marketService.activeMarket.exchangeCode
        }),
        okButton: true,
        isTranslated: true
      };
      const dialogRef = this.dialog.open(MarketConfirmationComponent, {
        width: '400px',
        data: dialogData
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result.result === 'accept') {
          const cancelData: any = {
            market_id: Number(Number(this.marketService.activeMarket.id)),
            cancellation_reason: Number(0)
          };

          this.historyService.cancelDAOrder(cancelData).subscribe((response) => {
            if (response.response === 'success') {
              this.marketService.setDaStatus(-1);
              this.resetFormValidation();
            }
            this.snackbar.open(this.translateService.translateResponse(response.reason),
              this.i18n('Close'), {duration: 2000});
          });

          this.clear();

          if (this.type === 'buy') {
            this.orderForm.controls.aboveBelow.setValue('1');
          } else {
            this.orderForm.controls.aboveBelow.setValue('0');
          }
        }
      });
    } else {
      const dialogData: DialogData = {
        title: this.i18n('Cancel Order'),
        result: 'string',
        reason: this.i18n(`Are you sure you would like to cancel the following Order?<br>
          <br><b>Price Change : </b> {{priceChange}} {{exchangeCode}}
          <br><b>Above/Below : </b>{{priceChangeDirection}}
          <br><b>Use buy orderbook prices  </b>: {{useOrderbookPrice}}
          <br><b>Sell to buy orders : </b> {{matchOppositeOrders}}
          <br><b>Order placement amount : </b> {{orderAmount}} {{coinCode}}
          <br><b>Minimum sell price : </b> {{limitTotalCoinAmount}} {{exchangeCode}}
          <br><b>Total amount to sell : </b> {{total}} {{coinCode}}
          <br><b>Seconds between order placement : </b> {{seconds}}
          <br><br> Please note that cancelling this order will cancel
                   the entire process to which it relates and the
                   process will therefore not be fulfilled based
                   on the settings specified
          <br><br>`, {
          aboveBelow: this.aboveBelow,
          priceChange: this.priceChange,
          priceChangeDirection: (Number(this.aboveBelow) === 0 ? 'Below' : 'Above'),
          useOrderbookPrice: (Number(this.useOrderBook) === 0 ? 'No' : 'Yes'),
          matchOppositeOrders: (Number(this.buyFromSell) === 0 ? 'No' : 'Yes'),
          orderAmount: this.orderAmount,
          limitTotalCoinAmount: this.maxBuyPrice,
          total: this.total,
          seconds: this.seconds,
          coinCode: this.marketService.activeMarket.coinCode,
          exchangeCode: this.marketService.activeMarket.exchangeCode
        }),
        okButton: true,
        isTranslated: true
      };
      const dialogRef = this.dialog.open(MarketConfirmationComponent, {
        width: '400px',
        data: dialogData
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result.result === 'accept') {
          const cancelData: any = {
            market_id: Number(Number(this.marketService.activeMarket.id)),
            cancellation_reason: Number(0)
          };

          this.historyService.cancelDAOrder(cancelData).subscribe((response) => {
            if (response.response === 'success') {
              this.marketService.setDaStatus(-1);
              this.resetFormValidation();
            }
            this.snackbar.open(this.translateService.translateResponse(response.reason),
              this.i18n('Close'), {duration: 2000});
          });

          this.clear();

          if (this.type === 'buy') {
            this.orderForm.controls.aboveBelow.setValue('1');
          } else {
            this.orderForm.controls.aboveBelow.setValue('0');
          }
        }
      });
    }
  }

  getordertype() {
    if (this.type === 'buy' || this.type === '0') {
      this.accDistType = 'Accumulation';
      this.oppType = 'Distribution';
    } else {
      this.accDistType = 'Distribution';
      this.oppType = 'Accumulation';
    }
  }

  buildSnackbarString() {
    const snackbarString: string = this.accDistType + ' Order added successfully';
    return this.i18n(snackbarString);
  }

  setupFormValidation() {
    const netValidator = (price = false) => (control) => {
      const value = +control.value;
      let _max = 0;
      if (this.balance.balance_available) {
        _max = +this.balance.balance_available;
      }
      if (value === null || !value || +value === 0) {
        return null;
      }
      return !isNaN(value) && value > _max ? { 'max': { 'max': _max, 'actual': control.value } } : null;
    };

    this.resetFormValidation();
    this.orderForm.controls.priceChange.setValidators(null);
    this.orderForm.controls.priceChange.updateValueAndValidity();
    this.orderForm.controls.priceChange.setValidators(
      [netValidator(), Validators.required,
        Validators.min(this.totalMinimum)]);
    this.orderForm.controls.priceChange.updateValueAndValidity();
    this.orderForm.controls.orderAmount.setValidators(null);
    this.orderForm.controls.orderAmount.updateValueAndValidity();
    this.orderForm.controls.orderAmount.setValidators([Validators.required]);
    this.orderForm.controls.orderAmount.updateValueAndValidity();
    this.orderForm.controls.maxBuyPrice.updateValueAndValidity();
    this.orderForm.controls.maxBuyPrice.setValidators([Validators.required]);
    this.orderForm.controls.maxBuyPrice.updateValueAndValidity();
    this.orderForm.controls.total.updateValueAndValidity();
    this.orderForm.controls.total.setValidators(
      [netValidator(), Validators.required,
        Validators.min(this.totalMinimum)]);
    this.orderForm.controls.total.updateValueAndValidity();
    this.orderForm.controls.seconds.updateValueAndValidity();
    this.orderForm.controls.seconds.setValidators([Validators.required, Validators.min(10), Validators.max(3600)]);
    this.orderForm.controls.seconds.updateValueAndValidity();
    this.orderForm.controls.maxTotal.updateValueAndValidity();
    this.orderForm.controls.maxTotal.setValidators([Validators.min(this.maxTotalMin)]);
    this.orderForm.controls.maxTotal.updateValueAndValidity();
    this.orderForm.markAsPristine();
  }

  setupForm() {
    this.orderForm.controls.priceChange.enable();
    this.orderForm.controls.aboveBelow.enable();
    this.orderForm.controls.useOrderBook.enable();
    this.orderForm.controls.buyFromSell.enable();
    this.orderForm.controls.orderAmount.enable();
    this.orderForm.controls.maxBuyPrice.enable();
    this.orderForm.controls.total.enable();
    this.orderForm.controls.seconds.enable();
    this.orderForm.controls.maxTotal.enable();
    this.displayPrice = false;
    this.setupFormValidation();
    if (this.type === 'buy') {
      this.formInputTop = this.i18n('Highest Bid');
      this.orderForm.controls.aboveBelow.setValue('1');
      this.aboveBelow = 1;
    } else {
      this.formInputTop = this.i18n('Lowest Ask');
      this.orderForm.controls.aboveBelow.setValue('0');
      this.aboveBelow = 0;
    }
    this.amount = 0;
  }

  changelabel() {
    if ( this.type === 'buy') {
      if (this.checked1 === true) {
        this.formInputTop = this.i18n('Lowest Ask');
      } else {
        this.formInputTop = this.i18n('Highest Bid');
      }
    } else {
      if (this.checked1 === true) {
        this.formInputTop = this.i18n('Highest Bid');
      } else {
        this.formInputTop = this.i18n('Lowest Ask');
      }
    }

  }

  disableAllControls() {
    Object.keys(this.orderForm.controls).forEach(key => {
      this.orderForm.get(key).disable();
    });
  }

  setExecutionType(type: string) {
    this.executionType = type;
    this.setupForm();
  }

  amountChange() {
      this.priceChange = this.valueChange(this.priceChange, this.decimalSpaces);
      this.calculateTotal();
      this.calculateFee();
  }

  calculateFee() {
    try {
      let feeToUse = +this.marketService.activeMarket[this.feeType];
      if (this.marketService[this.feeType] < feeToUse) {
        feeToUse = +this.marketService[this.feeType];
      }
      if (this.type === 'buy') {
        if (['ZAR'].indexOf(this.marketService.activeMarket.exchangeCode) !== -1) {
          this.fee = this.priceChange > 0 ? this.valueChange(new Big(+this.total)
              .times(feeToUse).div(+this.priceChange), this.feeDecimalSpaces)
            : 0;
        } else {
          this.fee = this.valueChange(new Big(+this.total)
            .times(feeToUse), this.feeDecimalSpaces);
        }
      } else {
        if (['ZAR'].indexOf(this.marketService.activeMarket.exchangeCode) !== -1) {
          this.fee = this.price > 0 ? this.valueChange(new Big(+this.total)
              .times(feeToUse).div(this.price), this.feeDecimalSpaces)
            : 0;
        } else {
          this.fee = this.valueChange(new Big(+this.total)
            .times(feeToUse), this.feeDecimalSpaces);
        }
      }
    } catch (ex) { }
  }

  calculateTotal() {
    this.amount = isNaN(this.amount) ? 0 : this.amount;
    this.price = isNaN(this.price) ? 0 : this.price;
    this.total = this.valueChange(new Big(+this.amount).times(+this.price), this.amountDecimalSpaces);
    if (this.type === 'sell') {
      this.validateTotal();
    }
  }

  clear() {
    this.priceChange = (0).toFixed(this.decimalSpaces);
    this.useOrderBook = 0;
    this.checked1 = false;
    this.checked2 = false;
    if (this.type === 'buy') {
      this.formInputTop = this.i18n('Highest Bid');
      this.aboveBelow = 1;
    } else {
      this.formInputTop = this.i18n('Lowest Ask');
      this.aboveBelow = 0;
    }
    this.buyFromSell = 0;
    this.orderAmount = (0).toFixed(this.amountDecimalSpaces);
    this.maxBuyPrice = (0).toFixed(this.decimalSpaces);
    this.total = (0).toFixed(this.amountDecimalSpaces);
    this.maxTotal = (0).toFixed(this.amountDecimalSpaces);
    this.seconds = (0).toFixed(0);
    this.amountAccumulated = 0;
    this.lastPrice = 0;
    this.placementDate = 0;
  }

  fillAmount() {
      this.total = this.valueChange(this.balance.balance_available, this.amountDecimalSpaces);
  }

  getPercentage(percentage: any) {
    return (percentage ? +percentage * 100 : 0).toFixed(2);
  }

  resetForm() {
    this.clear();

    this.activeMarketSub.unsubscribe();

    this.activeMarketSub = this.marketService.activeMarketSubject.subscribe((response) => {
      this.setActiveMarket();
    });
    if (this.type === 'buy') {
      this.formInputTop = this.i18n('Highest Bid');
    } else {
      this.formInputTop = this.i18n('Lowest Ask');
    }
    this.setActiveMarket();

    this.setupFormValidation();
    this.getordertype();
    this.setupForm();
  }

  order() {
    this.orderForm.controls.priceChange.markAsTouched();
    this.orderForm.controls.priceChange.updateValueAndValidity();
    this.orderForm.controls.aboveBelow.markAsTouched();
    this.orderForm.controls.useOrderBook.markAsTouched();
    this.orderForm.controls.buyFromSell.markAsTouched();
    this.orderForm.controls.orderAmount.markAsTouched();
    this.orderForm.controls.orderAmount.updateValueAndValidity();
    this.orderForm.controls.maxBuyPrice.markAsTouched();
    this.orderForm.controls.maxBuyPrice.updateValueAndValidity();
    this.orderForm.controls.total.markAsTouched();
    this.orderForm.controls.total.updateValueAndValidity();
    this.orderForm.controls.seconds.markAsTouched();
    this.orderForm.controls.seconds.updateValueAndValidity();
    this.orderForm.controls.maxTotal.markAsTouched();
    this.orderForm.controls.maxTotal.updateValueAndValidity();

    this.orderForm.markAsTouched();

    if (this.orderForm.valid) {

      let orderTypeNum = 0;
      if (this.type === 'buy') {
        orderTypeNum = 0;
      } else {
        orderTypeNum = 1;
      }

      const buySellOrders = this.returnstring(this.checked2);
      const userOrderBook = this.returnstring(this.checked1);

      let bid = '';
      if (Number(this.aboveBelow) === 1) {
        bid = 'Above';
      } else {
        bid = 'Below';
      }

      const placeOrder = () => {
        const data: any = {
          market_id : Number(this.marketService.activeMarket.id),
          order_type : Number(orderTypeNum),
          price_change : Number(this.priceChange),
          price_change_direction : Number(this.aboveBelow),
          use_orderbook_price : Number(this.checked1),
          match_opposite_orders : Number(this.checked2),
          order_amount : Number(this.orderAmount),
          limit_delay : Number(this.seconds),
          limit_price : Number(this.maxBuyPrice),
          limit_total_coin_amount : Number(this.total)
        };
        this.marketService.createDAOrder(data).subscribe((response) => {
          this.loadscreen.setIsLoading(true);
          if (this.showConfirmation) {
            // checking for specific error messages
            if (response.response === 'success') {
              this.snackbar.open(this.translateService.translateResponse(this.buildSnackbarString()),
                this.i18n('Close'), { duration: 2000 });
              this.setActiveMarket();
              const sleep = (milliseconds) => {
                return new Promise(resolve => setTimeout(resolve, milliseconds));
              };
              sleep(2000).then(() => {
                this.loadscreen.setIsLoading(false);
              });
              this.resetFormValidation();
            } else {
              this.dialog.open(MarketConfirmationComponent, {
                width: '500px',
                data: {
                  title: response.response === 'success' ? 'Success' : 'Failed',
                  result: response.response === 'success' ? 'success' : 'failed',
                  reason: response.reason
                }
              });
            }
          } else if (response.response !== 'success') {
            this.snackbar.open(this.translateService.translateResponse(response.reason),
                this.i18n('Close'), { duration: 5000 });
          }

          if (response.response === 'success') {
            this.amount = 0;

            this.store.updateBalance({
              code: data.type === 0 ?
                this.marketService.activeMarket.exchangeCode : this.marketService.activeMarket.coinCode,
              balance_available: response.balance_available
            });
          }
          this.loadscreen.setIsLoading(false);
        });
      };

      if (this.orderForm.valid && this.type === 'buy') {
        // tslint:disable-next-line:no-shadowed-variable
        const orderType = this.type.charAt(0).toUpperCase() + this.type.slice(1);
        const dialogData: DialogData = {
          title: this.i18n(`{{orderType}} {{coinCode}}`,
            {orderType, coinCode: this.marketService.activeMarket.coinCode }),
          result: 'string',
          reason: this.i18n(`Are you sure you would like to place this {{orderType}} order?<br>
                  <br><b>Price Change : </b> {{priceChange}} {{exchangeCode}}
                  <br><b>Above/Below {{formInputTop}} : </b> {{bid}}
                  <br><b>Use sell orderbook prices : </b> {{useOrderBook}}
                  <br><b>Buy from sell order : </b> {{buySellOrders}}
                  <br><b>Order placement amount : </b>{{orderAmount}} {{coinCode}}
                  <br><b>Maximum buy price : </b> {{maxBuyPrice}} {{exchangeCode}}
                  <br><b>Total amount to buy : </b> {{total}} {{coinCode}}
                  <br><b>Seconds between order placement : </b> {{seconds}} <br><br>
                  Please ensure that the above amounts are correct before confirming.
                  We will be unable to refund/cancel any live order
                  that has been matched.<br><br>
                  Please note that confirming the placement of this order gives {{sitename}} the right to
                  automatically place orders based on these settings using your funds until your desired
                  outcome has been reached or your funds run out`, {
            orderType : orderType,
            priceChange : this.priceChange,
            priceChangeDirection : this.aboveBelow,
            coinCode: this.marketService.activeMarket.coinCode,
            bid: bid,
            total : this.total,
            formInputTop : this.formInputTop,
            useOrderBook: userOrderBook,
            buySellOrders : buySellOrders,
            orderAmount : this.orderAmount,
            maxBuyPrice: this.maxBuyPrice,
            exchangeCode: this.marketService.activeMarket.exchangeCode,
            seconds: this.seconds,
            type: this.type,
            sitename: this.exchangeName === 'chainex' ? 'ChainEX' : 'BurnX'
          }),
          okButton: true,
          isTranslated: true,
          titleTranslated: true
        };
        const dialogRef = this.dialog.open(MarketConfirmationComponent, {
          width: '600px',
          data: dialogData
        });
        dialogRef.afterClosed().subscribe((result) => {
          if (!!result && result.result === 'accept') {
            placeOrder();
          }
        });
      } if (this.orderForm.valid && this.type === 'sell') {
        // tslint:disable-next-line:no-shadowed-variable
        const orderType = this.type.charAt(0).toUpperCase() + this.type.slice(1);
        const dialogData: DialogData = {
          title: this.i18n(`{{orderType}} {{coinCode}}`,
            {orderType, coinCode: this.marketService.activeMarket.coinCode }),
          result: 'string',
          reason: this.i18n(`Are you sure you would like to place this {{orderType}} order?<br>
                  <br><b>Price Change : </b> {{priceChange}} {{exchangeCode}}
                  <br><b>Above/Below {{formInputTop}} : </b> {{bid}}
                  <br><b>Use buy orderbook price : </b> {{useOrderBook}}
                  <br><b>Sell to buy order : </b> {{buySellOrders}}
                  <br><b>Order placement amount : </b>{{orderAmount}} {{coinCode}}
                  <br><b>Minimum sell price : </b> {{maxBuyPrice}} {{exchangeCode}}
                  <br><b>Total amount to sell : </b> {{total}} {{coinCode}}
                  <br><b>Seconds between order placement : </b> {{seconds}} <br><br>
                  Please ensure that the above amounts are correct before confirming.
                  We will be unable to refund/cancel any live order
                  that has been matched.<br><br>
                  Please note that confirming the placement of this order gives {{sitename}} the right to
                  automatically place orders based on these settings using your funds until your desired
                  outcome has been reached or your funds run out`, {
            orderType : orderType,
            priceChange : this.priceChange,
            priceChangeDirection : this.aboveBelow,
            coinCode: this.marketService.activeMarket.coinCode,
            bid: bid,
            total : this.total,
            formInputTop : this.formInputTop,
            useOrderBook: userOrderBook,
            buySellOrders : buySellOrders,
            orderAmount : this.orderAmount,
            maxBuyPrice: this.maxBuyPrice,
            exchangeCode: this.marketService.activeMarket.exchangeCode,
            seconds: this.seconds,
            type: this.type,
            sitename: this.exchangeName === 'chainex' ? 'ChainEX' : 'BurnX'
          }),
          okButton: true,
          isTranslated: true,
          titleTranslated: true
        };
        const dialogRef = this.dialog.open(MarketConfirmationComponent, {
          width: '600px',
          data: dialogData
        });
        dialogRef.afterClosed().subscribe((result) => {
          if (!!result && result.result === 'accept') {
            placeOrder();
          }
        });
      }
    }
  }

  setActiveMarket() {
    if (this.marketService.activeMarket.exchangeDecimals) {
      this.decimalSpaces = Number(this.marketService.activeMarket.exchangeDecimals);
      if (this.marketService.activeMarket.exchangeCode === 'ZAR') {
        this.feeDecimalSpaces = this.marketService.activeMarket.coinDecimals ?
        Number(this.marketService.activeMarket.coinDecimals) : 8;
      } else {
        this.feeDecimalSpaces = Number(this.marketService.activeMarket.exchangeDecimals);
      }
    } else {
      this.decimalSpaces = ['ZAR', 'USDT'].indexOf(this.marketService.activeMarket.exchangeCode) === -1 ? 8 : 2;
      this.feeDecimalSpaces = ['USDT'].indexOf(this.marketService.activeMarket.exchangeCode) === -1 ? 8 : 2;
    }

    if (this.marketService.activeMarket.coinDecimals) {
    this.amountDecimalSpaces = Number(this.marketService.activeMarket.coinDecimals);
    } else {
      this.amountDecimalSpaces = ['ZAR', 'USDT'].indexOf(this.marketService.activeMarket.coinCode) === -1 ? 8 : 2;
    }

    this.totalMinimum = ['ZAR', 'USDT'].indexOf(this.marketService.activeMarket.exchangeCode)
    === -1 ? 0.00000001 : 0.01;
    this.validationMessage =
      ['ZAR', 'USDT'].indexOf(this.marketService.activeMarket.exchangeCode) === -1 ? 0.00000001 : 0.01;

    this.orderAmount =  (0).toFixed(this.amountDecimalSpaces);
    this.total = (0).toFixed(this.amountDecimalSpaces);

    if (this.balanceSubs) {
      this.balanceSubs.unsubscribe();
    }

    this.exchangeCode = !!this.marketService.activeMarket ? this.marketService.activeMarket.exchangeCode : '';
    this.coinCode = this.marketService.activeMarket.coinCode;

    if (this.marketService.activeMarket.id !== '-1') {

      this.balanceSubs = this.store.subscribe('balances').subscribe(response => {
        if (!response.refresh) {
          const filteredResponse = response.data.filter(responseBalance => responseBalance.code === (
            this.type === 'buy' ?
              this.marketService.activeMarket.exchangeCode : this.marketService.activeMarket.coinCode))[0];
          if (filteredResponse !== undefined) {
            this.balance = filteredResponse;
            this.balance.balance_available = Number(this.balance.balance_available);
            if (!this.CDRef['destroyed']) {
              this.CDRef.detectChanges();
            }
          }
        }
      });
    }

    this.firstLoad = true;
    this.data.market = Number(this.marketService.activeMarket.id);
    this.data.allorders = false;
    this.clear();
    this.historyService.getDAOrders(this.data).subscribe((response: any) => {
      const responseFilterFata: any = response.data;
      let marketIndex: number = -1;
      if (responseFilterFata) {
        for (let i = 0; i < responseFilterFata.length; i++) {
          if (responseFilterFata[i].market === this.marketService.activeMarket.id) {
            marketIndex = i;
          }
        }
      }
      if (marketIndex === -1) {
        this.marketService.setDaStatus(-1);
      } else {
        this.priceChange = response.data[marketIndex].price_change;
        this.priceChange = this.valueChange(this.priceChange, this.decimalSpaces);
        this.aboveBelow = response.data[marketIndex].price_change_direction;

        this.useOrderBook = response.data[marketIndex].use_orderbook_price;
        if (Number(this.useOrderBook) === 1) {
          this.checked1 = true;
          this.changelabel();
        } else {
          this.checked1 = false;
        }
        this.amountAccumulated = this.valueChange(response.data[marketIndex].amount_traded, this.amountDecimalSpaces);
        this.placementDate = moment.unix(response.data[marketIndex].created_at_date).format('YYYY/MM/DD hh:mm:ss');
        this.lastPrice = this.valueChange(response.data[marketIndex].last_price, this.decimalSpaces);
        this.buyFromSell = response.data[marketIndex].match_opposite_orders;
        if (Number(this.buyFromSell) === 1) {
          this.checked2 = true;
        } else {
          this.checked2 = false;
        }
        this.orderAmount = response.data[marketIndex].order_amount;
        this.orderAmount = this.valueChange(this.orderAmount, this.amountDecimalSpaces);
        this.maxBuyPrice = response.data[marketIndex].limit_price;
        this.maxBuyPrice = this.valueChange(this.maxBuyPrice, this.decimalSpaces);
        this.total = response.data[marketIndex].limit_total_coin_amount;
        this.total = this.valueChange(this.total, this.amountDecimalSpaces);
        this.seconds = response.data[marketIndex].limit_delay;
        this.marketService.setDaStatus(Number(response.data[marketIndex].order_type));
      }
    });
    this.CDRef.detectChanges();
  }

  returnstring(event: boolean) {
    if (event === true) {
      return this.i18n('Yes');
    } else {
      return this.i18n('No');
    }
  }

  totalChange() {
    this.total = this.valueChange(this.total, this.amountDecimalSpaces);
    if (this.type === 'buy') {
      this.price = (+this.price).toFixed(this.decimalSpaces);
    } else {
      this.validateTotal();
    }

    this.amount = this.valueChange(new Big(+this.total).div(+this.price), this.amountDecimalSpaces);
    const calculatedTotal = this.valueChange(new Big(+this.amount).times(+this.price), this.decimalSpaces + 1);
    if (+this.total !== +calculatedTotal) {
      const multiplier = new Big(10).pow(this.amountDecimalSpaces);
      let calculatedAmount: number =
      +this.valueChange(new Big(+this.total).div(+this.price), this.amountDecimalSpaces + 1);
      const roundedCalc = Math.round(multiplier.times(calculatedAmount).add(1));
      calculatedAmount = new Big(roundedCalc).div(multiplier);
      this.amount = calculatedAmount.toFixed(this.amountDecimalSpaces);
    }

    this.calculateFee();
  }

  validateTotal() {
    this.orderForm.controls.total.markAsTouched();
  }

}

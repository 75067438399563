import {
  Component,
  OnInit,
  ViewChild,
  OnDestroy,
  AfterViewInit,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
} from '@angular/core';
import { ActivatedRoute, Router, RouterStateSnapshot } from '@angular/router';
import { SubscriptionLike } from 'rxjs';
import { take } from 'rxjs/operators';
import { MetaService } from '@ngx-meta/core';
import { I18n } from '@ngx-translate/i18n-polyfill';

import { SessionStorageService } from './../../core/services/session-storage.service';
import { OrderBookComponent } from '../order-book/order-book.component';
import { OrderFormComponent } from '../order-form/order-form.component';
import { SidebarComponent } from '../sidebar/sidebar.component';
import { MarketHistoryComponent } from '../market-history/market-history.component';
import { ChartComponent } from '../chart/chart.component';
import { StoreService } from '../../core/services/store.service';
import { ContactSupportComponent } from 'src/app/contact-support/contact-support.component';
import { RouterHelper } from 'src/app/core/router-helper';
import { MarketsService } from '../markets.service';
import { OrderTypesComponent } from '../order-types/order-types-component';
import { environment } from '../../../environments/environment';


type GetLoginUrl = (router: Router, state: RouterStateSnapshot) => void;

@Component({
  selector: 'markets-markets',
  templateUrl: './markets.component.html',
  styleUrls: ['./markets.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MarketsComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('activeOrders') activeOrders: MarketHistoryComponent;
  @ViewChild('buyForm') buyForm: OrderFormComponent;
  chat: ContactSupportComponent;
  @ViewChild('chart') chart: ChartComponent;
  coin1: string;
  coin2: string;
  defaultMarket: string = '1';
  getLoginUrl: GetLoginUrl = RouterHelper.getLoginUrl;
  hideBuySell: boolean = false;
  loadDefault: boolean = true;
  loggedIn: boolean = false;
  marketPairs: any = [];
  @ViewChild('orderBook') orderBook: OrderBookComponent;
  @ViewChild('orderHistory') orderHistory: MarketHistoryComponent;

  @ViewChild('orderTypes') orderTypesComponent: OrderTypesComponent;
  @ViewChild('orderTypesMobile') orderTypesComponentMobile: OrderTypesComponent;

  @ViewChild('mobileBuyForm') mobileAccForm: OrderFormComponent;
  @ViewChild('mobileSellForm') mobileDisForm: OrderFormComponent;

  @ViewChild('buyForm') AccumulationForm: OrderFormComponent;
  @ViewChild('sellForm') DistributionForm: OrderFormComponent;

  @ViewChild('sidebar') sidebar: SidebarComponent;
  storeSubscriptions: any = [];
  @ViewChild('trades') trades: MarketHistoryComponent;
  verificationLevel: number;
  marketExists: boolean = true;
  executionType: string = 'limit';

  disableHistory: boolean = environment.config.DISABLE_MARKET_HISTORY;

  showChainEXMarket: boolean = environment.config.CHAINEX_MARKET;
  showBurnxMarket: boolean = environment.config.BURNX_MARKET;

  retries: any = 0;

  private subs: SubscriptionLike[] = [];
  exchangeName: any = environment.config.EXCHANGE_NAME_L;

  constructor(
    public router: Router,
    private sessionStorage: SessionStorageService,
    public store: StoreService,
    private route: ActivatedRoute,
    private readonly meta: MetaService,
    private cdr: ChangeDetectorRef,
    public marketService: MarketsService,
    private i18n: I18n
  ) { }

  ngOnInit() {
    if (this.route.firstChild) {
      this.subs.push(this.route.firstChild.params.subscribe(params => {
        this.coin1 = !params['coin1'] ? null : params['coin1'].toUpperCase(),
        this.coin2 = !params['coin2'] ? null : params['coin2'].toUpperCase();
      }));
    }

    this.store.subscribe('settings').pipe(take(1)).subscribe((response) => {
      if (!response.refresh) {
        this.defaultMarket =
          !!response.data &&
            !!response.data.defaultMarket ?
            response.data.defaultMarket : '1';
        if (!!this.sessionStorage.get('selected_market')) {
          this.loadDefault = false;
          this.setMarketById(this.sessionStorage.get('selected_market'));
        }
        if (this.loadDefault && this.marketService.getMarketLength() > 0 && !!response.data.defaultMarket) {
          this.setMarketFromPair(this.coin1, this.coin2, this.defaultMarket);
        }
      }
    });

    if (this.sessionStorage.get('LOGGED_IN') !== '' &&
      this.sessionStorage.get('TOKEN') !== '') {
      this.loggedIn = true;
    } else {
      this.loggedIn = false;
    }

    let level: any = this.sessionStorage.get('PROFILE_VERIFICATION_LEVEL');
    if (level === '') {
      level = 0;
    } else {
      level = Number(level);
    }

    this.verificationLevel = level;

    this.subs.push(this.marketService.marketIdChange.subscribe((marketID) => this.onMarketIDChange(marketID)));
  }

  ngAfterViewInit() {
    this.loadMarket();
  }

  loadMarket() {
    if (this.marketService.getMarketLength() > 0) {
      // check if there is a coin1 and coin2 parameter from the url
      // if there is make sure the coinpair exists, either route to 404
      if (!!this.coin1 && !!this.coin2) {
        if (!this.marketService.coinPairExist(this.coin1, this.coin2)) {
          this.marketExists = false;
        } else {
          if (this.coin1 && this.coin2) {
            const foundMarket = this.marketService.getMarketbyCoinPair(this.coin1, this.coin2);
            if (foundMarket) {
              this.marketService.setActiveMarket(foundMarket);
            }
          }
        }
      }

      // this is needed otherwise the default route meta in markets.routing.ts is used
      if (this.marketService.activeMarket.lastPrice != null) {
        this.setMarketMetaTitle(this.marketService.activeMarket.lastPrice);
      }

      const marketTitle = response => {
        if (response.type === 'trade' && !!response.price &&
          response.feed.split('-')[1] === this.marketService.activeMarket.id) {
          this.setMarketMetaTitle(response.price);
          const fixedLastPrice = Number(response.price).toFixed(this.marketService.activeMarket.exchangeDecimals ?
            this.marketService.activeMarket.exchangeDecimals : 8);
          this.setMarketMetaTitle(fixedLastPrice);
        }
      };

      this.subs.push(this.store.subscribe('trade').subscribe(marketTitle.bind(this)));
      this.getMarketData();
      this.getAvailableMarketPairs();
    } else {
      this.retries = this.retries + 1;
      if (this.retries < 500) {
        setTimeout(() => { this.loadMarket(); }, 200);
      } else { this.router.navigate(['404']); }
    }
  }

  ngOnDestroy() {
    this.subs.forEach(sub => sub.unsubscribe());
  }

  chatVisibility(hidden: boolean) {
    if (!hidden) {
      this.chat.showForm();
    } else {
      this.chat.hidden = hidden;
    }
  }

  // Get market pairs if available
  // If not available, this will repeat when the active market observable updates
  getAvailableMarketPairs() {
    if (this.loadDefault && this.marketService.getMarketLength() > 0) {
      this.setMarketFromPair(this.coin1, this.coin2, this.defaultMarket);
    }
  }

  // Start getting market pairs by subscribing to the service observable
  getMarketData() {
    this.subs.push(
      this.marketService.marketUpdateSubject.subscribe(() => {
        this.getAvailableMarketPairs();
      })
    );
  }

  isIEOVerificationLevelBuyRestricted(market: any) {
    if (!this.isIEOBuyRestricted(market) && market.ieoCoin === '1' && this.verificationLevel < 1) {
      const ieoCoins = this.sessionStorage.get('IEO_COINS');
      if (!ieoCoins || ieoCoins.indexOf(market.exchangeId) === -1) {
        return true;
      }
    }
  }

  isIEOSellRestricted(market: any) {
    if (market.ieoCoin === '1' || market.ieoExchange === '1') {
      const ieoCoins = this.sessionStorage.get('IEO_COINS');
      if (!ieoCoins || (ieoCoins.indexOf(market.coinId) === -1 && ieoCoins.indexOf(market.exchangeId) === -1)) {
        return true;
      }
    }
  }

  isFiatRestricted(market: any) {
    return (this.verificationLevel <= 0)
      && ((market.exchangeCode === 'ZAR' || market.coinCode === 'ZAR')
        || (market.exchangeCode === 'USDT' || market.coinCode === 'USDT'));
  }

  isIEOBuyRestricted(market: any) {
    if (market.ieoExchange === '1' ||
      ((market.ieoCoin === '1' || market.ieoExchange === '1') && this.verificationLevel < 2)) {
      const ieoCoins = this.sessionStorage.get('IEO_COINS');
      if (!ieoCoins || ieoCoins.indexOf(market.exchangeId) === -1) {
        return true;
      }
    }
  }

  onHideBuySellChange(hideBuySell: boolean) {
    this.hideBuySell = hideBuySell;
    this.chatVisibility(hideBuySell);
  }

  onMarketIDChange(marketID: string) {
    this.setMarketById(marketID);
    this.cdr.markForCheck();
  }

  onValueChange(values: any) {
    if (!!this.orderTypesComponent) {
      this.orderTypesComponent.onValueChange(values);
    }
    if (!!this.orderTypesComponentMobile) {
      this.orderTypesComponentMobile.onValueChange(values);
    }
  }

  setForm(chat: ContactSupportComponent) {
    if (!!chat) {
      this.chat = chat;
    }
  }

  setMarket() {
    if (this.marketExists) {
      if (this.marketService.activeMarket.lastPrice != null) {
        this.setMarketMetaTitle(this.marketService.activeMarket.lastPrice);
      }
      this.store.connectMarket(`marketUpdates-${this.marketService.activeMarket.id}`);
      this.router.navigate(['markets/' + this.marketService.activeMarket.marketPair],
        { replaceUrl: true });
    } else {
      this.router.navigate(['unavailable']);
    }
  }

  setMarketById(id: any) {
    if (this.marketService.activeMarket.id === id) {
      this.setMarket();
    } else {
      const pair = this.marketService.getMarketPair(id);
      if (!!pair) {
        this.marketService.setActiveMarket(pair);
      }
    }
  }

  setMarketFromPair(coin1: string, coin2: string, market: string) {
    if (!this.marketService.marketsLoaded) {
      setTimeout(() => this.setMarketFromPair(coin1, coin2, market), 100);
      return;
    }
    const pairFound = this.marketService.getMarketbyCoinPair(coin1, coin2, market);
    if (!pairFound) {
      return;
    }
    if (this.loadDefault) {
      setTimeout(() => this.loadDefault = false, 10000);
    }
    this.marketService.setActiveMarket(pairFound);
  }

  public setMarketMetaTitle(lastPrice: string) {
    let exchangeName = '';
    if (environment.config.EXCHANGE_NAME_L !== 'chainex') {
      exchangeName = 'BurnX';
    } else {
      exchangeName = 'ChainEX';
    }

    if (!!this.marketService.activeMarket.marketPair && lastPrice !== undefined) {
      this.meta.setTitle(`(${lastPrice} ${this.marketService.activeMarket.marketPair}) - ` +
        exchangeName  + ' - ' + this.i18n('Market'));
    } else {
      this.meta.setTitle(environment.config.EXCHANGE_NAME + ' - ' + this.i18n('Market'));
    }

    const description = `${exchangeName} markets provide real-time \
trades and statistics. Buy, sell, trade and exchange ${this.marketService.activeMarket.coinName} \
(${this.marketService.activeMarket.coinCode}) and other digital assets 24 hours a day.`;

    this.meta.setTag('description', description);
    this.meta.setTag('og:description', description);
    this.meta.setTag('twitter:description', description);

  }

  updateChart() {
    if (!this.chart || !this.orderBook) {
      return;
    }

    this.chart.drawChart(
      this.orderBook.buyDataArray,
      this.orderBook.sellDataArray);
  }

  setExecutionType(type: string) {
    this.executionType = type;
    this.AccumulationForm.setExecutionType(type);
    this.DistributionForm.setExecutionType(type);
  }

}

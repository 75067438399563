<div class="row">
  <div class="table-heading click-thru col-12 col-xl-4 ">
    <ng-container>{{type|i18n|uppercase}} {{executionTypeStop}}</ng-container> {{marketService.activeMarket.coinCode}}
  </div>
  <div class="col-12 col-xl-8 text-left text-lg-right table-heading">
    <div class="row flex-end">
      <div class="clickable note-box" (click)="fillAmount()" i18n-matTooltip placement="bottom" data-container="body" matTooltip="{{translatedWords['AVAILABLE BALANCE: ']}}{{(balance?.balance_available || 0) | decimalNumber:(type === 'buy' ? decimalSpaces : amountDecimalSpaces) }} {{balance?.code}}">
        <span class="no-bold" i18n>AVAILABLE : </span>
        <span>{{(balance?.balance_available || 0) | decimalNumber:(type === 'buy' ? decimalSpaces :
            amountDecimalSpaces)}}</span> &nbsp;
        <span>{{balance?.code}}</span>
      </div>
      <app-balance-viewer
        [available]="(balance?.balance_available || 0) | decimalNumber: type === 'buy' ? decimalSpaces : amountDecimalSpaces"
        [held]="(balance?.balance_held || 0) | decimalNumber: type === 'buy' ? decimalSpaces : amountDecimalSpaces"
        [earn]="(balance?.balance_earn || 0) | decimalNumber: type === 'buy' ? decimalSpaces : amountDecimalSpaces"
        [code]="(type === 'buy') ? exchangeCode : coinCode">
      </app-balance-viewer>
    </div>
  </div>
</div>
<div [formGroup]="orderForm" class="order-form small-text">
  <mat-grid-list cols="8" rowHeight="8px">
    <div [ngClass]="{'hidden': orderForm.controls.amount.disabled}">
      <mat-grid-tile class="field-label" [colspan]="2" [rowspan]="3" i18n>
        Amount
      </mat-grid-tile>
      <mat-grid-tile [colspan]="4" [rowspan]="3">
        <mat-form-field class="w-100">
          <input matInput formControlName="amount" min="0" step="0.00000001" type="number"
            (change)="amountChange()" (input)="markAsTouched()" (keydown)="markAsTouched()" (paste)="markAsTouched()" [(ngModel)]="amount" />
          <mat-error *ngIf="orderForm.controls.amount.hasError('min')">
            <ng-container i18n>Minimum {{minAmount}} {{marketService.activeMarket.coinCode}}</ng-container>
          </mat-error>
          <mat-error *ngIf="orderForm.controls.amount.invalid && !orderForm.controls.amount.hasError('min')">
            <ng-container i18n>{{getErrorMessage()}}</ng-container>
          </mat-error>
        </mat-form-field>
      </mat-grid-tile>
      <mat-grid-tile [colspan]="2" [rowspan]="3" class="field-label">
        {{marketService.activeMarket.coinCode}}
      </mat-grid-tile>
    </div>

    <div [ngClass]="{'hidden': orderForm.controls.stopprice.disabled}">
      <mat-grid-tile class="field-label" [colspan]="2" [rowspan]="3">
        <ng-container i18n>Stop&nbsp;<mat-icon matTooltipClass="stop-info-tooltip" class="stop-info" svgIcon="stop-info" placement="top" i18n-matTooltip matTooltip="{{ stopToolTip }}" triggers="hover click" ></mat-icon></ng-container>
        <p class="ml-auto mb-0 mt-auto">{{stopLogicOperator}}&nbsp;</p>
      </mat-grid-tile>
      <mat-grid-tile [colspan]="4" [rowspan]="3">
        <mat-form-field class="w-100">
          <input (change)="stopChange()" (input)="markAsTouched()" (keydown)="markAsTouched()" (paste)="markAsTouched()"
            matInput formControlName="stopprice" min="0" [max]="type === 'sell' ? balance?.balance_available : undefined" step="0.00000001" type="number" [(ngModel)]="stopprice" />
          <mat-error *ngIf="orderForm.controls.stopprice.hasError('min') && type === 'buy'">
            <ng-container i18n>Minimum :</ng-container> {{lastprice}} {{exchangeCode}}
          </mat-error>
          <mat-error *ngIf="orderForm.controls.stopprice.hasError('max') && type === 'sell'">
            <ng-container i18n>Maximum :</ng-container> {{lastprice}} {{exchangeCode}}
          </mat-error>
        </mat-form-field>
      </mat-grid-tile>
      <mat-grid-tile [colspan]="2" [rowspan]="3" class="field-label">
        {{exchangeCode}}
      </mat-grid-tile>
    </div>

    <!--<div>
      <mat-grid-tile class="field-label" [colspan]="1" [rowspan]="3" i18n>
        Apply Limit
      </mat-grid-tile>
      <mat-grid-tile [colspan]="2" [rowspan]="3">
        <div class="checkbox-margin"> <mat-checkbox (click)="changeStop()"></mat-checkbox> </div>
      </mat-grid-tile>
      <mat-grid-tile [colspan]="5" [rowspan]="3">
      </mat-grid-tile>
    </div>-->

    <div [ngClass]="{'hidden': orderForm.controls.price.disabled}">
      <mat-grid-tile class="field-label" [colspan]="2" [rowspan]="3" i18n>
        Price
      </mat-grid-tile>
      <mat-grid-tile [colspan]="4" [rowspan]="3">
        <mat-form-field class="w-100" *ngIf="!applystop">
          <input matInput type="text" value="MARKET" readonly *ngIf="!displayPrice" disabled="true" />
        </mat-form-field>
        <mat-form-field class="w-100" *ngIf="applystop">
          <input (change)="priceChange()" (input)="markAsTouched()" (keydown)="markAsTouched()" (paste)="markAsTouched()"
            matInput formControlName="price" min="0" step="0.00000001" type="number" [(ngModel)]="price" />
        </mat-form-field>
      </mat-grid-tile>
      <mat-grid-tile [colspan]="2" [rowspan]="3" class="field-label">
        {{exchangeCode}}
      </mat-grid-tile>
    </div>

    <div [ngClass]="{'hidden': orderForm.controls.total.disabled}">
      <mat-grid-tile class="field-label" [colspan]="2" [rowspan]="3" i18n>
        Total
      </mat-grid-tile>
      <mat-grid-tile [colspan]="4" [rowspan]="3">
        <mat-form-field class="w-100">
          <input matInput formControlName="total" min="0" step="0.00000001" type="number"
            (change)="totalChange()" (input)="markAsTouched()" (keydown)="markAsTouched()" (paste)="markAsTouched()" [(ngModel)]="total" />
          <mat-error *ngIf="orderForm.controls.total.hasError('min')">
            <ng-container i18n>Minimum</ng-container>
            {{totalMinimum | decimalNumber : decimalSpaces}} {{type === 'buy' ? balance?.code : exchangeCode}}
          </mat-error>
        </mat-form-field>
      </mat-grid-tile>
      <mat-grid-tile [colspan]="2" [rowspan]="3" class="field-label">
        {{exchangeCode}}
      </mat-grid-tile>
    </div>

    <div [ngClass]="{'hidden': orderForm.controls.net_total.disabled}">
      <mat-grid-tile class="field-label" [colspan]="2" [rowspan]="3" i18n>
        Net Total
      </mat-grid-tile>
      <mat-grid-tile [colspan]="4" [rowspan]="3">
        <mat-form-field class="w-100">
          <input matInput formControlName="net_total" [min]="this.totalMinimum" step="0.00000001" type="number" [(ngModel)]="netTotal"
            (change)="netTotalChange()" (input)="markAsTouched()" (keydown)="markAsTouched()" (paste)="markAsTouched()" />
          <mat-error *ngIf="type !== 'sell' && orderForm.controls.net_total.hasError('max')">
            <ng-container i18n>Available balance:</ng-container> {{balance.balance_available | decimalNumber : decimalSpaces}} {{balance.code}}
          </mat-error>
          <mat-error *ngIf="type !== 'sell' && orderForm.controls.net_total.hasError('min')">
            <ng-container i18n>Minimum</ng-container> {{this.totalMinimum | decimalNumber : decimalSpaces}} {{balance.code}}
          </mat-error>
        </mat-form-field>
      </mat-grid-tile>
      <mat-grid-tile [colspan]="2" [rowspan]="3" class="field-label">
        {{exchangeCode}}
      </mat-grid-tile>
    </div>

    <mat-grid-tile class="mt-3" [colspan]="4" [rowspan]="6">
      <button mat-raised-button class="w-100 mat-raised-button" color="primary" (click)="clear()" i18n>CLEAR</button>
    </mat-grid-tile>

    <mat-grid-tile class="mt-3" [colspan]="4" [rowspan]="6">
      <button mat-raised-button class="w-100 mat-raised-button" color="primary" (click)="order()" i18n>{{type|uppercase}}</button>
    </mat-grid-tile>

  </mat-grid-list>
</div>

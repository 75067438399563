import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { MarketsService } from '../markets.service';
import { Router, RouterStateSnapshot } from '@angular/router';
import { RouterHelper } from 'src/app/core/router-helper';
import { SessionStorageService } from 'src/app/core/services/session-storage.service';
import { ScaledOrderComponent } from './scaled-order/scaled-order-chainex.component';
import { StoreService } from 'src/app/core/services/store.service';
import { SubscriptionLike, BehaviorSubject} from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { MatTabGroup } from '@angular/material/tabs';
import { environment } from 'src/environments/environment';
import { HostListener } from '@angular/core';

type GetLoginUrl = (router: Router, state: RouterStateSnapshot) => void;

@Component({
  selector: 'app-order-types',
  templateUrl: './order-types-component.html',
  styleUrls: ['./order-types-component.scss']
})
export class OrderTypesComponent implements OnInit, OnDestroy {
  scrHeight: any;
  scrWidth: any;
  getLoginUrl: GetLoginUrl = RouterHelper.getLoginUrl;

  verificationLevel: number;
  loggedIn: boolean = false;

  exchangeName: string = environment.config.EXCHANGE_NAME_L;

  @ViewChild('orderTabs') tabGroup: MatTabGroup;

  private subs: SubscriptionLike[] = [];

  lastValues: any = {};
  lastIndex: number = 0;
  orderType2: any = new BehaviorSubject<Number>(-1) ;

  @HostListener('window:resize', ['$event'])
  getScreenSize() {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
  }

  constructor(
    public router: Router,
    public marketService: MarketsService,
    private sessionStorage: SessionStorageService,
    public store: StoreService,
    private dialog: MatDialog,
  ) {
    this.getScreenSize();

    this.marketService.getDaStatus().subscribe(value => {
      this.orderType2.next(value);
    });
  }

  ngOnInit() {

    if (this.sessionStorage.get('LOGGED_IN') !== '' &&
      this.sessionStorage.get('TOKEN') !== '') {
      this.loggedIn = true;
    } else {
      this.loggedIn = false;
    }

    let level: any = this.sessionStorage.get('PROFILE_VERIFICATION_LEVEL');
    if (level === '') {
      level = 0;
    } else {
      level = Number(level);
    }

    this.verificationLevel = level;
  }

  ngOnDestroy() {
    this.subs.forEach(sub => sub.unsubscribe());
  }

  showScaledOrder() {
    this.dialog.open(ScaledOrderComponent);
  }

  orderChange(selectedIndex: number) {
    const selectedIndexEnv = this.exchangeName === 'chainex' ? 4 : 5;
    if (selectedIndex === selectedIndexEnv) {
      this.tabGroup.selectedIndex = this.lastIndex;
      this.showScaledOrder();
    } else {
      this.lastIndex = selectedIndex;
    }
  }

  public onValueChange(values: any) {
    if (values !== {}) {
      this.lastValues = JSON.parse(JSON.stringify(values));
    }
  }

  isIEOVerificationLevelBuyRestricted(market: any) {
    if (!this.isIEOBuyRestricted(market) && market.ieoCoin === '1' && this.verificationLevel < 1) {
      const ieoCoins = this.sessionStorage.get('IEO_COINS');
      if (!ieoCoins || ieoCoins.indexOf(market.exchangeId) === -1) {
        return true;
      }
    }
  }

  get isMobile( ) {
    if ( this.scrWidth <= 991 ) {
      return true;
    }
    return false;
  }

  isIEOSellRestricted(market: any) {
    if (market.ieoCoin === '1' || market.ieoExchange === '1') {
      const ieoCoins = this.sessionStorage.get('IEO_COINS');
      if (!ieoCoins || (ieoCoins.indexOf(market.coinId) === -1 && ieoCoins.indexOf(market.exchangeId) === -1)) {
        return true;
      }
    }
  }

  isFiatRestricted(market: any) {
    if (this.exchangeName === 'burnx') {
      return (this.verificationLevel <= 0
        && market.exchangeCode === 'ZAR' || market.coinCode === 'ZAR');
    } else if (this.exchangeName === 'chainex') {
      return (this.verificationLevel <= 0)
      && ((market.exchangeCode === 'ZAR' || market.coinCode === 'ZAR')
        || (market.exchangeCode === 'USDT' || market.coinCode === 'USDT'));
    }
  }

  isIEOBuyRestricted(market: any) {
    if (market.ieoExchange === '1' ||
      ((market.ieoCoin === '1' || market.ieoExchange === '1') && this.verificationLevel < 2)) {
      const ieoCoins = this.sessionStorage.get('IEO_COINS');
      if (!ieoCoins || ieoCoins.indexOf(market.exchangeId) === -1) {
        return true;
      }
    }
  }

}

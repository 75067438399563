<div  class="col-12 extra-padding" *ngIf="((orderType2 | async)  === -1)" data-ng-init="clear()">
  <div class="row">
    <div class="table-heading click-thru col-12 col-xl-4 ">
      <ng-container i18n>{{type|i18n|uppercase}}</ng-container>
      {{coinCode}}
    </div>
    <div class="col-12 col-xl-8 text-left text-lg-right table-heading custom-tooltip">
      <div class="row flex-end">
        <div class="clickable note-box" (click)="fillAmount()" i18n-matTooltip placement="bottom"
            matTooltip="AVAILABLE BALANCE : {{(balance?.balance_available || 0) | decimalNumber:(type === 'buy' ? decimalSpaces : amountDecimalSpaces) }} {{balance?.code}}">
          <span class="no-bold" i18n>AVAILABLE : </span>
          <span>{{(balance?.balance_available || 0) | decimalNumber:(type === 'buy' ? decimalSpaces :
            amountDecimalSpaces)}}</span>
          &nbsp;
          <span>{{balance?.code}}</span>
        </div>
        <app-balance-viewer
          [available]="(balance?.balance_available || 0) | decimalNumber: type === 'buy' ? decimalSpaces : amountDecimalSpaces"
          [held]="(balance?.balance_held || 0) | decimalNumber: type === 'buy' ? decimalSpaces : amountDecimalSpaces"
          [earn]="(balance?.balance_earn || 0) | decimalNumber: type === 'buy' ? decimalSpaces : amountDecimalSpaces"
          [code]="(type === 'buy') ? exchangeCode : coinCode">
        </app-balance-viewer>
      </div>
    </div>
  </div>
  <div>
    <div [formGroup]="orderForm" class="orderForm small-text col-xs-6">
      <mat-grid-list  cols="8" rowHeight="10px">
        <div [ngClass]="{'hidden': orderForm.controls.priceChange.disabled}">
          <mat-grid-tile class="field-label left-align" [colspan]="4" [rowspan]="3">
            <span class="matgridtilecust3" i18n>Price Change</span>
          </mat-grid-tile>
          <mat-grid-tile [colspan]="3" [rowspan]="3" class="overflowshow" >
            <mat-form-field class="w-100 matfieldcust">
              <input matInput formControlName="priceChange" min="0" class="inputcust"
                     [max]="type === 'sell' ? balance?.balance_available : 450" step="0.00000001" type="number"
                     (change)="amountChange()" [(ngModel)]="priceChange" />
              <mat-error *ngIf="orderForm.controls.priceChange.hasError('min') || orderForm.controls.priceChange.hasError('required')">
                <ng-container i18n>Minimum</ng-container> {{this.totalMinimum | decimalNumber : decimalSpaces}}
              </mat-error>
            </mat-form-field>
          </mat-grid-tile>
          <mat-grid-tile [colspan]="1" [rowspan]="3" class="field-label">
            <span class="w-100">{{ exchangeCode }}</span>
          </mat-grid-tile>

        </div>
      </mat-grid-list>
      <div [formGroup]="orderForm" class="orderForm small-text col-xs-6">
          <div>
            <mat-grid-list  cols="8" rowHeight="10px">
              <mat-grid-tile [colspan]="7" [rowspan]="3">
            <table class="tablewidth">
              <tr>
                <td></td>
                <td>
                  <div class="fixed-end">
                    <mat-radio-group aria-label="Select an option" class="overflowshow align-right" formControlName="aboveBelow">
                      <mat-radio-button class="matradio1" value="0" i18n>Below </mat-radio-button>
                      <mat-radio-button  class="matradio2" value="1" i18n> Above {{formInputTop}} </mat-radio-button>
                    </mat-radio-group>
                  </div>
                </td>
              </tr>
            </table>
              </mat-grid-tile>
              <mat-grid-tile [colspan]="1" [rowspan]="3" class="field-label">
                <span class="w-100"></span>
              </mat-grid-tile>
            </mat-grid-list>
            <table class="tablewidth">
              <tr>
                <td class="w-50">
                  <span class="matgridtilecust4" *ngIf="type === 'buy'" i18n>Sell Order Matching (Optional)</span>
                  <span class="matgridtilecust4" *ngIf="type === 'sell'" i18n>Buy Order Matching (Optional)</span>
                </td>
                <td>
                  <span class="align-left">
                    <mat-checkbox formControlName="useOrderBook" labelPosition="after" class="checkboxcust" [(ngModel)]="checked1" (change)="changelabel()">
                        <span *ngIf="type === 'buy'" i18n>Use Sell Orderbook Price</span>
                        <span *ngIf="type === 'sell'" i18n>Use Buy Orderbook Price</span>
                    </mat-checkbox>
                  </span>
                </td>
              </tr>
              <tr>
                <td>
                </td>
                <td>
                  <span class="align-left">
                    <mat-checkbox formControlName="buyFromSell" labelPosition="after" class="checkboxcust1" [(ngModel)]="checked2">
                        <span *ngIf="type === 'buy'" i18n>Buy from Sell Orders</span>
                        <span *ngIf="type === 'sell'" i18n>Sell to Buy Orders</span>
                    </mat-checkbox>
                </span>
                </td>
              </tr>
            </table>
            <mat-grid-tile [colspan]="8" [rowspan]="6"></mat-grid-tile>
          </div>

      </div>
      <div [formGroup]="orderForm" class="orderForm small-text col-xs-6">
        <mat-grid-list   cols="8" rowHeight="10px" class="extramat-grid">
          <div [ngClass]="{'hidden': orderForm.controls.orderAmount.disabled}">
            <mat-grid-tile class="field-label" [colspan]="4" [rowspan]="3">
              <span class="matgridtilecust3" i18n>Order Placement Amount</span>
            </mat-grid-tile>
            <mat-grid-tile [colspan]="3" [rowspan]="3">
              <mat-form-field class="w-100">
                <input matInput formControlName="orderAmount" min="0" step="0.00000001" type="number" class="right-align" (change)="updateValidation()"
                  [(ngModel)]="orderAmount" />
              </mat-form-field>
            </mat-grid-tile>
          </div>
          <mat-grid-tile [colspan]="1" [rowspan]="3" class="field-label">
            <span class="w-100">{{ coinCode }}</span>
          </mat-grid-tile>
          <div [ngClass]="{'hidden': orderForm.controls.maxBuyPrice.disabled}">
            <mat-grid-tile class="field-label" [colspan]="4" [rowspan]="3">
              <span class="matgridtilecust3" *ngIf="type === 'buy'" i18n>Maximum Buy Price</span>
              <span class="matgridtilecust3" *ngIf="type === 'sell'" i18n>Minimum Sell Price</span>
            </mat-grid-tile>
            <mat-grid-tile [colspan]="3" [rowspan]="3">
              <mat-form-field class="w-100">
                <input matInput formControlName="maxBuyPrice" min="0" step="0.00000001" type="number" class="right-align" (change)="updateValidation()"
                  [(ngModel)]="maxBuyPrice" />
              </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile [colspan]="1" [rowspan]="3" class="field-label">
              <span class="w-100">{{ exchangeCode }}</span>
            </mat-grid-tile>
          </div>
          <div [ngClass]="{'hidden': orderForm.controls.total.disabled}">
            <mat-grid-tile class="field-label" [colspan]="4" [rowspan]="3">
              <span class="matgridtilecust3" *ngIf="type === 'buy'" i18n>Total Amount to Buy</span>
              <span class="matgridtilecust3" *ngIf="type === 'sell'" i18n>Total Amount to Sell</span>
            </mat-grid-tile>
            <mat-grid-tile [colspan]="3" [rowspan]="3">
              <mat-form-field class="w-100">
                <input matInput id="total" formControlName="total" min="0" step="0.00000001" type="number" class="right-align"
                       [(ngModel)]="total" (change)="totalChangeUpdate()" />
                <mat-error *ngIf="orderForm.controls.total.hasError('min') || orderForm.controls.total.hasError('required')">
                  <ng-container i18n>Minimum</ng-container>
                  {{this.minAmount | decimalNumber : amountDecimalSpaces}}
                </mat-error>
              </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile [colspan]="1" [rowspan]="3" class="field-label">
              <span class="w-100">{{ coinCode }}</span>
            </mat-grid-tile>
          </div>
          <div [ngClass]="{'hidden': orderForm.controls.maxTotal.disabled}">
            <mat-grid-tile class="field-label" [colspan]="4" [rowspan]="3">
              <span class="matgridtilecust3" i18n>Maximum Total</span>
            </mat-grid-tile>
            <mat-grid-tile [colspan]="3" [rowspan]="3">
              <mat-form-field class="w-100">
                <input matInput id="maxTotal" formControlName="maxTotal" min="0" step="0.00000001" type="number" class="right-align"
                       [(ngModel)]="maxTotal" readonly />
                <mat-error *ngIf="orderForm.controls.maxTotal.hasError('min')">
                  <ng-container i18n>Minimum</ng-container>
                  {{this.maxTotalMin | decimalNumber : amountDecimalSpaces}}
                </mat-error>
              </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile [colspan]="1" [rowspan]="3" class="field-label">
              <span class="w-100">{{ exchangeCode }}</span>
            </mat-grid-tile>
          </div>
          <div [ngClass]="{'hidden': orderForm.controls.seconds.disabled}">
            <mat-grid-tile class="field-label" [colspan]="4" [rowspan]="3">
              <span class="matgridtilecust3" i18n>Seconds Between Order Placement</span>
            </mat-grid-tile>
            <mat-grid-tile [colspan]="3" [rowspan]="3">
              <mat-form-field class="w-100">
                <input matInput formControlName="seconds" min="0"  type="number" class="right-align"
                       [(ngModel)]="seconds" />
                <mat-error *ngIf="orderForm.controls.seconds.hasError('min')">
                  <ng-container i18n>Minimum</ng-container> 10 Seconds
                </mat-error>
                <mat-error *ngIf="orderForm.controls.seconds.hasError('max')">
                  <ng-container i18n>Maximum</ng-container> 3600 Seconds
                </mat-error>
                <mat-error *ngIf="orderForm.controls.seconds.hasError('required')">
                  <ng-container i18n>Minimum</ng-container> 10 Seconds
                </mat-error>
              </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile [colspan]="1" [rowspan]="3">
              <span class="w-100" i18n>Seconds</span>
            </mat-grid-tile>
          </div>
          <mat-grid-tile [colspan]="4" [rowspan]="6">
            <button mat-raised-button class="w-100 mat-raised-button" color="primary" (click)="clear()" i18n>CLEAR</button>
          </mat-grid-tile>
          <mat-grid-tile [colspan]="4" [rowspan]="6">
            <button mat-raised-button class="w-100 mat-raised-button" color="primary" (click)="order()">{{type|i18n|uppercase}}</button>
          </mat-grid-tile>
        </mat-grid-list>
      </div>
    </div>
  </div>
</div>
<div>
  <div  class="col-12 extra-padding" *ngIf="((orderType2 | async)  === 1 && type === 'sell' || (orderType2 | async)  === 0 && type === 'buy')">
    <div class="row">
      <div class="table-heading click-thru col-12 col-xl-4 " [hidden]="this.loadscreen.isLoading">
        <ng-container>{{type|i18n|uppercase}}</ng-container>
        {{coinCode}}
      </div>
      <div class="col-12 col-xl-8 text-left text-lg-right table-heading">
        <div class="clickable note-box" i18n-matTooltip data-container="body"
             matTooltip="AVAILABLE BALANCE : {{(balance?.balance_available || 0) | decimalNumber:(type === 'buy' ? decimalSpaces : amountDecimalSpaces) }} {{balance?.code}}">
          <span class="no-bold" i18n>AVAILABLE : </span>
          <span>{{(balance?.balance_available || 0) | decimalNumber:(type === 'buy' ? decimalSpaces :
              amountDecimalSpaces)}}</span>
          &nbsp;
          <span>{{balance?.code}}</span>
        </div>
      </div>
    </div>
    <div>
      <div [formGroup]="orderForm" class="orderForm small-text col-xs-6">
        <mat-grid-list  cols="8" rowHeight="10px">
          <div class="w-100">
            <mat-grid-tile class="field-label" [colspan]="4" [rowspan]="3">
              <span class="matgridtilecust3" i18n><b> <u>Active </u> </b> <b> <u>{{accDistType}} order </u> </b> </span>
            </mat-grid-tile>
            <mat-grid-tile class="field-label" [colspan]="4" [rowspan]="3"></mat-grid-tile>
            <mat-grid-tile class="field-label" [colspan]="4" [rowspan]="3">
              <span class="matgridtilecust3" i18n>Placement date</span>
            </mat-grid-tile>
            <mat-grid-tile [colspan]="3" [rowspan]="3">
              <mat-form-field class="w-100">
                <input readonly matInput min="0" type="text" class="right-align"
                       [(ngModel)]="placementDate" value="{{placementDate}}" />
              </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile [colspan]="1" [rowspan]="3"></mat-grid-tile>
          </div>
          <div>
            <mat-grid-tile class="field-label left-align" [colspan]="4" [rowspan]="3">
              <span class="matgridtilecust3" i18n>Price Change</span>
            </mat-grid-tile>
            <mat-grid-tile [colspan]="3" [rowspan]="3" class="overflowshow" >
              <mat-form-field class="w-100 matfieldcust">
                <input readonly matInput formControlName="priceChange" min="0" class="inputcust"
                       [max]="type === 'sell' ? balance?.balance_available : 450" step="0.00000001" type="number"
                       (change)="amountChange()" [(ngModel)]="priceChange" value="{{priceChange}}" />
                <mat-error *ngIf="balance && orderForm.controls.priceChange.hasError('max') && executionType !== 'market'">
                  <ng-container i18n>Order Placement Amount :</ng-container>
                  {{balance?.balance_available | decimalNumber : amountDecimalSpaces}} {{balance?.code}}
                </mat-error>
                <mat-error *ngIf="amountAvailable && orderForm.controls.priceChange.hasError('max') && executionType === 'market'">
                  <ng-container *ngIf="type === 'buy'" i18n>Maximum Buy Price</ng-container>
                  <ng-container *ngIf="type === 'sell'" i18n>Minimum Sell Price</ng-container>
                  {{amountAvailable | decimalNumber : amountDecimalSpaces}} {{marketService.activeMarket.coinCode}}
                </mat-error>
              </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile [colspan]="1" [rowspan]="3">
              <span class="w-100">{{ exchangeCode }}</span>
            </mat-grid-tile>
          </div>
        </mat-grid-list>
        <div [formGroup]="orderForm" class="orderForm small-text col-xs-6">
          <div>
            <mat-grid-list  cols="8" rowHeight="10px">
              <mat-grid-tile [colspan]="7" [rowspan]="3">
                <table class="tablewidth">
                  <tr>
                    <td></td>
                    <td>
                      <div class="fixed-end">
                        <mat-radio-group aria-label="Select an option" class="overflowshow align-right" formControlName="aboveBelow">
                          <mat-radio-button class="matradio1 disable-control" value="0" i18n>Below </mat-radio-button>
                          <mat-radio-button  class="matradio2 disable-control" value="1" i18n> Above {{formInputTop}} </mat-radio-button>
                        </mat-radio-group>
                      </div>
                    </td>
                  </tr>
                </table>
              </mat-grid-tile>
              <mat-grid-tile [colspan]="1" [rowspan]="3" class="field-label">
                <span class="w-100"></span>
              </mat-grid-tile>
            </mat-grid-list>
            <table class="tablewidth">
              <tr>
                <td class="w-50">
                  <span class="matgridtilecust4" *ngIf="type === 'buy'" i18n>Sell Order Matching (Optional)</span>
                  <span class="matgridtilecust4" *ngIf="type === 'sell'" i18n>Buy Order Matching (Optional)</span>
                </td>
                <td>
                  <span class="align-left">
                    <mat-checkbox formControlName="useOrderBook" labelPosition="after" readonly class="checkboxcust disable-control" [(ngModel)]="checked1" (change)="changelabel()">
                        <span *ngIf="type === 'buy'" i18n>Use Sell Orderbook Price</span>
                        <span *ngIf="type === 'sell'" i18n>Use Buy Orderbook Price</span>
                    </mat-checkbox>
                  </span>
                </td>
              </tr>
              <tr>
                <td>
                </td>
                <td>
                  <span class="align-left"><mat-checkbox formControlName="buyFromSell" labelPosition="after" readonly class="checkboxcust1 disable-control" [(ngModel)]="checked2">
                    <span *ngIf="type === 'buy'" i18n>Buy from Sell Orders</span>
                    <span *ngIf="type === 'sell'" i18n>Sell to Buy Orders</span>
                  </mat-checkbox></span>
                </td>
              </tr>
            </table>
            <mat-grid-tile [colspan]="8" [rowspan]="6"></mat-grid-tile>
          </div>
        </div>
        <div [formGroup]="orderForm" class="orderForm small-text col-xs-6">
          <mat-grid-list   cols="8" rowHeight="10px">
            <div>
              <mat-grid-tile class="field-label" [colspan]="4" [rowspan]="3">
                <span class="matgridtilecust3" i18n>Order Placement Amount</span>
              </mat-grid-tile>
              <mat-grid-tile [colspan]="3" [rowspan]="3">
                <mat-form-field class="w-100">
                  <input readonly matInput formControlName="orderAmount" min="0" step="0.00000001" type="number" class="right-align"
                         [(ngModel)]="orderAmount" value="{{orderAmount}}"/>
                </mat-form-field>
              </mat-grid-tile>
              <mat-grid-tile [colspan]="1" [rowspan]="3">
                <span class="w-100">{{ coinCode }}</span>
              </mat-grid-tile>
            </div>
            <div>
              <mat-grid-tile class="field-label" [colspan]="4" [rowspan]="3">
                <span class="matgridtilecust3" *ngIf="type === 'buy'" i18n>Maximum Buy Price</span>
                <span class="matgridtilecust3" *ngIf="type === 'sell'" i18n>Minimum Sell Price</span>
              </mat-grid-tile>
              <mat-grid-tile [colspan]="3" [rowspan]="3">
                <mat-form-field class="w-100">
                  <input readonly matInput formControlName="maxBuyPrice" min="0" step="0.00000001" type="number" class="right-align"
                         [(ngModel)]="maxBuyPrice"  value="{{maxBuyPrice}}"/>
                </mat-form-field>
              </mat-grid-tile>
              <mat-grid-tile [colspan]="1" [rowspan]="3">
                <span class="w-100">{{ exchangeCode }}</span>
              </mat-grid-tile>
            </div>
            <div>
              <mat-grid-tile class="field-label" [colspan]="4" [rowspan]="3">
                <span class="matgridtilecust3" *ngIf="type === 'buy'" i18n>Total Amount to Buy</span>
                <span class="matgridtilecust3" *ngIf="type === 'sell'" i18n>Total Amount to Sell</span>
              </mat-grid-tile>
              <mat-grid-tile [colspan]="3" [rowspan]="3">
                <mat-form-field class="w-100">
                  <input readonly matInput formControlName="total" min="0" step="0.00000001" type="number" class="right-align"
                         [(ngModel)]="total" value="{{total}}"/>
                </mat-form-field>
              </mat-grid-tile>
              <mat-grid-tile [colspan]="1" [rowspan]="3">
                <span class="w-100">{{ coinCode }}</span>
              </mat-grid-tile>
            </div>
            <div>
              <mat-grid-tile class="field-label" [colspan]="4" [rowspan]="3">
                <span class="matgridtilecust3 extra-padding-seconds" i18n>Seconds Between Order Placement</span>
              </mat-grid-tile>
              <mat-grid-tile [colspan]="3" [rowspan]="3">
                <mat-form-field class="w-100">
                  <input readonly matInput min="0"  type="number" class="right-align"
                         [(ngModel)]="seconds" value="{{seconds}}" />
                </mat-form-field>
              </mat-grid-tile>
              <mat-grid-tile [colspan]="1" [rowspan]="3" class="field-label">
                <span class="w-100"></span>
              </mat-grid-tile>
            </div>
            <div>
              <mat-grid-tile class="field-label" [colspan]="4" [rowspan]="3">
                <span class="matgridtilecust3" *ngIf="type === 'buy'" i18n> <b> Amount accumulated</b></span>
                <span class="matgridtilecust3" *ngIf="type === 'sell'" i18n> <b> Amount distributed</b></span>
              </mat-grid-tile>
              <mat-grid-tile [colspan]="3" [rowspan]="3">
                <mat-form-field class="w-100">
                  <input readonly matInput min="0"  type="number" class="right-align"
                         [(ngModel)]="amountAccumulated" value="{{amountAccumulated}}" />
                </mat-form-field>
              </mat-grid-tile>
              <mat-grid-tile [colspan]="1" [rowspan]="3" class="field-label">
                <span class="w-100">{{ coinCode }}</span>
              </mat-grid-tile>
            </div>
            <div>
              <mat-grid-tile class="field-label" [colspan]="4" [rowspan]="3">
                <span class="matgridtilecust3"><b>Price of last order</b> </span>
              </mat-grid-tile>
              <mat-grid-tile [colspan]="3" [rowspan]="3">
                <mat-form-field class="w-100">
                  <input readonly matInput formControlName="seconds" min="0"  type="number" class="right-align"
                         [(ngModel)]="lastPrice" value="{{lastPrice}}" />
                </mat-form-field>
              </mat-grid-tile>
              <mat-grid-tile [colspan]="1" [rowspan]="3" class="field-label">
                <span class="w-100">{{ exchangeCode }}</span>
              </mat-grid-tile>
            </div>
            <mat-grid-tile [colspan]="8" [rowspan]="6">
              <button mat-raised-button class="w-100 mat-raised-button" color="primary" (click)="cancelOrder()" i18n>Cancel Order</button>
            </mat-grid-tile>
          </mat-grid-list>
        </div>
      </div>
    </div>
  </div>
</div>
<app-loading-da #loadscreen class="loader-background"></app-loading-da>

<div  class="col-12 extra-padding h-100" *ngIf="((orderType2 | async)  === 0 && type === 'sell') || ((orderType2 | async)  === 1 && type === 'buy')">
  <div class="row">
    <div id="inner-box-2" *ngIf="((orderType2 | async)  === 0 && type=== 'sell') || ((orderType2 | async)  === 1 && type=== 'buy')">
      <div class="overlaytext" *ngIf="((orderType2 | async)  === 0 && type=== 'sell') || ((orderType2 | async)  === 1 && type=== 'buy')"><span class="custom-font-weight" i18n>You currently have an active {{oppType}} order and are only permitted to have one active Accumulation or Distribution order per market at any time.</span></div>
    </div>
    <div class="table-heading click-thru col-12 col-xl-4 ">
      <ng-container>{{type|i18n|uppercase}}</ng-container>
      {{coinCode}}
    </div>
    <div class="col-12 col-xl-8 text-left text-lg-right table-heading">
      <div class="clickable note-box" (click)="fillAmount()" i18n-matTooltip
           matTooltip="AVAILABLE BALANCE : {{(balance?.balance_available || 0) | decimalNumber:(type === 'buy' ? decimalSpaces : amountDecimalSpaces) }} {{balance?.code}}">
        <span class="no-bold spandef" i18n>AVAILABLE : </span>
        <span class="spandef">{{(balance?.balance_available || 0) | decimalNumber:(type === 'buy' ? decimalSpaces :
            amountDecimalSpaces)}}</span>
        &nbsp;
        <span>{{balance?.code}}</span>
      </div>
    </div>
  </div>
  <div>
    <div [formGroup]="orderForm" class="orderForm small-text col-xs-6">
      <mat-grid-list  cols="8" rowHeight="10px">
        <mat-grid-tile [colspan]="8" [rowspan]="6"></mat-grid-tile>
        <div >
          <mat-grid-tile class="field-label left-align" [colspan]="4" [rowspan]="3">
            <span class="matgridtilecust3" i18n>Price Change</span>
          </mat-grid-tile>
          <mat-grid-tile [colspan]="3" [rowspan]="3" class="overflowshow" >
            <mat-form-field class="w-100 matfieldcust">
              <input readonly matInput formControlName="priceChange" min="0" class="inputcust disable-control" [disabled]="type === 'sell'"
                     [max]="type === 'sell' ? balance?.balance_available : 450" step="0.00000001" type="number"
                     (change)="amountChange()" />
            </mat-form-field>
          </mat-grid-tile>
          <mat-grid-tile [colspan]="1" [rowspan]="3">
            <span class="w-100">{{ exchangeCode }}</span>
          </mat-grid-tile>
        </div>
      </mat-grid-list>
      <div [formGroup]="orderForm" class="orderForm small-text col-xs-6">
        <div>
          <mat-grid-list  cols="8" rowHeight="10px">
            <mat-grid-tile [colspan]="7" [rowspan]="3">
              <table class="tablewidth">
                <tr>
                  <td></td>
                  <td>
                    <div class="fixed-end">
                      <mat-radio-group aria-label="Select an option" class="overflowshow align-right" formControlName="aboveBelow">
                        <mat-radio-button class="matradio1 disable-control" value="0" [checked]="true" i18n>Below </mat-radio-button>
                        <mat-radio-button  class="matradio2 disable-control" value="1" i18n> Above {{formInputTop}} </mat-radio-button>
                      </mat-radio-group>
                    </div>
                  </td>
                </tr>
              </table>
            </mat-grid-tile>
            <mat-grid-tile [colspan]="1" [rowspan]="3" class="field-label">
              <span class="w-100"></span>
            </mat-grid-tile>
          </mat-grid-list>
          <table class="tablewidth">
            <tr>
              <td class="w-50">
                <span class="matgridtilecust4" *ngIf="type === 'buy'" i18n>Sell Order Matching (Optional)</span>
                <span class="matgridtilecust4" *ngIf="type === 'sell'" i18n>Buy Order Matching (Optional)</span>
              </td>
              <td>
                <span class="align-left">
                <mat-checkbox formControlName="useOrderBook" labelPosition="after" readonly class="checkboxcust disable-control" [(ngModel)]="checked1" (change)="changelabel()">
                  <span *ngIf="type === 'buy'" i18n>Use Sell Orderbook Price</span>
                  <span *ngIf="type === 'sell'" i18n>Use Buy Orderbook Price</span>
                </mat-checkbox>
                </span>
              </td>
            </tr>
            <tr>
              <td>
              </td>
              <td>
                <span class="align-left">
                  <mat-checkbox formControlName="buyFromSell" labelPosition="after" readonly class="checkboxcust1 disable-control" [(ngModel)]="checked2">
                    <span *ngIf="type === 'buy'" i18n>Buy from Sell Orders</span>
                    <span *ngIf="type === 'sell'" i18n>Sell to Buy Orders</span>
                  </mat-checkbox>
                </span>
              </td>
            </tr>
          </table>
          <mat-grid-tile [colspan]="8" [rowspan]="6"></mat-grid-tile>
        </div>
      </div>
      <div [formGroup]="orderForm" class="orderForm small-text col-xs-6">
        <mat-grid-list   cols="8" rowHeight="10px" class="extra-margin-top-custom">
          <div [ngClass]="{'hidden': orderForm.controls.orderAmount.disabled}">
            <mat-grid-tile class="field-label" [colspan]="4" [rowspan]="3" i18n>
              <span class="matgridtilecust3">Order Placement Amount</span>
            </mat-grid-tile>
            <mat-grid-tile [colspan]="3" [rowspan]="3">
              <mat-form-field class="w-100">
                <input readonly matInput formControlName="orderAmount" min="0" step="0.00000001" type="number" class="right-align disable-control"
                       [disabled]="type === 'sell'"/>
                <mat-error *ngIf="orderForm.controls.orderAmount.hasError('min')">
                  <ng-container i18n>Minimum</ng-container>
                  {{totalMinimum | decimalNumber : decimalSpaces}} {{type === 'buy' ? balance?.code : exchangeCode}}
                </mat-error>
              </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile [colspan]="1" [rowspan]="3">
              <span class="w-100">{{ coinCode }}</span>
            </mat-grid-tile>
          </div>
          <div [ngClass]="{'hidden': orderForm.controls.maxBuyPrice.disabled}">
            <mat-grid-tile class="field-label" [colspan]="4" [rowspan]="3">
              <span class="matgridtilecust3" *ngIf="type === 'buy'" i18n>Maximum Buy Price</span>
              <span class="matgridtilecust3" *ngIf="type === 'sell'" i18n>Minimum Sell Price</span>
            </mat-grid-tile>
            <mat-grid-tile [colspan]="3" [rowspan]="3">
              <mat-form-field class="w-100">
                <input readonly matInput formControlName="maxBuyPrice" min="0" step="0.00000001" type="number" class="right-align disable-control"
                       [disabled]="type === 'sell'"/>
                <mat-error *ngIf="orderForm.controls.maxBuyPrice.hasError('min')">
                  <ng-container i18n>Minimum</ng-container>
                  {{totalMinimum | decimalNumber : decimalSpaces}} {{type === 'buy' ? balance?.code : exchangeCode}}
                </mat-error>
              </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile [colspan]="1" [rowspan]="3">
              <span class="w-100">{{ exchangeCode }}</span>
            </mat-grid-tile>
          </div>
          <div [ngClass]="{'hidden': orderForm.controls.total.disabled}">
            <mat-grid-tile class="field-label" [colspan]="4" [rowspan]="3">
              <span class="matgridtilecust3" *ngIf="type === 'buy'" i18n>Total Amount to Buy</span>
              <span class="matgridtilecust3" *ngIf="type === 'sell'" i18n>Total Amount to Sell</span>
            </mat-grid-tile>
            <mat-grid-tile [colspan]="3" [rowspan]="3">
              <mat-form-field class="w-100">
                <input readonly matInput formControlName="total" min="0" step="0.00000001" type="number" class="right-align disable-control"
                       [disabled]="type === 'sell'" />
              </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile [colspan]="1" [rowspan]="3">
              <span class="w-100">{{ coinCode }}</span>
            </mat-grid-tile>
          </div>
          <div [ngClass]="{'hidden': orderForm.controls.seconds.disabled}">
            <mat-grid-tile class="field-label" [colspan]="4" [rowspan]="3">
              <span class="matgridtilecust3" i18n>Seconds Between Order Placement</span>
            </mat-grid-tile>
            <mat-grid-tile [colspan]="3" [rowspan]="3">
              <mat-form-field class="w-100">
                <input readonly matInput formControlName="seconds" min="0"  type="number" class="right-align disable-control"
                       [disabled]="type === 'sell'"/>
              </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile [colspan]="1" [rowspan]="3">
              <span class="w-100" i18n>Seconds</span>
            </mat-grid-tile>
          </div>
          <mat-grid-tile [colspan]="8" [rowspan]="6" ng-disabled="button"></mat-grid-tile>
          <mat-grid-tile [colspan]="4" [rowspan]="6">
            <button mat-raised-button [disabled]="type == 'sell' || type == 'buy'" class="w-100 mat-raised-button zbutton" color="primary" (click)="clear()" i18n ng-model="button">CLEAR</button>
          </mat-grid-tile>
          <mat-grid-tile [colspan]="4" [rowspan]="6" ng-disabled="button">
            <button mat-raised-button [disabled]="type == 'sell' || type == 'buy'" class="w-100 mat-raised-button zbutton" color="primary" (click)="order()"
                    i18n ng-model="button">{{type|uppercase}}</button>
          </mat-grid-tile>
        </mat-grid-list>
      </div>
    </div>
  </div>
</div>

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-loading-da',
  templateUrl: './loading-da.component.html',
  styleUrls: ['./loading-da.component.css']
})
export class LoadingDaComponent implements OnInit {

  isLoading: boolean = false;
  constructor() { }

  ngOnInit() {
  }

  public setIsLoading(check: boolean) {
    this.isLoading = check;
  }

}

<mat-table *ngIf="marketsService.activeMarket" matSort [dataSource]="historyData" (matSortChange)="SortEvent(historyMode)" class="w-100 height-limit full-table">

  <ng-container matColumnDef="coin">
    <mat-header-cell *matHeaderCellDef mat-sort-header i18n>BUY/SELL</mat-header-cell>
    <mat-cell *matCellDef="let element" i18n> {{getCoinCode(element.market)}} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="buy">
    <mat-header-cell *matHeaderCellDef mat-sort-header i18n>BUY/SELL</mat-header-cell>
    <mat-cell *matCellDef="let element" i18n> {{element.type === '0' ? 'BUY' : 'SELL'}} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="quantity">
    <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Quantity</mat-header-cell>
    <mat-cell *matCellDef="let element" (click)="LoadValue(element)"> {{element.amount | decimalNumber: element.coin_decimals}} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="cancel" stickyEnd>
    <mat-header-cell *matHeaderCellDef>Cancel</mat-header-cell>
    <mat-cell *matCellDef="let element">
      <mat-icon (click)="cancelOrder(element)" color="bad" data-container="body" placement="left" i18n-matTooltip matTooltip="Cancel Order" triggers="hover click">clear</mat-icon>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="type">
    <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Action</mat-header-cell>
    <mat-cell *matCellDef="let element" i18n> <mat-icon [ngClass]="[!element.type ? '' : element.type === '0' ? 'buy' : 'sell']">lens</mat-icon> {{element.type === '0' ? 'BUY' : 'SELL'}} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="action">
    <mat-header-cell *matHeaderCellDef i18n>Type</mat-header-cell>
    <mat-cell *matCellDef="let element" (click)="LoadValue(element)"> {{ actionName(element.action) }} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="amount">
    <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Amount</mat-header-cell>
    <mat-cell *matCellDef="let element" (click)="LoadValue(element)"> {{element.amount | decimalNumber: element.coin_decimals}} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="price">
    <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Price</mat-header-cell>
    <mat-cell *matCellDef="let element" (click)="LoadValue(element)"> {{mustDisplay(element.action, element.price) | decimalNumber: element.exchange_decimals}} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="total">
    <mat-header-cell *matHeaderCellDef  mat-sort-header i18n>Total</mat-header-cell>
    <mat-cell *matCellDef="let element" (click)="LoadValue(element)"> {{mustDisplay(element.action, element.total) | decimalNumber: element.exchange_decimals}} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="fee">
    <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Fee</mat-header-cell>
    <mat-cell *matCellDef="let element" (click)="LoadValue(element)"> {{element.fee | decimalNumber: (this.marketsService.activeMarket.exchangeCode === 'ZAR' ? element.coin_decimals: element.exchange_decimals)}} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="net_total">
    <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Net Total</mat-header-cell>
    <mat-cell *matCellDef="let element" (click)="LoadValue(element)"> {{mustDisplay(element.action, element.net_total) | decimalNumber: element.exchange_decimals}} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="conditional_trading">
    <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Conditional Trading</mat-header-cell>
    <mat-cell *matCellDef="let element" (click)="LoadValue(element)">
      <mat-icon *ngIf="mustDisplayTooltip(element)" color="primary" data-container="body" placement="bottom" triggers="hover click" i18n-matTooltip matTooltip="{{buildToolTip(element)}}">info</mat-icon>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="status">
    <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Status</mat-header-cell>
    <mat-cell *matCellDef="let element" (click)="LoadValue(element)"> {{element.status}} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="time">
    <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Time</mat-header-cell>
    <mat-cell *matCellDef="let element" (click)="LoadValue(element)"> {{element.time_formatted}} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="actions" stickyEnd>
    <mat-header-cell *matHeaderCellDef mat-sort-header></mat-header-cell>
    <mat-cell *matCellDef="let element">
      <mat-icon color="primary" (click)="cancelOrder(element)" data-container="body" placement="left" triggers="click hover" i18n-matTooltip matTooltip="Cancel Order">clear</mat-icon>
    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="historyDefinition; sticky: true"></mat-header-row>
  <mat-row *matRowDef="let row; columns: historyDefinition;"></mat-row>

</mat-table>
<div class="w-100 text-center no-records" *ngIf="!historyData || historyData.filteredData.length === 0" i18n>No records found</div>
<mat-paginator i18n-itemsPerPageLabel itemsPerPageLabel="Items per page:" [ngStyle]="{ 'display': (hidePaginator?'none':'unset') }" (page)="paging_change($event)" *ngIf="enablePaginator" [length]="totalRecords" [pageSize]="listNumber" [pageSizeOptions]="pageSizes"></mat-paginator>

<div class="text-center d-flex bx-pb-4" *ngIf = "exchangeName === 'burnx'">
  <div class="burnx-cancel">
      <button mat-raised-button class="mat-raised-button" color="bad" *ngIf="cancelButton && historyMode === 0" (click)="cancelAllOrders()">CANCEL ALL ORDERS</button>
  </div>

  <div class="burnx-paging">
    <mat-icon class="page-left" [ngClass]="[!hasPrevPage()?'disabled':'']" (click)="prevPage()">keyboard_arrow_left</mat-icon>
    <div>{{getPageNumber()}}</div>
    <mat-icon class="page-right" [ngClass]="[!hasNextPage()?'disabled':'']" (click)="nextPage()">keyboard_arrow_right</mat-icon>
  </div>
</div>

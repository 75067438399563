import {ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {MarketsService} from '../../markets.service';
import {StoreService} from '../../../core/services/store.service';
import {I18n} from '@ngx-translate/i18n-polyfill';
import {I18nTranslationService} from '../../../core/services/i18n-translation.service';
import * as Big from 'big-js';
import {environment} from '../../../../environments/environment';
import {DialogData, MarketConfirmationComponent} from '../../market-confirmation/market-confirmation.component';
import {executionType} from '../order-types-shared';
import { DecimalNumberPipe } from 'src/app/core/pipe/decimalNumber.pipe';
import { UtilHelper } from 'src/app/core/helpers/util-helper';
import { SubscriptionLike } from 'rxjs';

const typeMap = ['buy', 'sell'];

@Component({
  selector: 'app-stop-limit-order',
  templateUrl: './stop-limit-order.component.html',
  styleUrls: ['./stop-limit-order.component.scss']
})

export class StopLimitOrderComponent implements OnInit, OnDestroy {
  public valueChange: Function = UtilHelper.bigValueToFixed;
  @Input('orderValues')
  set orderValues(values: any) {
    if (Object.keys(values).length > 0 && (values.userSpecified || values.marketChanged)) {
      this.LoadValue(values);
    }
  }

  amount: number | Big = new Big(0).toFixed(8);
  stopprice: number| Big = new Big(0).toFixed(8);
  stopLogicOperator: string = '';
  applystop: boolean = true;
  amountDecimalSpaces: number = 8;
  stopDecimalSpaces: number = 8;
  feeDecimalSpaces: number = 8;
  balance: any = {
    balance: { balance_available: 0.00000000, code: '' }
  };
  balanceSell: any = {
    balance: { balance_available: 0.00000000, code: '' }
  };
  balanceBuy: any = {
    balance: { balance_available: 0.00000000, code: '' }
  };
  decimalSpaces: number = 8;
  fee: number | Big = new Big(0).toFixed(8);
  netTotal: number | Big = new Big(0).toFixed(8);
  orderForm: FormGroup;
  price: number | Big = new Big(0).toFixed(8);
  showConfirmation: boolean = true;
  subs: SubscriptionLike[] = [];
  balanceSubs: SubscriptionLike;
  total: number | Big = new Big(0).toFixed(8);
  totalMinimum: number = 1;
  priceForFeeCalc: number | Big = new Big(0).toFixed(8);
  feeType: string = 'makerFee';
  feePercentage: number | Big = new Big(0).toFixed(8);
  @Input() type: string = 'buy'; // Buy = 0, Sell = 1
  executionTypeStop: string = '';
  exchangeCode: string = '';
  coinCode: string = '';
  displayPrice: boolean = false;
  amountAvailable: number | Big = new Big(0).toFixed(8);
  orderBook: any = [];
  priceMarket: number | Big = new Big(0).toFixed(8);
  firstLoad: boolean = true;
  feeToolTip: string = '';
  stopToolTip: string = '';
  exchangeName: string = environment.config.EXCHANGE_NAME_L;
  minAmount: number | Big = new Big(0).toFixed(8);
  minTotal: number = 0.0001;

  translatedWords: Object = {
    'AVAILABLE BALANCE: ': this.i18n('AVAILABLE BALANCE: '),
  };

  constructor(
    public dialog: MatDialog,
    public marketService: MarketsService,
    private formBuilder: FormBuilder,
    private store: StoreService,
    private i18n: I18n,
    private translateService: I18nTranslationService,
    public snackbar: MatSnackBar,
    private CDRef: ChangeDetectorRef,
    private decimalPipe: DecimalNumberPipe
  ) {
    this.orderForm = this.formBuilder.group({
      amount: ['', Validators.max(0)],
      stopprice: ['', Validators.min(0.0001)],
      price: ['', Validators.required],
      total: ['', Validators.min(this.minTotal)],
      fee: [''],
      net_total: ['', Validators.min(this.minTotal)]
    });
  }

  ngOnInit() {
    this.subs.push(this.marketService.activeMarketSubject.subscribe((response) => {
      this.setActiveMarket();
    }));
    this.setActiveMarket();
    this.subs.push(this.store.subscribe('settings').subscribe((response) => {
      if (!response.refresh) {
        this.showConfirmation = !response.data.confirmations ? true : response.data.confirmations === '1';
      }
    }));

    this.generateStopTooltip();
    this.setupForm();
    this.setupFormValidation();
  }

  ngOnDestroy() {
    this.subs.forEach(sub => sub.unsubscribe());
    if (this.balanceSubs) {
      this.balanceSubs.unsubscribe();
    }
  }


  resetFormValidation() {
    Object.keys(this.orderForm.controls).forEach(key => {
      this.orderForm.get(key).setValidators(null);
      this.orderForm.get(key).updateValueAndValidity();
    });
  }

  setupFormValidation() {
    const netValidator = (price = false) => (control) => {
      const value = +control.value;
      if (value === null || !value || +value === 0) {
        return null;
      }
      let _max = 0;
      if (this.balance.balance_available) {
        _max = +this.balance.balance_available;
      }
      if (price) {
        _max *= this.price;
      }

      return !isNaN(value) && value > _max ? { 'max': { 'max': _max, 'actual': control.value } } : null;
    };

    this.resetFormValidation();
    const maximumValue = (this.type === 'buy' ? false :
    (!!this.balance ? this.balance?.balance_available : this.amountAvailable));
    this.minAmount = this.marketService.getMinAmount(
      this.minAmount, maximumValue, this.orderForm, +this.price, this.calcFeeToUse('takerFee'));

    if (this.type === 'buy') {
      this.orderForm.controls.amount.setValidators(null);
      this.orderForm.controls.amount.updateValueAndValidity();
      this.orderForm.controls.amount.setValidators([Validators.required, Validators.min(0.00000001)]);
      this.orderForm.controls.amount.updateValueAndValidity();

      if (this.applystop) {
        this.orderForm.controls.price.setValidators(null);
        this.orderForm.controls.price.updateValueAndValidity();
        this.orderForm.controls.price.setValidators([Validators.required, Validators.min(0.00000001)]);
        this.orderForm.controls.price.updateValueAndValidity();
      }

      this.orderForm.controls.net_total.setValidators(null);
      this.orderForm.controls.net_total.updateValueAndValidity();
      this.orderForm.controls.net_total.setValidators([netValidator(),
      Validators.min(this.totalMinimum)]);
      this.orderForm.controls.net_total.updateValueAndValidity();

      this.orderForm.controls.total.setValidators(null);
      this.orderForm.controls.total.updateValueAndValidity();

    } else {
      this.orderForm.controls.amount.setValidators(null);
      this.orderForm.controls.amount.updateValueAndValidity();
      this.orderForm.controls.amount.setValidators([Validators.required, Validators.min(0.00000001),
        netValidator(false)]);
      this.orderForm.controls.amount.updateValueAndValidity();

      if (this.applystop) {
        this.orderForm.controls.price.setValidators(null);
        this.orderForm.controls.price.updateValueAndValidity();
        this.orderForm.controls.price.setValidators([Validators.required, Validators.min(0.00000001)]);
        this.orderForm.controls.price.updateValueAndValidity();
      }

      this.orderForm.controls.net_total.setValidators(null);
      this.orderForm.controls.net_total.updateValueAndValidity();

      this.orderForm.controls.total.setValidators(null);
      this.orderForm.controls.total.updateValueAndValidity();
      this.orderForm.controls.total.setValidators([netValidator(true),
        Validators.min(this.totalMinimum)]);
      this.orderForm.controls.total.updateValueAndValidity();
    }
    this.orderForm.markAsPristine();
  }

  getErrorMessage() {
    if (this.balance && this.orderForm.controls.amount.hasError('max') && this.type === 'sell') {
      return 'Available balance: ' +
        this.decimalPipe.transform(this.balance.balance_available, this.amountDecimalSpaces, '') + ' ' +
        this.marketService.activeMarket.coinCode;
    } else if (this.balance && this.orderForm.controls.amount.hasError('max') && this.type === 'buy') {
      return 'Available balance: ' +
        this.decimalPipe.transform(this.balance.balance_available, this.amountDecimalSpaces, '') + ' '
        + this.marketService.activeMarket.exchangeCode;
    } else if (this.amountAvailable && this.orderForm.controls.amount.hasError('max')) {
      return 'Available in order book: ' +
        this.decimalPipe.transform(this.amountAvailable, this.amountDecimalSpaces, '') + ' '
        + this.marketService.activeMarket.coinCode;
    } else {
      return 'Minimum 0.00000001 ' + this.marketService.activeMarket.coinCode;
    }
  }

  setupForm() {
    this.disableAllControls();
    this.orderForm.controls.amount.enable();
    this.orderForm.controls.stopprice.enable();
    this.orderForm.controls.price.enable();
    if (this.applystop) {
      this.displayPrice = true;
      this.orderForm.controls.price.enable();
      this.orderForm.controls.total.enable();
      this.orderForm.controls.fee.enable();
      this.orderForm.controls.net_total.enable();
    } else {
      this.displayPrice = false;
    }

    this.setupFormValidation();
    this.amount = 0;
    this.amountChange();
  }

  disableAllControls() {
    Object.keys(this.orderForm.controls).forEach(key => {
      this.orderForm.get(key).disable();
    });
  }

  setExecutionType(type: string) {
    this.executionTypeStop = type;
    this.setupForm();
  }

  amountChange() {
    this.amount = this.valueChange(this.amount, this.amountDecimalSpaces);
    this.calculateTotal();
    this.calculateFee();
    this.calculateNetTotal();
    this.stopChange();
  }

  calculateAmount() {
    const currentFee = new Big(1)[this.type === 'buy' ? 'plus' : 'minus'](this.feePercentage);
    const currentTotal = new Big(Number(this.netTotal)).div(currentFee);
    let currentAmount = currentTotal.div(this.price);
    currentAmount = currentAmount.round(this.amountDecimalSpaces, 0); // round down to the last decimal place

    this.amount = this.valueChange(currentAmount, this.amountDecimalSpaces);
  }

  calcFeeToUse(type: string = 'makerFee') {
    let toUse = +this.marketService.activeMarket[type];
    if (this.marketService[type] < toUse) {
      toUse = +this.marketService[type];
    }
    return toUse;
  }

  generateStopTooltip() {
    if (this.exchangeName === 'chainex') {
      this.stopToolTip = this.i18n('Order will execute when market price matches this');
    } else {
      this.stopToolTip = this.i18n('Buy: Execute when Last Price >= Stop') + '\n' +
        this.i18n('Sell: Execute when Last Price <= Stop');
    }

  }

  generateFeeTooltip() {
    let mFee: string = '0';
    let tFee = '0';
    try {
      const mFeeToUse = this.calcFeeToUse('makerFee');
      const tFeeToUse = this.calcFeeToUse('takerFee');
      if (this.type === 'buy') {
        if (['ZAR'].indexOf(this.marketService.activeMarket.exchangeCode) !== -1) {
          mFee = this.price > 0 ? this.valueChange(new Big(+this.total)
              .times(mFeeToUse).div(+this.price), this.feeDecimalSpaces)
            : '0';
          tFee = this.price > 0 ? this.valueChange(new Big(+this.total)
              .times(tFeeToUse).div(+this.price), this.feeDecimalSpaces)
            : '0';
        } else {
          mFee = this.valueChange(new Big(+this.total)
            .times(mFeeToUse), this.feeDecimalSpaces);
          tFee = this.valueChange(new Big(+this.total)
            .times(tFeeToUse), this.feeDecimalSpaces);
        }
      } else {
        if (['ZAR'].indexOf(this.marketService.activeMarket.exchangeCode) !== -1) {
          mFee = this.price > 0 ? this.valueChange(new Big(+this.total)
              .times(mFeeToUse).div(this.price), this.feeDecimalSpaces)
            : '0';
          tFee = this.price > 0 ? this.valueChange(new Big(+this.total)
              .times(tFeeToUse).div(this.price), this.feeDecimalSpaces)
            : '0';
        } else {
          mFee = this.valueChange(new Big(+this.total)
            .times(mFeeToUse), this.feeDecimalSpaces);
          tFee = this.valueChange(new Big(+this.total)
            .times(tFeeToUse), this.feeDecimalSpaces);
        }
      }
    } catch (ex) { }
    this.feeToolTip = 'Maker: ' + mFee + '\nTaker: ' + tFee;
  }

  calculateFeeToUse() {
    const tempTakerFee = (+this.marketService['takerFee'] < +this.marketService.activeMarket['takerFee'])
      ? +this.marketService['takerFee'] : +this.marketService.activeMarket['takerFee'];
    const tempMakerFee = (+this.marketService['makerFee'] < +this.marketService.activeMarket['makerFee'])
      ? +this.marketService['makerFee'] : +this.marketService.activeMarket['makerFee'];
    this.feeType = (tempTakerFee > tempMakerFee) ? 'takerFee' : 'makerFee';
    this.feePercentage = (tempTakerFee > tempMakerFee) ? tempTakerFee : tempMakerFee;
  }

  calculateFee() {
    try {
      this.generateFeeTooltip();
      this.calculateFeeToUse();
      if (this.type === 'buy') {
        if (['ZAR'].indexOf(this.marketService.activeMarket.exchangeCode) !== -1) {
          this.fee = this.price > 0 ? this.valueChange(new Big(+this.total)
              .times(this.feePercentage).div(+this.price), this.feeDecimalSpaces)
            : 0;
        } else {
          this.fee = this.valueChange(new Big(+this.total)
            .times(this.feePercentage), this.feeDecimalSpaces);
        }
      } else {
        if (['ZAR'].indexOf(this.marketService.activeMarket.exchangeCode) !== -1) {
          this.fee = this.price > 0 ? this.valueChange(new Big(+this.total)
              .times(this.feePercentage).div(this.price), this.feeDecimalSpaces)
            : 0;
        } else {
          this.fee = this.valueChange(new Big(+this.total)
            .times(this.feePercentage), this.feeDecimalSpaces);
        }
      }
    } catch (ex) { }
  }

  calculateNetTotal() {
    if (['ZAR'].indexOf(this.marketService.activeMarket.exchangeCode) !== -1) {
      let currentFee: any = 0;
      currentFee = new Big(+this.price).times(+this.amount).times(new Big(this.feePercentage));
      this.netTotal = this.valueChange(new Big(+this.total)[this.type === 'buy' ? 'plus' : 'minus'](currentFee)
        , this.decimalSpaces);
    } else {
      this.netTotal = this.valueChange(new Big(+this.total)[this.type === 'buy' ? 'plus' : 'minus'](+this.fee)
        , this.decimalSpaces);
    }
    this.orderForm.controls.net_total.markAsTouched();

    if (this.type === 'sell' && this.amount > this.balance.balance_available) {
      this.orderForm.controls.amount.setValidators([Validators.max(this.amountAvailable)]);
      this.orderForm.controls.amount.markAsTouched();
    } else {
      this.orderForm.controls.amount.setValidators(null);
    }
  }

  calculateTotalMarket() {
    this.amount = isNaN(this.amount) ? 0 : this.amount;
    let amountToOffer: number = this.amount;
    let totalCalc: number | Big = new Big(0).toFixed(8);
    let totalPrice: number | Big = new Big(0).toFixed(8);
    let orderCount: number = 0;
    if (!!this.orderBook) {
      for (let i = 0; i <= this.orderBook.length; i++) {
        if (+this.orderBook[i].amount > 0) {
          amountToOffer = amountToOffer - this.orderBook[i].amount;
          let orderTake: number | Big = new Big(0).toFixed(8);
          if (amountToOffer > 0) {
            // we can take the whole order
            orderTake = new Big(+this.orderBook[i].amount).times(+this.orderBook[i].price);
          } else {
            orderTake = (new Big(+this.orderBook[i].amount).plus(+amountToOffer)).times(+this.orderBook[i].price);
          }
          totalCalc = new Big(totalCalc).plus(orderTake);
          totalPrice = new Big(totalPrice).plus(+this.orderBook[i].price);
          orderCount++;
          if (amountToOffer <= 0) {
            break;
          }
        }
      }
      this.priceMarket = new Big(totalPrice).div(orderCount).toFixed(8);
    }

    this.total = this.valueChange(totalCalc, this.decimalSpaces);
    if (this.type === 'sell') {
      this.validateTotal();
    }
  }

  calculateTotal() {
    this.amount = isNaN(this.amount) ? 0 : this.amount;
    this.price = isNaN(this.price) ? 0 : this.price;
    this.total = this.valueChange(new Big(+this.amount).times(+this.price), this.decimalSpaces);
    if (this.type === 'sell' && this.total > this.minTotal) {
      this.orderForm.controls.total.markAsUntouched();
    } else {
      this.validateTotal();
    }
  }

  clear() {
    this.amount = (0).toFixed(this.decimalSpaces);
    this.stopprice = (0).toFixed(this.decimalSpaces);
    this.total = (0).toFixed(this.decimalSpaces);
    this.price = (0).toFixed(this.decimalSpaces);

    this.calculateTotal();
    this.calculateFee();
    this.calculateNetTotal();

    this.orderForm.markAsUntouched();
  }

  fillAmount() {
    if (this.type === 'buy') {
      this.netTotal = this.balance.balance_available;
      this.netTotalChange();
    } else {
      this.amount = this.balance.balance_available;
      this.amountChange();
    }
  }

  getPercentage(percentage: any) {
    return (percentage ? +percentage * 100 : 0).toFixed(2);
  }

  LoadValue(values: any) {
    // check if form is untouched before changing values
    const priceChangeAllowed = this.orderForm.untouched || (values.userSpecified || values.marketChanged);

    // this updates the price used to check if order is taker/maker
    if (!!values[this.type] && !!values[this.type].feePrice) {
      this.priceForFeeCalc = (+values[this.type].feePrice || 0).toFixed(this.amountDecimalSpaces);
      this.priceChange();
    }

    if (!!values[this.type] || (!!values.price && !!values.amount)) {
      if (!!values[this.type] && this.firstLoad) {
        this.firstLoad = false;
        this.price = +values[this.type].price.toFixed(this.decimalSpaces) ||
          this.marketService.activeMarket.lastPrice;
        this.priceChange();
        if (values.marketChanged) {
          this.amount = 0;
        } else {
          this.amount = (+values[this.type].amount || 0).toFixed(this.amountDecimalSpaces);
        }
        this.stopprice = (values.price.toFixed(this.decimalSpaces) ||
          this.marketService.activeMarket.lastPrice);
        this.amountChange();
        this.orderForm.controls.total.markAsUntouched();
        this.orderForm.controls.net_total.markAsUntouched();
      } else {
        if (priceChangeAllowed) {
          if (values.userSpecified || values.marketChanged) {
            this.price = +values.price || this.marketService.activeMarket.lastPrice;
          } else {
            // if order book update, get price by order type
            this.price = (+values[this.type].price.toFixed(this.decimalSpaces) ||
            this.marketService.activeMarket.lastPrice);
          }

          if (values.marketChanged) {
            this.amount = 0;
          } else {
            this.amount = +values.amount || 0;
          }
          this.stopprice = values.price.toFixed(this.decimalSpaces) ||
            this.marketService.activeMarket.lastPrice;
          this.priceChange();
          this.amountChange();
          if ((this.type === 'buy' && this.netTotal > this.balance.balance_available) ||
            (this.type === 'sell' && this.amount > this.balance.balance_available)) {
            this.fillAmount();
          }

          if (!(values.userSpecified)) {
            this.orderForm.markAsUntouched();
          }
        }
      }
    }

    const otherType = this.type === 'buy' ? 'sell' : 'buy';
    if (!!values[otherType] && !!values[otherType].totalAmountAvailable) {
      this.amountAvailable = (+values[otherType].totalAmountAvailable).toFixed(this.amountDecimalSpaces);
    }
    if (!!values[otherType] && !!values[otherType].orders) {
      this.orderBook = values[otherType].orders;
    }
  }

  markAsTouched() {
    this.orderForm.markAsTouched();
  }

  netTotalChange() {
    this.netTotal = this.valueChange(this.netTotal, this.decimalSpaces);
    this.calculateAmount();
    this.calculateTotal();
    this.calculateFee();
  }

  changeStop() {
    if ( this.applystop === false) {
      this.applystop = true;
    } else {
      this.applystop = false;
    }
    this.setupForm();
  }

  order() {
    if (this.exchangeName === 'burnx') {
      this.type = 'buy';
      this.balance = this.balanceBuy;
      this.setupFormValidation();
    }
    this.orderForm.controls.amount.markAsTouched();
    this.orderForm.controls.amount.updateValueAndValidity();
    this.orderForm.controls.stopprice.markAsTouched();
    this.orderForm.controls.stopprice.updateValueAndValidity();
    this.orderForm.controls.price.markAsTouched();
    this.orderForm.controls.price.updateValueAndValidity();
    this.orderForm.controls.total.markAsTouched();
    this.orderForm.controls.total.updateValueAndValidity();
    this.orderForm.controls.net_total.markAsTouched();
    this.orderForm.controls.net_total.updateValueAndValidity();

    this.orderForm.markAsTouched();

    this.stopChange();

    if (this.orderForm.valid) {
      const placeOrder = () => {
        const data: any = {
          amount: this.amount,
          stopprice: this.stopprice,
          stop_logic: this.stopLogicOperator,
          price: this.applystop ? this.price : this.stopprice,
          market: this.marketService.activeMarket.id,
          type: typeMap.indexOf(this.type),
          action:  this.applystop ? executionType['stop-limit'] : executionType['stop']
        };
        this.marketService.createOrder(data).subscribe((response) => {
          if (this.showConfirmation) {
            // checking for specific error messages
            if (response.reason === 'CREATEORDER_NOTENOUGHBALANCE' || response.response === 'success') {
              this.snackbar.open(this.translateService.translateResponse(response.reason),
                this.i18n('Close'), { duration: 2000 });
            } else {
              this.dialog.open(MarketConfirmationComponent, {
                width: '500px',
                data: {
                  title: response.response === 'success' ? 'Success' : 'Failed',
                  result: response.response === 'success' ? 'success' : 'failed',
                  reason: response.reason
                }
              });
            }
          } else if (response.response !== 'success') {
            this.snackbar.open(this.translateService.translateResponse(response.reason),
                this.i18n('Close'), { duration: 5000 });
          }

          if (response.response === 'success') {
            this.orderForm.markAsUntouched();
            this.amount = 0;
            this.price = 0;
            this.clear();
            this.store.updateBalance({
              code: data.type === 0 ?
                this.marketService.activeMarket.exchangeCode : this.marketService.activeMarket.coinCode,
              balance_available: response.balance_available
            });
          }
        });
      };
      if (this.showConfirmation) {
        const orderType = this.type.charAt(0).toUpperCase() + this.type.slice(1);
        const dialogData: DialogData = {
          title: this.i18n(`{{orderType}} {{coincode}}`,
            { orderType, coincode: this.marketService.activeMarket.coinCode }),
          result: 'string',
          reason: this.i18n(`Are you sure you would like to place this {{type}} order?<br>
                  <br><b>Market: </b>{{pair}}
                  <br><b>Amount: </b>{{amount}} {{coincode}}
                  <br><b>Stop: </b> {{stop}} {{exchangecode}}
                  <br><b>Apply limit: </b> {{limit}}
                  <br><b>Price: </b>{{price}} {{exchangecode}}
                  <br><b>Total: </b> {{total}} {{exchangecode}}
                  <br><b>Fee: </b> {{fee}} {{exchangecode}}
                  <br><b>Net Total: </b>{{nettotal}}
                  {{exchangecode}} <br><br>
                  Please ensure that the above amounts are correct before confirming.
                  We will be unable to refund/cancel any live order that has been matched.<br><br>`, {
            pair: this.marketService.activeMarket.marketPair,
            stop: this.stopprice,
            amount: this.amount,
            fee: this.fee,
            total: this.total,
            limit: this.applystop === true ? 'Yes' : 'No',
            coincode: this.marketService.activeMarket.coinCode,
            price: this.price,
            exchangecode: this.marketService.activeMarket.exchangeCode,
            nettotal: this.netTotal,
            type: this.type
          }) +
            '<div class="border-box">' +
            this.i18n(`Please note that once the market price reaches your specified Stop Price,
          an order with the above details will be placed automatically using your available balance.
          If you do not have enough balance available to place this order fully at this time, it will be canceled.`) +
            '</div>',
          okButton: true,
          isTranslated: true,
          titleTranslated: true
        };
        const dialogRef = this.dialog.open(MarketConfirmationComponent, {
          width: '600px',
          data: dialogData
        });
        dialogRef.afterClosed().subscribe((result) => {
          if (!!result && result.result === 'accept') {
            placeOrder();
          }
        });
      } else {
        placeOrder();
      }
    }
  }

  orderSell() {
    if (this.exchangeName === 'burnx') {
      this.type = 'sell';
      this.balance = this.balanceSell;
      this.setupFormValidation();
    }
    this.orderForm.controls.amount.markAsTouched();
    this.orderForm.controls.stopprice.markAsTouched();
    this.orderForm.controls.price.markAsTouched();
    this.orderForm.controls.total.markAsTouched();

    this.orderForm.markAsTouched();

    if (this.orderForm.valid) {

      const placeOrder = () => {
        const data: any = {
          stopprice: this.stopprice,
          amount: this.amount,
          price: this.stopprice,
          market: this.marketService.activeMarket.id,
          type: 1,
          action:  this.applystop ? executionType['stop-limit'] : executionType['stop']
        };
        this.marketService.createOrder(data).subscribe((response) => {

          if (this.showConfirmation) {
            // checking for specific error messages
            if (response.reason === 'CREATEORDER_NOTENOUGHBALANCE' || response.response === 'success') {
              this.snackbar.open(this.translateService.translateResponse(response.reason),
                this.i18n('Close'), { duration: 2000 });
            } else {
              this.dialog.open(MarketConfirmationComponent, {
                width: '500px',
                data: {
                  title: response.response === 'success' ? 'Success' : 'Failed',
                  result: response.response === 'success' ? 'success' : 'failed',
                  reason: response.reason
                }
              });
            }
          }

          if (response.response === 'success') {
            this.amount = 0;
            this.clear();
            this.store.updateBalance({
              code: data.type === 0 ?
                this.marketService.activeMarket.exchangeCode : this.marketService.activeMarket.coinCode,
              balance_available: response.balance_available
            });
          }
        });
      };

      if (this.showConfirmation) {
        const orderType = this.type.charAt(0).toUpperCase() + this.type.slice(1);
        const dialogData: DialogData = {
          title: this.i18n(`{{orderType}} {{coincode}}`,
            { orderType, coincode: this.marketService.activeMarket.coinCode }),
          result: 'string',
          reason: this.i18n(`Are you sure you would like to place this {{type}} order?<br>
                  <br><b>Market: </b>{{pair}}
                  <br><b>Amount: </b>{{amount}} {{coincode}}
                  <br><b>Stop: </b> {{stop}} {{exchangecode}}
                  <br><b>Apply limit: </b> {{limit}}
                  <br><b>Price: </b>{{price}} {{exchangecode}}
                  <br><b>Total: </b> {{total}} {{exchangecode}}
                  <br><b>Fee: </b> {{fee}} {{exchangecode}}
                  <br><b>Net Total: </b>{{nettotal}}
                  {{exchangecode}} <br><br>
                  Please ensure that the above amounts are correct before confirming.
                  We will be unable to refund/cancel any live order that has been matched.<br><br>`, {
            pair: this.marketService.activeMarket.marketPair,
            stop: this.stopprice,
            amount: this.amount,
            fee: this.fee,
            total: this.total,
            limit: this.applystop === true ? 'Yes' : 'No',
            coincode: this.marketService.activeMarket.coinCode,
            price: this.price,
            exchangecode: this.marketService.activeMarket.exchangeCode,
            nettotal: this.netTotal,
            type: this.type
          }),
          okButton: true,
          isTranslated: true,
          titleTranslated: true
        };
        const dialogRef = this.dialog.open(MarketConfirmationComponent, {
          width: '600px',
          data: dialogData
        });
        dialogRef.afterClosed().subscribe((result) => {
          if (!!result && result.result === 'accept') {
            placeOrder();
          }
        });
      }
    }
  }

  priceChange() {
    this.price = this.valueChange(this.price, this.decimalSpaces);

    this.calculateFeeToUse();
    this.calculateTotal();
    this.calculateFee();
    this.calculateNetTotal();
    const maximumValue = (this.type === 'buy' ? false :
    (!!this.balance ? this.balance?.balance_available : this.amountAvailable));
    this.minAmount = this.marketService.getMinAmount(
      this.minAmount, maximumValue, this.orderForm, +this.price, this.calcFeeToUse('takerFee'));
  }

  setActiveMarket() {
    if (this.marketService.activeMarket.exchangeDecimals) {
      this.decimalSpaces = Number(this.marketService.activeMarket.exchangeDecimals);
      this.feeDecimalSpaces = (this.marketService.activeMarket.exchangeCode === 'ZAR' ?
        this.marketService.activeMarket.coinDecimals :
        this.marketService.activeMarket.exchangeDecimals) || 8;
      this.stopDecimalSpaces = Number(this.marketService.activeMarket.exchangeDecimals);
    } else {
      this.decimalSpaces = ['ZAR', 'USDT'].indexOf(this.marketService.activeMarket.exchangeCode) === -1 ? 8 : 2;
      this.feeDecimalSpaces = ['USDT'].indexOf(this.marketService.activeMarket.exchangeCode) === -1 ? 8 : 2;
      this.stopDecimalSpaces = ['ZAR', 'USDT'].indexOf(this.marketService.activeMarket.exchangeCode) === -1 ? 8 : 2;
    }
    if (this.marketService.activeMarket.coinDecimals) {
    this.amountDecimalSpaces = Number(this.marketService.activeMarket.coinDecimals);
    } else {
      this.amountDecimalSpaces = ['ZAR', 'USDT'].indexOf(this.marketService.activeMarket.coinCode) === -1 ? 8 : 2;
    }
    this.totalMinimum = (['ZAR', 'USDT'].indexOf(this.marketService.activeMarket.exchangeCode) === -1 ?
      this.minTotal : 1);
    this.price = Number(this.marketService.activeMarket.lastPrice);
    this.setupFormValidation();
    if (!this.CDRef['destroyed']) {
      this.CDRef.detectChanges();
    }

    if (this.balanceSubs) {
      this.balanceSubs.unsubscribe();
    }

    this.exchangeCode = !!this.marketService.activeMarket ? this.marketService.activeMarket.exchangeCode : '';
    this.coinCode = this.marketService.activeMarket.coinCode;

    if (this.marketService.activeMarket.id !== '-1') {
      this.balanceSubs = this.store.subscribe('balances').subscribe(response => {
        if (!response.refresh) {
          let filteredResponse;
          let filteredResponseSell;
          if (this.exchangeName === 'chainex') {
            filteredResponse = response.data.filter(responseBalance => responseBalance.code === (
              this.type === 'buy' ?
                this.marketService.activeMarket.exchangeCode : this.marketService.activeMarket.coinCode))[0];
          } else {
            filteredResponse = response.data.filter(responseBalance => responseBalance.code === (
              this.marketService.activeMarket.exchangeCode))[0];
            filteredResponseSell = response.data.filter(responseBalance => responseBalance.code === (
              this.marketService.activeMarket.coinCode))[0];
          }
          if (filteredResponse !== undefined) {
            if (this.exchangeName === 'burnx') {
              this.balanceBuy = filteredResponse;
            } else {
              this.balance = filteredResponse;
              this.balance.balance_available = Number(this.balance.balance_available);
            }
            if (!this.CDRef['destroyed']) {
              this.CDRef.detectChanges();
            }
          }
          if (filteredResponse !== undefined) {
            this.balanceSell = filteredResponseSell;
            if (!this.CDRef['destroyed']) {
              this.CDRef.detectChanges();
            }
          }
        }
      });
    }
    this.orderForm.markAsPristine();
    this.firstLoad = true;
  }

  stopChange() {
    if (this.stopprice > 0) {
      this.stopLogicOperator = Number(this.marketService.activeMarket.lastPrice) < this.stopprice ? '>=' : '<=';
    } else {
      this.stopLogicOperator = '';
    }
    this.stopprice = this.valueChange(this.stopprice, this.stopDecimalSpaces);
  }

  totalChange() {
    this.total = this.valueChange(this.total, this.decimalSpaces);
    if (this.type === 'buy') {
      this.price = (+this.price).toFixed(this.decimalSpaces);
    } else {
      this.validateTotal();
    }

    this.amount = this.valueChange(new Big(+this.total).div(+this.price), this.amountDecimalSpaces);
    const calculatedTotal = this.valueChange(new Big(+this.amount).times(+this.price), this.decimalSpaces + 1);
    if (+this.total !== +calculatedTotal) {
      const multiplier = new Big(10).pow(this.amountDecimalSpaces);
      let calculatedAmount: number = +this.
      valueChange(new Big(+this.total).div(+this.price), this.amountDecimalSpaces + 1);
      const roundedCalc = Math.round(multiplier.times(calculatedAmount).add(1));
      calculatedAmount = new Big(roundedCalc).div(multiplier);
      this.amount = calculatedAmount.toFixed(this.amountDecimalSpaces);
    }

    this.calculateFee();
    this.calculateNetTotal();
  }

  validateTotal() {
    this.orderForm.controls.total.markAsTouched();
  }

  displayFee() {
    if (this.type === 'buy') {
      return this.getPercentage(this.marketService.activeMarket.takerFee);
    } else {
      return this.getPercentage(this.marketService.activeMarket.makerFee);
    }
  }

}

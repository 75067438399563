<div class="heightfull">
    <div class="mat-elevation-z2 sell-section min-height-50" id="sell-stats" [ngClass]="{'fiat-style-sell':marketService.activeMarket.exchangeCode === 'ZAR' || marketService.activeMarket.exchangeCode === 'USDT' }">
      <div class="table-heading p-0 font-avenir-medium" i18n *ngIf="orderBookHeader">
        ORDER BOOK
      </div>
      <div class="position-grouping" *ngIf="burnxGrouping">
        <mat-icon color="accent" (click)="addGroup()">add</mat-icon>
        <mat-icon color="accent" (click)="minusGroup()">remove</mat-icon>
      </div>
      <mat-table [dataSource]="sellData" class="d-flex flex-column w-100 sell-table" [trackBy]="trackByOrderBook">

        <ng-container matColumnDef="myOrder">
          <mat-header-cell class="text-right mr-1" *matHeaderCellDef></mat-header-cell>
          <mat-cell *matCellDef="let element" class="my-order text-right mr-1">
            <span *ngIf="element.hasOrder">
              <mat-icon class="order-indicator restrict-icon-height" svgIcon="order-indicator" placement="right" i18n-matTooltip matTooltip="You have active orders in this price range" triggers="hover click"></mat-icon>
            </span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="price">
          <mat-header-cell class="text-right mr-1" *matHeaderCellDef i18n>Price</mat-header-cell>
          <mat-cell *matCellDef="let element" class="sell text-right mr-1">
            <ng-container *ngIf="element.price == 0; else sellPrice">-</ng-container>
            <ng-template #sellPrice>{{element.price | decimalNumber : decimals}}</ng-template>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="amount">
          <mat-header-cell class="text-right mr-2" *matHeaderCellDef i18n>Amount</mat-header-cell>
          <mat-cell *matCellDef="let element" class="text-right mr-2" placement="bottom" triggers="hover click" [matTooltip]="element.amount" matTooltip="{{translatedWords['AMOUNT: ']}}{{element.amount | number:'1.8-8'}}">
            <ng-container *ngIf="element.amount == 0; else sellAmount">-</ng-container>
            <ng-template #sellAmount>{{element.amount | number:'1.4-4'}}</ng-template>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="total">
          <mat-header-cell class="text-right mr-1" *matHeaderCellDef i18n>Total</mat-header-cell>
          <mat-cell *matCellDef="let element" class="text-right mr-1">
            <ng-container *ngIf="element.orderTotal == 0; else sellOrderTotal">-</ng-container>
            <ng-template #sellOrderTotal>{{element.orderTotal | decimalNumber : marketService.activeMarket.exchangeDecimals}}</ng-template>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="sum">
          <mat-header-cell class="text-right mr-1" *matHeaderCellDef i18n>Sum</mat-header-cell>
          <mat-cell *matCellDef="let element" class="text-right mr-1">
            <ng-container *ngIf="element.total == 0; else sellTotal">-</ng-container>
            <ng-template #sellTotal>{{element.total | decimalNumber : marketService.activeMarket.exchangeDecimals}}</ng-template>
          </mat-cell>
        </ng-container>

        <mat-header-row class="low-z" *matHeaderRowDef="tableDefinition; sticky: true"></mat-header-row>
        <ng-container matColumnDef="sell-total">
          <mat-header-cell *matHeaderCellDef>
            {{orderBookData?.sell_total_amount | decimalNumber : marketService.activeMarket.coinDecimals}} {{marketService.activeMarket.coinCode}}
          </mat-header-cell>
        </ng-container>
        <!--ng-container matColumnDef="empty-header-row">
          <mat-header-cell *matHeaderCellDef class="text-right headerfont">
          </mat-header-cell>
        </ng-container-->
        <ng-container matColumnDef="sell-total-amount">
          <mat-header-cell *matHeaderCellDef class="text-right">
            {{orderBookData?.sell_total | decimalNumber : marketService.activeMarket.exchangeDecimals}} {{marketService.activeMarket.exchangeCode}}
          </mat-header-cell>
        </ng-container>

        <mat-row (click)="loadValue(row)" *matRowDef="let row; columns: tableDefinition;"
          [ngClass]="{'clickable': row.amount>0, 'update': row.update, 'increase': row.more, 'decrease': row.more === false}"
          [ngStyle]="getRowColorStyle(row, getStyleColor('buy'), getStyleColor('buy_dark'))"></mat-row>
        <mat-header-row *matHeaderRowDef="['sell-total','sell-total-amount']"></mat-header-row>
        <!--mat-header-row class="no-border" *matHeaderRowDef="['empty-header-row']"></mat-header-row-->
        <!--mat-row>
          <mat-cell></mat-cell>
        </mat-row-->

      </mat-table>
      <div class="w-100 text-center pull-middle" *ngIf="!sellData || sellData.filteredData.length === 0">
        {{sellOrdersStatus | i18n}}
      </div>
    </div>
    <div class="col-12 mat-elevation-z2 buy-section min-height-50 padding-top-2 nopadding" id="buy-stats" [ngClass]="{'fiat-style-buy':marketService.activeMarket.exchangeCode === 'ZAR' || marketService.activeMarket.exchangeCode === 'USDT' }">
      <mat-table [dataSource]="buyData" class="w-100 h-100 buy-table" [trackBy]="trackByOrderBook">
        <ng-container matColumnDef="myOrder">
          <mat-header-cell class="text-right mr-1" *matHeaderCellDef></mat-header-cell>
          <mat-cell *matCellDef="let element" class="my-order text-right mr-1">
            <span *ngIf="element.hasOrder">
              <mat-icon class="order-indicator restrict-icon-height" svgIcon="order-indicator" placement="right" matTooltip="You have active orders in this price range." triggers="hover click"></mat-icon>
            </span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="price">
          <mat-cell *matCellDef="let element" class="buy text-right mr-1">
            <ng-container *ngIf="element.price == 0; else buyPrice">-</ng-container>
            <ng-template #buyPrice>{{element.price | decimalNumber : decimals}}</ng-template>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="amount">
          <mat-cell *matCellDef="let element" class="text-right mr-2" [matTooltip]="element.amount" placement="bottom" triggers="hover click" matTooltip="{{translatedWords['AMOUNT: ']}}{{element.amount | number:'1.8-8'}}">
            <ng-container *ngIf="element.amount == 0; else buyAmount">-</ng-container>
            <ng-template #buyAmount>{{element.amount | number:'1.4-4'}}</ng-template>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="total">
          <mat-cell *matCellDef="let element" class="text-right mr-1">
            <ng-container *ngIf="element.orderTotal == 0; else buyOrderTotal">-</ng-container>
            <ng-template #buyOrderTotal>{{element.orderTotal | decimalNumber : marketService.activeMarket.exchangeDecimals}}</ng-template>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="sum">
          <mat-cell *matCellDef="let element" class="text-right mr-1">
            <ng-container *ngIf="element.total == 0; else buyTotal">-</ng-container>
            <ng-template #buyTotal>{{element.total | decimalNumber : marketService.activeMarket.exchangeDecimals}}</ng-template>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="buy-total">
          <mat-footer-cell *matFooterCellDef class="headerfont">
            {{+(orderBookData?.buy_total_amount) | decimalNumber : marketService.activeMarket.coinDecimals}} {{marketService.activeMarket.coinCode}}
          </mat-footer-cell>
        </ng-container>
        <!-- <ng-container matColumnDef="empty-row">
          <mat-footer-cell *matFooterCellDef class="text-right headerfont">
          </mat-footer-cell>
        </ng-container> -->
        <ng-container matColumnDef="buy-total-amount">
          <mat-footer-cell *matFooterCellDef class="text-right headerfont">
            {{orderBookData?.buy_total | decimalNumber : marketService.activeMarket.exchangeDecimals}} {{marketService.activeMarket.exchangeCode}}
          </mat-footer-cell>
        </ng-container>

        <mat-row (click)="loadValue(row)" *matRowDef="let row; columns: tableDefinition; let i = index"
          [ngClass]="{'clickable': row.amount>0, 'update': row.update, 'increase': row.more, 'decrease': row.more === false, 'border-b': i === buyData.filteredData.length - 1}"
          [ngStyle]="getRowColorStyle(row, getStyleColor('sell'), getStyleColor('sell_dark'))"></mat-row>
          <!--mat-footer-row *matFooterRowDef="['empty-row']"></mat-footer-row-->
          <mat-footer-row *matFooterRowDef="['buy-total','buy-total-amount']" class="headerfont"></mat-footer-row>
      </mat-table>
      <div class="w-100 text-center pull-middle" *ngIf="!buyData || buyData.filteredData.length === 0">
        {{buyOrdersStatus | i18n }}
      </div>
    </div>
    <div class="col-12 stats-grouping noselect" *ngIf="chainexGrouping && ['ZAR', 'USDT'].indexOf(marketService.activeMarket.exchangeCode) === -1">
      <div class="w-50 d-inline-block"><b i18n>GROUP</b></div>
      <div class="w-50 text-right d-inline-block groupOrders">{{decimals}}
        <div class="group-controls" *ngIf="!currentlyFetching">
          <mat-icon color="accent" (click)="addGroup()">add</mat-icon>
          <mat-icon color="accent" (click)="minusGroup()">remove</mat-icon>
        </div>
        <div class="group-controls" *ngIf="currentlyFetching">
          <mat-progress-spinner [diameter]="20" color="accent" mode="indeterminate"></mat-progress-spinner>
        </div>
      </div>
    </div>

  <div class="col-12 stats-grouping noselect" *ngIf="!(chainexGrouping && ['ZAR', 'USDT'].indexOf(marketService.activeMarket.exchangeCode) === -1)">
    <div class="w-50 d-inline-block"><b i18n></b></div>
    <div class="w-50 text-right d-inline-block groupOrders">
      <div class="group-controls" *ngIf="!currentlyFetching">
      </div>
      <div class="group-controls" *ngIf="currentlyFetching">
      </div>
    </div>
  </div>
</div>

<div class="row">
  <div class="table-heading click-thru col-12 col-xl-4 ">
    <ng-container>{{type|i18n|uppercase}}</ng-container> {{coinCode}}
  </div>
  <div class="col-12 col-xl-8 text-left text-lg-right table-heading">
    <div class="row flex-end">
      <div class="clickable note-box" (click)="fillAmount()" i18n-matTooltip placement="bottom"
          matTooltip="AVAILABLE BALANCE : {{(balance?.balance_available || 0) | decimalNumber:(type === 'buy' ? decimalSpaces : amountDecimalSpaces) }} {{balance?.code}}">
        <span class="no-bold" i18n>AVAILABLE : </span>
        <span>{{(balance?.balance_available || 0) | decimalNumber:(type === 'buy' ? decimalSpaces :
            amountDecimalSpaces)}}</span>
        &nbsp;
        <span>{{balance?.code}}</span>
      </div>
      <app-balance-viewer
        [available]="(balance?.balance_available || 0) | decimalNumber: type === 'buy' ? decimalSpaces : amountDecimalSpaces"
        [held]="(balance?.balance_held || 0) | decimalNumber: type === 'buy' ? decimalSpaces : amountDecimalSpaces"
        [earn]="(balance?.balance_earn || 0) | decimalNumber: type === 'buy' ? decimalSpaces : amountDecimalSpaces"
        [code]="(type === 'buy') ? exchangeCode : coinCode">
      </app-balance-viewer>
    </div>
  </div>
</div>

<div [formGroup]="orderForm" class="order-form small-text">
  <mat-grid-list cols="8" rowHeight="10px">
    <div [ngClass]="{'hidden': orderForm.controls.amount.disabled}">
      <mat-grid-tile class="field-label" [colspan]="2" [rowspan]="3" i18n>
        Amount
      </mat-grid-tile>
      <mat-grid-tile [colspan]="4" [rowspan]="3">
        <mat-form-field class="w-100">
          <input matInput formControlName="amount" min="0" [max]="type === 'sell' ? balance?.balance_available : undefined"
                 step="0.00000001" type="number" (change)="amountChange()" [(ngModel)]="amount" />
          <mat-error *ngIf="orderForm.controls.amount.hasError('max')">
            <ng-container i18n>Available in order book:</ng-container> {{amountAvailable | decimalNumber : amountDecimalSpaces}} {{coinCode}}
          </mat-error>
          <mat-error *ngIf="orderForm.controls.amount.hasError('min') && !(orderForm.controls.amount.hasError('min1') || orderForm.controls.amount.hasError('min0'))">
            <ng-container i18n>Minimum</ng-container> {{minAmount}} {{marketService.activeMarket.coinCode}}
          </mat-error>
          <mat-error *ngIf="orderForm.controls.amount.hasError('min0') && !orderForm.controls.amount.hasError('max')">
            <ng-container i18n>Amount must be more than </ng-container> {{0 | decimalNumber : amountDecimalSpaces}} {{ coinCode }}
          </mat-error>
          <mat-error *ngIf="orderForm.controls.amount.hasError('min1') && !orderForm.controls.amount.hasError('max')">
            <ng-container i18n>Minimum</ng-container> {{type === 'buy' ? this.minAmountBuy : this.minAmountSell | decimalNumber : amountDecimalSpaces}} {{ coinCode }}
          </mat-error>
          <mat-error *ngIf="orderForm.controls.amount.hasError('min2') && !orderForm.controls.amount.hasError('max')">
            <ng-container i18n>Minimum</ng-container> {{minAmount | decimalNumber : amountDecimalSpaces}} {{ coinCode }}
          </mat-error>
          <mat-error *ngIf="orderForm.controls.amount.hasError('max2') && !orderForm.controls.amount.hasError('max')">
            <ng-container i18n>Available balance:</ng-container> {{balance?.balance_available | decimalNumber : (type === 'sell' ? amountDecimalSpaces : decimalSpaces)}} {{ type === 'sell' ? coinCode : exchangeCode }}
          </mat-error>
        </mat-form-field>
      </mat-grid-tile>
      <mat-grid-tile [colspan]="2" [rowspan]="3" class="field-label">
        {{coinCode}}
      </mat-grid-tile>
    </div>

    <div [ngClass]="{'hidden': orderForm.controls.price.disabled}">
      <mat-grid-tile class="field-label" [colspan]="2" [rowspan]="3" i18n>
        Price
      </mat-grid-tile>
      <mat-grid-tile [colspan]="4" [rowspan]="3">
        <mat-form-field class="w-100">
          <input matInput type="text" value="MARKET" *ngIf="!displayPrice" disabled="true" />
          <input matInput formControlName="price" min="0" step="0.00000001" type="number" (change)="priceChange()"
                 [(ngModel)]="price" *ngIf="displayPrice" />
        </mat-form-field>
      </mat-grid-tile>
      <mat-grid-tile [colspan]="2" [rowspan]="3" class="field-label">
        {{exchangeCode}}
      </mat-grid-tile>
    </div>

    <mat-grid-tile class="field-label" [colspan]="2" [rowspan]="3">
    </mat-grid-tile>
    <mat-grid-tile [colspan]="4" [rowspan]="3">
    </mat-grid-tile>
    <mat-grid-tile [colspan]="2" [rowspan]="3" class="field-label">
    </mat-grid-tile>

    <mat-grid-tile [colspan]="8" [rowspan]="3" class="field-label">
      <mat-error class="w-100 px-3" *ngIf="orderForm.controls.amount.hasError('priceDeviation')">
        {{priceDeviationMessage| i18n}}
      </mat-error>
    </mat-grid-tile>

    <mat-grid-tile class="field-label" [colspan]="2" [rowspan]="3">
    </mat-grid-tile>
    <mat-grid-tile [colspan]="4" [rowspan]="3">
    </mat-grid-tile>
    <mat-grid-tile [colspan]="2" [rowspan]="3" class="field-label">
    </mat-grid-tile>

    <mat-grid-tile [colspan]="4" [rowspan]="6">
      <button mat-raised-button class="w-100 mat-raised-button" color="primary" (click)="clear()" i18n>CLEAR</button>
    </mat-grid-tile>

    <mat-grid-tile [colspan]="4" [rowspan]="6">
      <button mat-raised-button class="w-100 mat-raised-button" color="primary" (click)="orderBuy()"
              i18n>{{type|uppercase}}</button>
    </mat-grid-tile>

  </mat-grid-list>
</div>
